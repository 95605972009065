import React, { Component } from "react";
import DocumentList from "../components/DocumentList";
import "../App.css";
import Carousels from "../components/Carousels";
import StatCounter from "../components/StatCounter";
import ReachUsComponent from "../components/ReachUsComponent";
import Layout from "./Layout";
import HomeLoanCalcComponent from "../components/HomeLoanCalcComponent";
import ProductListComponent from "../components/ProductListComponent";
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

class HomePage extends Component {
  render() {
    const { width } = 250
    const { height } = 60
    return (
      <div className="globalTopMargin">
        <Layout
          mainContent={
            <div>
              {/* <p>Test</p> */}
              <Confetti className="m-auto"
                width={width}
                height={height}
              />
              <Carousels />
              <DocumentList />
              <HomeLoanCalcComponent />
              <ProductListComponent />
              <ReachUsComponent />
              <StatCounter />
            </div>
          }
        />
      </div>
    );
  }
}
export default HomePage;

import Layout from "./Layout";
import * as ContactService from "../services/services";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/ContactUS.css"

export function StampDutyCalculator() {
    const [stateList, setStateList] = useState([]);
    const [stateName, setStateName] = useState("");
    const [propertyValue, setPropertyValue] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const changedState = (event) => {
        let stateData = event.target.value.split(",");
        console.log(event.target.value.split(","));
        setPercentage(stateData[0])
        setStateName(stateData[1])
    };
    const handleChange = (event) => {
        let regex = new RegExp('^[0-9]*$');
        let propertyVal = event.target.value;
        if (regex.test(propertyVal)) {
            setPropertyValue(propertyVal)
        }
    }
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        ContactService.getStampDutyDetails().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                console.log(resp)
                setStateList(resp)
            }
        })
        return () => mounted = false;

    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <Link to="/other-calculators" className="mr-2">
                        Other Calculators
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Stamp Duty Calculator
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Stamp Duty Calculator
            </div>
            <Card className="pt-5">
                {/* Enter Property Details */}
                <Row className="m-auto w-100">
                    <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-5">
                        <Form.Group controlId="exampleForm.SelectCustom" className="m-auto">
                            <Form.Label>Select State</Form.Label>
                            <Form.Control as="select" onChange={changedState.bind(this)}>
                                <option>Select State</option>
                                {stateList.map((state, index) => {
                                    return (
                                        <option key={state.id} value={[state.StampDutyPercentage, state.StateName]}>{state.StateName}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-5">
                        <Form.Group controlId="exampleForm.SelectCustom" className="m-auto">
                            <Form.Label>Enter Property Amount (in Rs.)</Form.Label>
                            <Form.Control
                                type="number"
                                name="PropertyAmount"
                                value={propertyValue}
                                onChange={handleChange.bind(this)}
                            // isValid={touched.LanguagesKnown && !errors.LanguagesKnown}
                            // isInvalid={!!errors.LanguagesKnown}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {stateName ? <div className="my-5" style={{
                    width: "80%",
                    margin: "auto",
                    background: "#E31A52",
                    color: "white",
                    minHeight: "3rem",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    borderRadius: "0.25rem",
                }}>The estimated stamp duty will be {propertyValue * percentage / 100} (Rs.) for the property value of {propertyValue} (Rs.) in the state of {stateName}</div> :
                    <div className="my-5" style={{
                        width: "80%",
                        margin: "auto",
                        background: "#E31A52",
                        color: "white",
                        minHeight: "3rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        borderRadius: "0.25rem",
                    }}>Choose a state and enter the property value</div>
                }
                <p className="mb-5" style={{ color: "#E31A52" }}>
                    * The Values displayed here are only indicative, For actual charges, Please refer to the respective State government Website.
                </p>
            </Card>
        </div>} />
    </div >)
}
import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../styles/Carousel.css";

class Carousels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      carousel: [],
    };
  }

  async componentDidMount() {
    let response = await fetch(
      process.env.REACT_APP_BASE_URL + "/home-page-carousels"
    );
    if (!response.ok) {
      return;
    }

    let carousel = await response.json();
    this.setState({ loading: false, carousel: carousel[0].carousel });
  }
  render() {
    if (!this.state.loading) {
      return (
        <div data-aos="fade-up">
          <Carousel>
            {this.state.carousel.map((carousel, index) => {
              return (
                <Carousel.Item key={carousel.id} className="pickgradient">
                  <div style={{ background: "#E31A52" }}><p className="m-0" style={{ color: "white", fontSize: "1rem", fontStyle: "italic" }}>ROI at 7.50%* &amp; 2.67* lakhs subsidy under PMAY (*Conditions apply) </p></div>
                  {carousel.alternativeText === 'video' ?
                    <video style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "0.25rem"
                    }} autoPlay muted loop>
                      <source src={`${process.env.REACT_APP_BASE_URL + carousel.url}`} type="video/mp4" />
                      Your browser does not support HTML video.
                    </video> : <img
                      className="d-block w-100 Carousel-image"
                      src={`${process.env.REACT_APP_BASE_URL + carousel.url}`}
                      alt={"Slide" + carousel.id}
                      style={carousel.id === 81 ? { objectPosition: "center" } : { objectPosition: "center" }}
                    />
                  }
                  <Carousel.Caption>
                    <p className="Carousel-title mid-size-content">{carousel.caption}
                    </p>
                    {/* <button className="Carousel-applyButton">Apply Now</button> */}
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      );
    }
    return <p className="DocumentList-title">Loading...</p>;
  }
}

export default Carousels;

import Layout from "./Layout";
import * as FinancialService from "../services/services";
import { useEffect, useState } from "react";
import { Col, Row, Table, Tabs, Tab, TabContent, Nav, Accordion, Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/FinancialInformation.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faChartArea, faChartLine, faFilePdf, faHandPaper, faHeadphones, faHourglassHalf, faInfo, faKey, faMicrophone, faMicrophoneAlt, faMoneyBill, faMoneyBillWave, faMoneyCheck, faNewspaper, faPaperclip, faPaperPlane, faUnlock } from "@fortawesome/free-solid-svg-icons";

export function FinancialInformation() {
    const [financialInformation, setFinancialInformation] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [quarterlyResults, setQuarterlyResults] = useState([]);
    const [annualReports, setAnnualReports] = useState([]);
    const [creditRatings, setCreditRatings] = useState([]);

    const showAnnualReport = (link) => {
        window.open(link)
    }
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        FinancialService.getFinancialInformation().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setFinancialInformation(resp);
                setTableHeaders(Object.keys(resp[0].FinancialHighlights[0]));
            }
        })
        FinancialService.getQuarterlyResults().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setQuarterlyResults(resp);
            }
        })
        FinancialService.getAnnualReports().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setAnnualReports(resp);
            }
        })
        FinancialService.getCreditRatings().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setCreditRatings(resp);
            }
        })
        return () => mounted = false;

    }, [])
    const convertToFormattedValue = (value) => {
        if (value != null && (value % 1 == 0)) {
            var totalFormatted = value.toString();
            var lastThree = totalFormatted.substring(totalFormatted.length - 3);
            var otherNumbers = totalFormatted.substring(0, totalFormatted.length - 3);
            if (otherNumbers !== '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            return (res)
        }
        else return value
    }

    function truncate(input) {
        if (input.length > 5) {
            return input.substring(0, 5) + '...';
        }
        return input;
    };

    return (<div><Layout mainContent={<div data-aos="fade-up" className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                </Link>{" "}
                &#10095;
                <span className="ml-2">
                    Financial Information
                </span>
            </p>
        </div>
        <div className="headings BranchNetworkHeading">Financial Information</div>
        <Tabs
            defaultActiveKey="financialhighlights"
            id="uncontrolled-tab-example"
        >
            <Tab eventKey="financialhighlights" title="Financial Highlights">
                <TabContent className="ProductContent mt-3">
                    <div >
                        <Table responsive bordered>
                            <thead className="text-left">
                                <tr style={{ color: "#E31A52" }}>
                                    <th>Particulars</th>
                                    <th>Units</th>
                                    <th>FY15</th>
                                    <th>FY16</th>
                                    <th>FY17</th>
                                    <th>FY18</th>
                                    <th>FY19</th>
                                    <th>FY20</th>
                                    <th>CAGR</th>
                                </tr>
                            </thead>
                            <tbody className="text-left">
                                {financialInformation.length > 0 ? financialInformation[0].FinancialHighlights.map((financeHighlight, index) => {
                                    return (<tr key={index}>
                                        <td>{financeHighlight.particulars}</td>
                                        <td>{financeHighlight.units}</td>
                                        <td>{convertToFormattedValue(financeHighlight.FY15)}</td>
                                        <td>{convertToFormattedValue(financeHighlight.FY16)}</td>
                                        <td>{convertToFormattedValue(financeHighlight.FY17)}</td>
                                        <td>{convertToFormattedValue(financeHighlight.FY18)}</td>
                                        <td>{convertToFormattedValue(financeHighlight.FY19)}</td>
                                        <td>{convertToFormattedValue(financeHighlight.FY20)}</td>
                                        <td>{financeHighlight.CAGR}</td>
                                    </tr>)
                                }) : ""}
                            </tbody>
                        </Table>
                    </div>
                </TabContent>
            </Tab>
            <Tab eventKey="quarterlyresults" title="Quarterly Results">
                <TabContent className="ProductContent mt-3">
                    <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="FY 2020 - 21">
                            <Row className="pill-row p-3">
                                <Col sm={3} className="pill-column">
                                    <Nav variant="pills" className="flex-column">
                                        {quarterlyResults ? quarterlyResults.map((quarter, index) => {
                                            return (
                                                <Nav.Item>
                                                    <Nav.Link eventKey={quarter.FinancialYear}>{quarter.FinancialYear}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        }) : ""}
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    {quarterlyResults ? quarterlyResults.map((quarter, index) => {
                                        return (
                                            <Tab.Content style={{ overflow: "auto" }}>
                                                <Tab.Pane eventKey={quarter.FinancialYear}>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ fontWeight: "bold", color: "#E31A52" }}>{quarter.FinancialYear}</th>
                                                                <th className="text-center" style={{ color: "#E31A52" }}>Q1</th>
                                                                <th className="text-center" style={{ color: "#E31A52" }}>Q2</th>
                                                                <th className="text-center" style={{ color: "#E31A52" }}>Q3</th>
                                                                <th className="text-center" style={{ color: "#E31A52" }}>Q4</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ fontWeight: "bold" }}>Financial results</td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QOneFinancialResults ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QOneFinancialResults.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.25rem" }} size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QTwoFinancialResults ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QTwoFinancialResults.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.25rem" }} size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QThreeFinancialResults ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QThreeFinancialResults.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.25rem" }} size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QFourFinancialResults ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QFourFinancialResults.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.25rem" }} size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontWeight: "bold" }}>Earnings presentation / update</td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QOneEarningsPresentationUpdate ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QOneEarningsPresentationUpdate.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faMoneyCheck} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QTwoEarningsPresentationUpdate ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QTwoEarningsPresentationUpdate.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faMoneyCheck} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QThreeEarningsPresentationUpdate ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QThreeEarningsPresentationUpdate.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faMoneyCheck} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QFourEarningsPresentationUpdate ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QFourEarningsPresentationUpdate.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faMoneyCheck} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontWeight: "bold" }}>Earnings Call Invite</td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QOneEarningsConferenceCall ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QOneEarningsConferenceCall.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faNewspaper} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QTwoEarningsConferenceCall ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QTwoEarningsConferenceCall.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faNewspaper} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QThreeEarningsConferenceCall ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QThreeEarningsConferenceCall.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faNewspaper} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QFourEarningsConferenceCall ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QFourEarningsConferenceCall.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faNewspaper} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontWeight: "bold" }}>Earnings Call Transcripts</td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QOneEarningsCallTranscript ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QOneEarningsCallTranscript.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faBookOpen} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QTwoEarningsCallTranscript ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QTwoEarningsCallTranscript.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faBookOpen} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QThreeEarningsCallTranscript ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QThreeEarningsCallTranscript.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faBookOpen} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QFourEarningsCallTranscript ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QFourEarningsCallTranscript.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.5rem" }} size="2x" icon={faBookOpen} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontWeight: "bold" }}>Public Disclosure on Liquidity</td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QOnePublicDisclosure ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QOnePublicDisclosure.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.35rem" }} size="2x" icon={faKey} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QTwoPublicDisclosure ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QTwoPublicDisclosure.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.35rem" }} size="2x" icon={faKey} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QThreePublicDisclosure ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QThreePublicDisclosure.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.35rem" }} size="2x" icon={faKey} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {quarter ? quarter.QFourPublicDisclosure ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + quarter.QFourPublicDisclosure.url}>
                                                                        <FontAwesomeIcon style={{ width: "1.35rem" }} size="2x" icon={faKey} ></FontAwesomeIcon>
                                                                    </a> : "-" : "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    {/* {quarter ? quarter.QOneOthers.length > 0 ? <Accordion defaultActiveKey="1">
                                                        <Card>
                                                            <Accordion.Toggle style={{ textAlign: "left", fontWeight: "bold" }} as={Card.Header} eventKey="0">
                                                                <span className="my-4">Quarter One - Other Files</span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body style={{ textAlign: "left", marginTop: "2rem", overflow: "auto" }} >
                                                                    {quarter ? quarter.QOneOthers.map((oneOther, index) => {
                                                                        return (
                                                                            <a className="pdfHighlight" style={{
                                                                                display: "block",
                                                                                whiteSpace: "nowrap",
                                                                                width: "70%",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis"
                                                                            }} target="_blank" href={process.env.REACT_APP_BASE_URL + oneOther.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(oneOther.name).split('.').slice(0, -1).join('.')}</a>)
                                                                    }) : ""}
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion> : "" : ""}
                                                    {quarter ? quarter.QTwoOthers.length > 0 ? <Accordion defaultActiveKey="1">
                                                        <Card>
                                                            <Accordion.Toggle style={{ textAlign: "left", fontWeight: "bold" }} as={Card.Header} eventKey="0">
                                                                <span className="my-4">Quarter Two - Other Files</span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body style={{ textAlign: "left", marginTop: "2rem", overflow: "auto" }} >
                                                                    {quarter ? quarter.QTwoOthers.map((oneOther, index) => {
                                                                        return (
                                                                            <a className="pdfHighlight" style={{
                                                                                display: "block",
                                                                                whiteSpace: "nowrap",
                                                                                width: "70%",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis"
                                                                            }} target="_blank" href={process.env.REACT_APP_BASE_URL + oneOther.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(oneOther.name).split('.').slice(0, -1).join('.')}</a>)
                                                                    }) : ""}
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion> : "" : ""}
                                                    {quarter ? quarter.QThreeOthers.length > 0 ? <Accordion defaultActiveKey="1">
                                                        <Card>
                                                            <Accordion.Toggle style={{ textAlign: "left", fontWeight: "bold" }} as={Card.Header} eventKey="0">
                                                                <span className="my-4">Quarter Three - Other Files</span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body style={{ textAlign: "left", marginTop: "2rem", overflow: "auto" }} >
                                                                    {quarter ? quarter.QThreeOthers.map((oneOther, index) => {
                                                                        return (
                                                                            <a className="pdfHighlight" style={{
                                                                                display: "block",
                                                                                whiteSpace: "nowrap",
                                                                                width: "70%",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis"
                                                                            }} target="_blank" href={process.env.REACT_APP_BASE_URL + oneOther.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(oneOther.name).split('.').slice(0, -1).join('.')}</a>)
                                                                    }) : ""}
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion> : "" : ""}
                                                    {quarter ? quarter.QFourOthers.length > 0 ? <Accordion defaultActiveKey="1">
                                                        <Card>
                                                            <Accordion.Toggle style={{ textAlign: "left", fontWeight: "bold" }} as={Card.Header} eventKey="0">
                                                                <span className="my-4">Quarter Four - Other Files</span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body style={{ textAlign: "left", marginTop: "2rem", overflow: "auto" }} >
                                                                    {quarter ? quarter.QFourOthers.map((oneOther, index) => {
                                                                        return (
                                                                            <a className="pdfHighlight" style={{
                                                                                display: "block",
                                                                                whiteSpace: "nowrap",
                                                                                width: "70%",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis"
                                                                            }} target="_blank" href={process.env.REACT_APP_BASE_URL + oneOther.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(oneOther.name).split('.').slice(0, -1).join('.')}</a>)
                                                                    }) : ""}
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion> : "" : ""}
                                                    {quarter ? quarter.ThisQuarterFiles.length > 0 ? <Accordion defaultActiveKey="1">
                                                        <Card>
                                                            <Accordion.Toggle style={{ textAlign: "left", fontWeight: "bold" }} as={Card.Header} eventKey="0">
                                                                <span className="my-4">General Files for {quarter.FinancialYear}</span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body style={{ textAlign: "left", marginTop: "2rem", overflow: "auto" }} >
                                                                    {quarter ? quarter.ThisQuarterFiles.map((oneOther, index) => {
                                                                        return (
                                                                            <a className="pdfHighlight" style={{
                                                                                display: "block",
                                                                                whiteSpace: "nowrap",
                                                                                width: "70%",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis"
                                                                            }} target="_blank" href={process.env.REACT_APP_BASE_URL + oneOther.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(oneOther.name).split('.').slice(0, -1).join('.')}</a>)
                                                                    }) : ""}
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion> : "" : ""} */}

                                                </Tab.Pane>
                                            </Tab.Content>)
                                    }) : ""}
                                    {/* {quarterlyResults ? quarterlyResults.map((quarter, index) => {

                                        return (<Tab.Content>
                                            {
                                                quarter.ThisQuarterFiles ? quarter.ThisQuarterFiles.map((file, index) => {
                                                    return (
                                                        <Tab.Pane eventKey={quarter.FinancialYear}>
                                                            <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + file.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(file.name).split('.').slice(0, -1).join('.')}</a>
                                                        </Tab.Pane>
                                                    )
                                                }) : "No Information Available"
                                            }
                                        </Tab.Content>)
                                    }) : ""} */}
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </TabContent>
            </Tab>
            <Tab eventKey="annualReports" title="Annual Reports">
                <TabContent className="ProductContent mt-3">
                    <Row>
                        <Col>
                            <div style={{
                                width: "80%",
                                backgroundImage: "url('http://134.209.154.158/backend/uploads/businesswoman_using_tablet_analysis_graph_company_finance_strategy_statistics_success_concept_planning_future_office_room_fdd6422d90.jpg')",
                                height: '100%',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                margin: 'auto',
                                backgroundPosition: 'initial',
                                borderRadius: '0.25rem',
                            }}>

                            </div>
                        </Col>
                        <Col className="col-sm-12 col-md-6 col-lg-6 col-12 m-auto">
                            <div class="grid-container">
                                {annualReports.AnnualReport ? annualReports.AnnualReport.map((report, index) => {
                                    return (
                                        <div class="grid-item" style={{ cursor: "pointer" }} onClick={() => showAnnualReport(process.env.REACT_APP_BASE_URL + report.url)}><p style={{ fontWeight: "bold", margin: 0 }}>FY'{(report.name).split('.').slice(0, -1).join('.').split("Annual Report")[1].split("-")[1].split("20")[1] == "" ? "20" : (report.name).split('.').slice(0, -1).join('.').split("Annual Report")[1].split("-")[1].split("20")[1]}</p></div>)
                                }) : ""}
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="pill-row p-3">
                        <Col>
                            {annualReports.AnnualReport ? annualReports.AnnualReport.map((report, index) => {
                                return (<div><a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + report.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(report.name).split('.').slice(0, -1).join('.')}</a></div>)
                            }) : ""}
                        </Col>
                    </Row> */}
                </TabContent>
            </Tab>
            <Tab eventKey="creditRatings" title="Credit Ratings">
                <TabContent className="ProductContent mt-3">
                    <Table responsive bordered>
                        <thead className="text-left">
                            <tr>
                                <th style={{ color: "#E31A52" }}>Rating Agency</th>
                                <th style={{ color: "#E31A52" }}>Instrument</th>
                                <th style={{ color: "#E31A52" }}>Rating</th>
                            </tr>
                        </thead>
                        <tbody className="text-left">
                            {creditRatings.length > 0 ? creditRatings[0].CreditRating.map((cRating, index) => {
                                return (<tr key={index}>
                                    <td>{cRating.Rating_agency}</td>
                                    <td>{cRating.Instrument}</td>
                                    <td>{cRating.Rating}</td>
                                </tr>)
                            }) : ""}
                        </tbody>
                    </Table>
                </TabContent>
            </Tab>
        </Tabs>

    </div>} /></div>)
}
import React, { Component } from "react";
import CountUp from "react-countup";
import * as StatService from "../services/services";
import "../styles/StatCounter.css";

class StatCounter extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, stats: [] };
  }
  componentDidMount() {
    StatService.getStats().then((resp) => {
      if (!resp) {
        return;
      }
      //   debugger;
      this.setState({ loading: false, stats: resp, end: null });
    });
  }
  render() {
    return (
      <div>
        {this.state.stats.length > 0 ? (
          <div
            className="StatBackground"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0,0.2), rgba(227, 26, 82,0.8)), url("${
                process.env.REACT_APP_BASE_URL +
                this.state.stats[0].StatBackground.url
              }")`,
              backgroundSize: `cover`,
            }}
          >
            <div className="Base-wrapper" data-aos="fade-up">
              <div className="StatList-wrapper" data-aos="fade-up">
                {this.state.stats.map((stat, index) => {
                  return (
                    <div key={stat.id} className="m-auto">
                      <img
                        className="StatIcon"
                        src={`${
                          process.env.REACT_APP_BASE_URL + stat.StatIcon.url
                        }`}
                        alt="Document list background"
                      />
                      <div className="StatData">
                        <p className="StatValue">
                          <CountUp delay={4} end={stat.StatValue} />
                        </p>
                        <p className="StatTitle">{stat.StatTitle}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
export default StatCounter;

import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import Layout from "./Layout";
import * as branchService from "../services/services";
import { Link } from "react-router-dom";
import { Col, Dropdown, Form, FormControl, Row, Card } from "react-bootstrap";

class BranchLocatorPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, SATList: [], stateList: [], branchList: [], stateName: "", branchName: "", selectedBranch: null, selectedSATCenter: {} };
    this.getBranches();
    this.SATCenterList();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  stateValue = (e) => {
    this.setState({ stateName: e.target.value });
  };
  branchValue = (e) => {
    this.setState({ branchName: e.target.value });
  };

  getBranches = (e) => {
    branchService.getBranchDetails().then((resp) => {
      if (!resp) {
        return;
      }
      this.setState({ stateList: resp })
      console.log(this.stateList)
    })
  }

  SATCenterList = (e) => {
    branchService.getSATCenter().then((res) => {
      if (!res) {
        return;
      }
      this.setState({ SATList: res }, () => { console.log(this.state.SATList) });
    });
  };

  setBranchDropdownValues(event) {
    console.log(event.target.value);
    this.state.stateList.forEach((state, index) => {
      if (state.id == event.target.value) {
        // let branch = [];
        // branch = 
        state.BranchLink.sort((branch1, branch2) => branch1.BranchName.localeCompare(branch2.BranchName))
        this.setState({ branchList: state.BranchLink })
      }
    })
  }
  showBranchDetails(event) {
    this.setState({ selectedSATCenter: {} }, () => { console.log(this.state.selectedSATCenter) })
    console.log(event.target.value)
    this.state.branchList.forEach((branch, index) => {
      if (branch.id == event.target.value) {
        this.setState({ selectedBranch: branch })
        if (this.state.SATList.length > 0) {
          this.state.SATList.forEach(satcentre => {
            if (branch.sat_center == satcentre.id) {
              this.setState({ selectedSATCenter: satcentre }, () => { console.log(this.state.selectedSATCenter) })
            }
          });
        }
      }
    })
  }
  filterSlashes = (content) => {
    let contentreturn = ""
    // if (content.includes(/\r/g)) {
    contentreturn = content.replace(/\\r/g, " ")
    // if (contentreturn.includes(/\n/g)) {
    contentreturn = contentreturn.replace(/\\n/g, " ")
    return contentreturn
    // }
    // else {
    // return contentreturn
    // }
    // }
    // else {
    // return content
    // }
  }
  render() {
    return (
      <div className="globalTopMargin">
        <Layout
          mainContent={
            <div className="Base-wrapper">
              <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                  }}
                >
                  <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    Branches
                  </span>
                </p>
              </div>
              <div
                className="headings BranchNetworkHeading"
              >
                Branch Locator
              </div>
              <div>

                <Form>
                  <Row className="m-auto branch-custom-row">
                    <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-8">
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" onChange={this.setBranchDropdownValues.bind(this)}>
                          <option>Select State</option>
                          {this.state.stateList.map((state, index) => {
                            return (
                              <option key={state.id} value={state.id}>{state.State}</option>
                            )
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-8">
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" onChange={this.showBranchDetails.bind(this)}>
                          <option>Select Branch</option>
                          {this.state.branchList.map((branch, index) => {
                            return (
                              <option key={branch.id} value={branch.id}>{branch.BranchName}</option>
                            )
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                {this.state.selectedBranch != null ?
                  <Row className="mt-5">
                    <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-6">
                      <Card className="py-5" style={{ boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px" }}><p className="font-tint mid-size-content mb-4 mt-0">Branch Details</p>
                        <p className="text-left mx-5"><div className="font-tint font-weight-bold">Branch Name - </div>{this.state.selectedBranch.BranchAddress} - {this.state.selectedBranch.BranchName}</p>
                        <p className="text-left mx-5" dangerouslySetInnerHTML={{ __html: "<div class='font-tint font-weight-bold'>Address - </div>" + this.filterSlashes(this.state.selectedBranch.BranchStreet) }}></p>
                        <p className="text-left mx-5"><div className="font-tint font-weight-bold">City - </div>{this.state.selectedBranch.BranchCity} - {this.state.selectedBranch.BranchPin}</p>
                        <p className="text-left mx-5"><div className="font-tint font-weight-bold">Email - </div>{this.state.selectedBranch.BranchEmail}</p>
                        <p className="text-left mx-5"><div className="font-tint font-weight-bold">Phone - </div>{this.state.selectedBranch.BranchContactDetails}</p>
                      </Card>
                    </Col>
                    {this.state.selectedSATCenter.SatCentreName ? <Col>
                      <Card className="py-5 h-100" style={{ boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px" }}><p className="font-tint mid-size-content mb-4 mt-0">Sat-Center Details</p>
                        <p className="text-left mx-5"><div className="font-tint font-weight-bold">Sat-Center Name - </div>{this.state.selectedSATCenter.SatCentreName}</p>
                        <p className="text-left mx-5" dangerouslySetInnerHTML={{ __html: "<div class='font-tint font-weight-bold'>Address - </div>" + this.state.selectedSATCenter.SatCentreAddress }}></p>
                        <p className="text-left mx-5"><div className="font-tint font-weight-bold">Sat-Center Incharge - </div>{this.state.selectedSATCenter.SatCentreIncharge}</p>
                        <p className="text-left mx-5"><div className="font-tint font-weight-bold">Sat-Center Contact - </div>{this.state.selectedSATCenter.SatCentreContactNumber}</p>
                      </Card>
                    </Col> : ""}
                  </Row> : <p>Choose a state and Branch to View the details</p>}
                {/* <input
                  className="BranchStateInput"
                  value={this.state.stateName}
                  placeholder="State Name"
                  type="text"
                  onChange={this.stateValue}
                />
                <input
                  className="BranchCityInput"
                  value={this.state.branchName}
                  placeholder="Branch Name"
                  type="text"
                  onChange={this.branchValue}
                /> */}
              </div>
              {/* <div style={{ marginBottom: "5rem" }}>
                <Button
                  className="SATCenterButton px-5"
                  onClick={this.SATCenterList}
                >
                  Click here for SAT Center List
                </Button>
              </div> */}
            </div>
          }
        />
      </div>
    );
  }
}
export default BranchLocatorPage;

import React, { Component } from "react";
import { Route, Switch } from "react-router";
import "./App.css";
import HomePage from "./pages/HomePage";
import ProductList from "./pages/ProductList";
import Products from "./pages/Products";
import { Redirect } from "react-router-dom";
import BranchLocatorPage from "./pages/BranchLocatorPage";
import { AboutUS } from "./pages/AboutUS";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FinancialInformation } from "./pages/FinancialInformation";
import { Announcements } from "./pages/Announcements"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { Shareprice } from "./pages/Shareprice";
import { ContactUS } from "./pages/ContactUS";
import { HomeLoanForm } from "./pages/HomeLoanForm";
import { CalculatorPage } from "./pages/CalculatorPage";
// import { FairPractice } from "./pages/FairPractice";
import { Downloads } from "./pages/Downloads";
import { Gallery } from "./pages/Gallery";
import { Careers } from "./pages/Careers";
import { NewsUpdates } from "./pages/NewsUpdates";
import { FAQQueriesPage } from "./pages/FAQs/FAQQueriesPage";
import { FAQHLGTaxDocuments } from "./pages/FAQs/FAQHLGTaxDocuments";
import { FAQHLGLoanProcessRHFL } from "./pages/FAQs/FAQHLGLoanProcessRHFL";
import { FAQHLGSupportingDocuments } from "./pages/FAQs/FAQHLGSupportingDocuments";
import { FAQGeneralInformation } from "./pages/FAQs/FAQGeneralInformation";
import { FAQHomePage } from "./pages/FAQs/FAQHomePage";
import { AuctionSaleNotices } from "./pages/AuctionSaleNotices";
import { AnalystCoverage } from "./pages/AnalystCoverage";
import { InvestorHomePage } from "./pages/Investors/InvestorHomePage";
import { InvestorCalendar } from "./pages/Investors/InvestorCalendar";
import { InvestorFAQs } from "./pages/Investors/InvestorFAQs";
import { InvestorContact } from "./pages/Investors/InvestorContact";
import { UnclaimedDividend } from "./pages/Investors/UnclaimedDividend";
import { TDSonDividend } from "./pages/Investors/TDSonDividend";
import { IntimationToStockExchange } from "./pages/IntimationToStockExchange";
import { CorporateGovernance } from "./pages/CorporateGovernance";
import { BidsDigitization } from "./pages/BidsDigitization";
import { GrievanceRedressal } from "./pages/GrievanceRedressal";
import { PoliciesAndCodes } from "./pages/PoliciesAndCodes";
import { StampDutyCalculator } from "./pages/StampDutyCalculator";
import { GuidelineValue } from "./pages/GuidelineValue";
import { AreaConversionCalculator } from "./pages/AreaConversionCalculator";
import { CalculatorsList } from "./pages/CalculatorsList";
import { EligibilityCalculator } from "./pages/EligibilityCalculator";
import { NotFound } from "./pages/404";
import SatCenterList from "./pages/SatCenterList";
// import { Launchingsoon } from "./pages/Launchingsoon";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      popupChatbox: false
    };
  }
  componentDidMount() {
    AOS.init({
      duration: 2000
    })
  }
  render() {
    const openForm = (e) => {
      // document.getElementById("myForm").style.display = "block";
      this.setState({ popupChatbox: true })
    }

    const closeForm = (e) => {
      // document.getElementById("myForm").style.display = "none";
      this.setState({ popupChatbox: false })
    }
    return (
      <div className="App user-select-none">
        <main className="App-content">
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            {/* <Route exact path="/launchingsoon" component={Launchingsoon} /> */}
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/about" component={AboutUS} />
            {/* <Route exact path="/fair-practice-policy" component={FairPractice} /> */}
            <Route exact path="/downloads" component={Downloads} />
            <Route exact path="/financial-information" component={FinancialInformation} />
            <Route exact path="/products" component={ProductList} />
            <Route exact path="/products/:id" component={Products} />
            <Route exact path="/branches" component={BranchLocatorPage} />
            <Route exact path="/satcenters" component={SatCenterList} />
            <Route exact path="/announcements" component={Announcements} />
            <Route exact path="/share-details" component={Shareprice} />
            <Route exact path="/contact" component={ContactUS} />
            <Route exact path="/apply-now" component={HomeLoanForm} />
            <Route exact path="/emi-calculator" component={CalculatorPage} />
            <Route exact path="/eligibility-calculator" component={EligibilityCalculator} />
            <Route exact path="/stamp-duty-calculator" component={StampDutyCalculator} />
            <Route exact path="/area-conversion-calculator" component={AreaConversionCalculator} />
            <Route exact path="/other-calculators" component={CalculatorsList} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/news" component={NewsUpdates} />
            <Route exact path="/faqs" component={FAQHomePage} />
            <Route exact path="/faqs/queries" component={FAQQueriesPage} />
            <Route exact path="/faqs/home-loan-tax-benefits" component={FAQHLGTaxDocuments} />
            <Route exact path="/faqs/home-loan-process" component={FAQHLGLoanProcessRHFL} />
            <Route exact path="/faqs/home-loan-supporting-doc" component={FAQHLGSupportingDocuments} />
            <Route exact path="/faqs/general" component={FAQGeneralInformation} />
            <Route exact path="/auction-sale-notices" component={AuctionSaleNotices} />
            <Route exact path="/analyst-coverage" component={AnalystCoverage} />
            <Route exact path="/investors" component={InvestorHomePage} />
            <Route exact path="/investors/investor-calendar" component={InvestorCalendar} />
            <Route exact path="/investors/investor-faq" component={InvestorFAQs} />
            <Route exact path="/investors/investor-contact" component={InvestorContact} />
            <Route exact path="/investors/unclaimed-dividend" component={UnclaimedDividend} />
            <Route exact path="/investors/tds-dividend" component={TDSonDividend} />
            <Route exact path="/intimation-stock-exchange" component={IntimationToStockExchange} />
            <Route exact path="/corporate-governance" component={CorporateGovernance} />
            <Route exact path="/bids-digitization" component={BidsDigitization} />
            <Route exact path="/grievances" component={GrievanceRedressal} />
            <Route exact path="/policies-and-codes" component={PoliciesAndCodes} />
            <Route exact path="/guideline-values" component={GuidelineValue} />
            <Route path="*" component={NotFound} />
          </Switch>
        </main>
        {/* <div className="chatbox">
          {this.state.popupChatbox ? <div className="chat-popup" id="myForm">
            <form action="#" className="form-container">
              <h1>Chat</h1>

              <label for="msg"><b>Message</b></label>
              <textarea placeholder="Type message.." name="msg" required></textarea>

              <button type="submit" className="btn">Send</button>
              <button type="button" className="btn cancel" onClick={closeForm}>Close</button>
            </form>
          </div> : ""}
        </div> */}
        {/* <div className="chatButton">
          {!this.state.popupChatbox ? <FontAwesomeIcon onClick={openForm} className="chatIcon" icon={faCommentAlt} /> : ""}
        </div> */}
      </div>
    );
  }
}
export default App;

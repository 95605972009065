import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import * as NavbarService from "../services/services";
import * as ProductService from "../services/services";
import { EnquiryForm } from "./EnquiryForm";
import "../styles/Navbar.css";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faHeadset, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      productsShow: false,
      noticesShow: false,
      isTop: true,
      loading: true,
      navbarOptions: [],
      productList: [],
      enquiryForm: false,
      homepage: window.location.pathname === '/home',
    };
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    });
    NavbarService.getNavbarOptions().then((resp) => {
      if (!resp) {
        return;
      }
      //   debugger;
      this.setState({ loading: false, navbarOptions: resp });
    });
    ProductService.getProductList().then((resp) => {
      if (!resp) {
        return;
      }
      this.setState({
        loading: false,
        productList: resp.sort((a, b) => {
          return a.ProductOrder - b.ProductOrder;
        }),
      });
      //   console.log(this.state.productList);
    });
  }

  getCurrentTime = () => {
    return moment().format("HH:mm")
  }

  showExploreDropdown = (e) => {
    this.setState({ show: true });
  }
  hideExploreDropdown = e => {
    this.setState({ show: false });
  }
  toggleExploreDropdown = e => {
    this.setState({ show: !this.state.show });
  }

  showProductsDropdown = (e) => {
    this.setState({ productsShow: true });
  }
  hideProductsDropdown = e => {
    this.setState({ productsShow: false });
  }
  toggleProductsDropdown = e => {
    this.setState({ productsShow: !this.state.productsShow });
  }

  toggleNotices = e => {
    this.setState({ noticesShow: !this.state.noticesShow });
  }

  toggleEnquiryForm = () => {
    this.setState({ enquiryForm: !this.state.enquiryForm })
  }
  render() {
    if (!this.loading) {
      return (
        <div>
          <div className="sticky-left" data-aos="fade-right">
            <ul className="social-left">
              {!this.state.homepage ? <Link to="/home"><li className="home-left">
                <FontAwesomeIcon style={{ width: "1.15rem" }} icon={faHome} />
              </li></Link> : ""}
              <li className={!this.state.homepage ? "email-left" : "email-left-others"} onClick={this.toggleEnquiryForm}>
                <p className="enquire">Enquire now!</p>
                {/* <a href="#"> */}
                <img
                  style={{ width: "1.15rem" }}
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/uploads/email_a420f3db5c.png"
                  }
                  alt="email_link"
                />
                {/* </a> */}
              </li>
            </ul>
            {this.state.enquiryForm ? <EnquiryForm closeEnquiryForm={this.state.enquiryForm} checkToggleStatus={this.toggleEnquiryForm} /> : ""}
          </div>
          <div className="sticky-right" data-aos="fade-left">
            <ul className="social-right">
              <li className="fb">
                <a href="https://www.facebook.com/RepcoHomeFinanceOfficial/" target="_blank">
                  <img
                    style={{ width: "1.15rem" }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/uploads/facebook_422801247e.png"
                    }
                    alt="facebook_link"
                  />
                </a>
              </li>
              <li
                className="twitter"
              >
                <a href="https://twitter.com/RepcoHome" target="_blank">
                  <img
                    style={{ width: "1.15rem" }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/uploads/twitter_e7b2b04777.png"
                    }
                    alt="twitter_link"
                  />
                </a>
              </li>
              <li
                className="call"
              >
                <a href="tel:9444394918">
                  <FontAwesomeIcon style={{ width: "1.15rem", transform: "rotate(90deg)", color: "white" }} icon={faPhone} />
                </a>
              </li>

              <li
                className="tollfree"
              >
                <a href="tel:1800-425-6070">
                  <FontAwesomeIcon style={{ width: "1.15rem", color: "white" }} icon={faHeadset} />
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="firstNav">
            <Navbar expand="lg" fixed="top" className="m-auto">
              <p></p>
              <p className="contactFnav">Call: 9444394918</p>
              <p className="contactFnav">E-mail: co@repcohome.com</p>
              <p className="contactFnav">Toll Free Number: 1800-425-6070</p>
            </Navbar>
          </div> */}
          <div className="secondNav lower-mid-size-content">
            <Navbar className="secondNav" expand="lg" fixed="top">
              <Link className="secondNav" to="/">
                <Navbar.Brand className="secondNav">
                  {((this.state.navbarOptions.length > 0) ? (
                    this.state.isTop ? (<img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        this.state.navbarOptions[0].RepcoLogo.url
                      }
                      alt="repco logo"
                    />) : (<img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        this.state.navbarOptions[1].RepcoLogo.url
                      }
                      alt="repco logo"
                    />)
                  ) :
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        `/uploads/repcohome_logo_7c80641cfb.png`
                      }
                      alt="repco logo"
                    />
                  )}
                </Navbar.Brand>
              </Link>
              <Navbar.Toggle
                className="secondNav"
                aria-controls="navbar"
              />
              <Navbar.Collapse
                className="secondNav justify-content-end"
                id="navbar-collapse"
              >
                <Nav className="secondNav mr-0">
                  <Nav.Link href="/about">About Us</Nav.Link>
                  <div className="productDesktop-hide">
                    <NavDropdown title="Products" id="productdropdown-desktop" show={this.state.productsShow}
                      onMouseEnter={this.showProductsDropdown}
                      onMouseLeave={this.hideProductsDropdown}>
                      {this.state.productList.length > 0 ? (
                        <div className="productdropdown-desktop">
                          {this.state.productList.map((products, index) => {
                            return (
                              <>
                                {!products.IsASpecialProduct ?
                                  <div key={products.ProductId}>
                                    <NavDropdown.Item
                                      href={"/products/" + products.ProductId}
                                    >
                                      <p className="m-0">{products.ProductName}</p>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                  </div> : null}
                              </>
                            );
                          })}
                          <p className="text-center m-0 text-white p-2" style={{ backgroundColor: "#E31A52" }}>Repco Special Loans</p>
                          <NavDropdown.Divider />
                          {this.state.productList.map((products, index) => {
                            return (
                              <>
                                {products.IsASpecialProduct ?
                                  <div key={products.ProductId}>
                                    <NavDropdown.Item
                                      href={"/products/" + products.ProductId}
                                    >
                                      <p className="m-0">{products.ProductName}</p>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                  </div> : null}
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </NavDropdown>
                  </div>
                  <div className="productMobile-hide">
                    <NavDropdown title="Products" id="productdropdown-mobile" style={{ width: "18rem", margin: "auto" }} show={this.state.productsShow}
                      onClick={this.toggleProductsDropdown}>
                      {this.state.productList.length > 0 ? (
                        <div>
                          {this.state.productList.map((products, index) => {
                            return (
                              <>
                                {products.IsASpecialProduct ?
                                  <div key={products.ProductId}>
                                    <NavDropdown.Item
                                      href={"/products/" + products.ProductId}
                                    >
                                      <p className="m-0" style={{
                                        whiteSpace: "nowrap",
                                        width: "15rem",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "0.75rem"
                                      }}>{products.ProductName}</p>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                  </div> : null}
                              </>
                            );
                          })}
                          <p className="text-center m-0 text-white p-2" style={{ backgroundColor: "#E31A52" }}>Repco Special Loans</p>
                          <NavDropdown.Divider />
                          {this.state.productList.map((products, index) => {
                            return (
                              <>
                                {products.IsASpecialProduct ?
                                  <div key={products.ProductId}>
                                    <NavDropdown.Item
                                      href={"/products/" + products.ProductId}
                                    >
                                      <p className="m-0" style={{
                                        whiteSpace: "nowrap",
                                        width: "15rem",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "0.75rem"
                                      }}>{products.ProductName}</p>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                  </div> : null}
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </NavDropdown>
                  </div>
                  <div className="exploreDesktop-hide">
                    <NavDropdown title="Investors" id="exploredropdown-desktop" show={this.state.show}
                      onMouseEnter={this.showExploreDropdown}
                      onMouseLeave={this.hideExploreDropdown}>
                      {/* <NavDropdown.Item href="/about">
                        About Repco
                    </NavDropdown.Item> */}
                      <NavDropdown.Item href="/financial-information">
                        Financial Information
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/announcements">
                        {" "}
                        Announcements
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/share-details">
                        {" "}
                        Share Price & Ownership
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/corporate-governance">
                        {" "}
                        Corporate Governance
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/analyst-coverage">
                        {" "}
                        Analyst Coverage
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/investors">
                        Investor Information
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/intimation-stock-exchange">
                        Intimation to Stock Exchanges
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                  <div className="exploreMobile-hide">
                    <NavDropdown title="Investors" id="exploredropdown-mobile" style={{ maxWidth: "18rem", margin: "auto" }} show={this.state.show}
                      onClick={this.toggleExploreDropdown}>
                      {/* <NavDropdown.Item href="/about">
                        About Repco
                    </NavDropdown.Item> */}
                      <NavDropdown.Item href="/financial-information">
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Financial Information</p>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/announcements">
                        {" "}
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Announcements</p>

                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/share-details">
                        {" "}
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Share Price & Ownership</p>

                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/corporate-governance">
                        {" "}
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Corporate Governance</p>

                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/analyst-coverage">
                        {" "}
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Analyst Coverage</p>

                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/investors">
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Investor Information</p>

                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/intimation-stock-exchange">
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Intimation to Stock Exchanges</p>

                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                  <Nav.Link href="/careers">Careers</Nav.Link>
                  <div className="noticeDesktop-hide">
                    <NavDropdown title="Notices" id="noticedropdown-desktop" show={this.state.noticesShow}
                      onMouseEnter={this.toggleNotices}
                      onMouseLeave={this.toggleNotices}>
                      <NavDropdown.Item href="/auction-sale-notices" style={{ overflow: "hidden" }}>
                        Auction Sale Notices
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/bids-digitization" style={{ overflow: "hidden" }}>
                        Bids Digitization
                      </NavDropdown.Item></NavDropdown>
                  </div>
                  <div className="noticeMobile-hide">
                    <NavDropdown title="Notices" id="noticedropdown-mobile" style={{
                      width: "18rem",
                      margin: "auto"
                    }} show={this.state.noticesShow}
                      onClick={this.toggleNotices}>
                      <NavDropdown.Item href="/auction-sale-notices" style={{ overflow: "hidden" }}>
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Auction Sale Notices</p>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/bids-digitization" style={{ overflow: "hidden" }}>
                        <p className="m-0" style={{
                          whiteSpace: "nowrap",
                          width: "15rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem"
                        }}>Bids Digitization</p>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                  <Nav.Link className="newsSection" href="/news">
                    News Updates
                  </Nav.Link>
                  <a href="https://www.onlinesbi.com/sbicollect/icollecthome.htm?corpID=928381" target="_blank"><Button className="PayOnline mx-3">Pay Online</Button></a>
                  <Navbar.Text className="p-0" style={{ maxWidth: "10rem", margin: "auto" }}>
                    <Row>
                      <Col ><img style={{ width: "3rem" }} src={process.env.REACT_APP_BASE_URL + "/uploads/be_logo_54fe92db80.gif"} /><span style={{ fontWeight: "bold" }}>&nbsp;288.95&nbsp;</span></Col>
                      <Col><img style={{ width: "3rem", marginLeft: "-5px" }} src={process.env.REACT_APP_BASE_URL + "/uploads/nse_logo_2f11a4b7f3.png"} /><span style={{ fontWeight: "bold" }}>&nbsp;285.00</span></Col>
                    </Row>
                    <Col style={{ fontWeight: "bold", fontSize: "0.75rem" }}>Time: &nbsp; {this.getCurrentTime()}</Col>
                  </Navbar.Text>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      );
    }
  }
}

export default NavbarComponent;

import Layout from "./Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card, Row, Col } from "react-bootstrap";
import * as GrievanceService from "../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";


export function GrievanceRedressal() {
    const [GrievanceDetails, setGrievanceDetails] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        GrievanceService.getGrievances().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setGrievanceDetails(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Grievance Redressal
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Grievance Redressal
            </div>
            <div>
                <div style={{ textAlign: "justify" }}>
                    <Row>
                        <Col className="col-12 col-sm-12 col-md-12 col-lg-6 mb-5">
                            <Card className="px-5 py-5">
                                <p style={{ color: "#E31A52" }}>
                                    <b>Any Customer of RHFL can take up his grievance with the concerned Branch Manager of the Company.</b>
                                </p>
                                <p>The Branch Manager is required to reply to the Customer within 7 working days from the date of receipt of the complaint in the office.</p>

                                <p>If the customer is not satisfied with the reply provided by the Branch Manager or in case no reply is received within the stipulated period or the complaint is against the Branch Manager, the customer may take up the complaint with the Chief Development Officer of the Company at the following address:</p>
                                <b style={{ color: "#E31A52" }}>The Chief Development Officer</b>
                                <b style={{ color: "#E31A52" }}>Repco Home Finance Limited</b>
                                <b style={{ color: "#E31A52" }}>Corporate Office</b>
                                <b style={{ color: "#E31A52" }}>3rd Floor, Alexander Square</b>
                                <b style={{ color: "#E31A52" }}>Old No 34&amp;35, New No.2, Sardar Patel Road</b>
                                <b style={{ color: "#E31A52" }}>Guindy, Chennai 600 032</b>
                                <b style={{ color: "#E31A52" }}>Phone: 044-42106650/6652 and +91-9444394918</b>
                                <b style={{ color: "#E31A52" }}>Fax: 044-42106651</b>
                                <b style={{ color: "#E31A52" }}>Email: grievance@repcohome.com</b>
                            </Card>
                        </Col>

                        <Col className="col-12 col-sm-12 col-md-12 col-lg-6 mb-5" style={{ height: "auto" }}>
                            <Card className="px-5 py-5" style={{ height: "100%" }}>
                                <p style={{ color: "#E31A52" }}><b>The Chief Development Officer is required to dispose off the complaint within 30 days of receipt of the complaint in the Corporate Office.</b></p>
                                <p>If the customer is not satisfied with the reply provided by the Chief Development Officer or in case no reply is received within the stipulated period, the customer may approach the National Housing Bank at the following address:</p>
                                <b style={{ color: "#E31A52" }}>The General Manager</b>
                                <b style={{ color: "#E31A52" }}>National Housing Bank</b>
                                <b style={{ color: "#E31A52" }}>Department of Regulation and Supervision</b>
                                <b style={{ color: "#E31A52" }}>Complaint Redressal Cell</b>
                                <b style={{ color: "#E31A52" }}>4th Floor, Core 5A, India Habitat Centre</b>
                                <b style={{ color: "#E31A52" }}>Lodhi Road</b>
                                <b style={{ color: "#E31A52" }}>New Delhi 110 003</b>
                                <b style={{ color: "#E31A52" }}>Website: https://grids.nhbonline.org.in</b>
                            </Card>
                        </Col>
                    </Row>
                </div>
                {/* {GrievanceDetails.length > 0 ? <div className="text-left" dangerouslySetInnerHTML={{ __html: GrievanceDetails[0].GrievanceInformation }}></div> : ""} */}
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"0"}>
                            Customer Grievance Redressal
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"0"}>
                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                {GrievanceDetails.length > 0 ? <p><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;<a className="pdfHighlight-line-space font-weight-bold" href={process.env.REACT_APP_BASE_URL + GrievanceDetails[0].CustomerGrievanceRedressal.url} target="_blank">Customer Grievance redressal policy</a></p> : null}
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"1"}>
                            Escalation Matrix
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"1"}>
                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                {GrievanceDetails.length > 0 ? <p><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;<a className="pdfHighlight-line-space font-weight-bold" href={process.env.REACT_APP_BASE_URL + GrievanceDetails[0].EscalationMatrix.url} target="_blank">Escalation Matrix</a></p> : null}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>} />
    </div >)
}
import Layout from "./Layout";
import * as BidsService from "../services/services";
import { useEffect, useState } from "react";
import { Col, Row, Table, Tabs, Tab, TabContent, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/FinancialInformation.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'

export function BidsDigitization() {
    const [bidInformation, setBidInformation] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        BidsService.getBidInformation().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setBidInformation(resp);
            }
        })
        return () => mounted = false;

    }, [])

    return (<div><Layout mainContent={<div data-aos="fade-up" className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                </Link>{" "}
                &#10095;
                <span className="ml-2">
                    Bids Digitization
                </span>
            </p>
        </div>
        <div className="headings BranchNetworkHeading">Bids Digitization</div>

        <Table responsive bordered>
            <thead className="">
                <tr>
                    <th style={{ color: "#E31A52" }}>RFP No.</th>
                    <th style={{ color: "#E31A52" }}>Description</th>
                    <th style={{ color: "#E31A52" }}>Start Date</th>
                    <th style={{ color: "#E31A52" }}>End Date</th>
                    <th style={{ color: "#E31A52" }} colSpan="6">Downloads</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colSpan="3" style={{ color: "#E31A52" }} className="font-weight-bold">RFP</td>
                    <td colSpan="3" style={{ color: "#E31A52" }} className="font-weight-bold">Addendum</td>
                </tr>
                {bidInformation.length > 0 ? bidInformation.map((bid, index) => {

                    return (<tr key={index}>
                        <td style={{ verticalAlign: "middle" }}>{bid.RFPNumber}</td>
                        <td style={{ verticalAlign: "middle" }}>{bid.Description}</td>
                        <td style={{ verticalAlign: "middle" }}>{moment(bid.StartDate).format('lll')}</td>
                        <td style={{ verticalAlign: "middle" }}>{moment(bid.EndDate).format('lll')}</td>
                        <td style={{ verticalAlign: "middle" }} colSpan="3">{bid.RFPDownloadFiles ? <a className="pdfHighlight-line-space font-weight-bold" href={process.env.REACT_APP_BASE_URL + bid.RFPDownloadFiles.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{bid.RFPDownloadFiles.name}</a> : "-"}</td>
                        <td style={{ verticalAlign: "middle" }} colSpan="3">{bid.AddendumDownloadFiles ? <a className="pdfHighlight-line-space font-weight-bold" href={process.env.REACT_APP_BASE_URL + bid.AddendumDownloadFiles.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{bid.AddendumDownloadFiles.name}</a> : "-"}</td>
                    </tr>)
                }) : ""}
            </tbody>
        </Table>
    </div>} /></div>)
}
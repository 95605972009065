import Layout from "../Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Button, Card, Modal, Table } from "react-bootstrap";
import * as CareerService from "../../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faList } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";


export default function CurrentOpenings({ open, close, events, changeTab, type }) {
    const [careerDetails, setCareerDetails] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [showJobModal, setshowJobModal] = useState(false);

    const handleEvent = event => {
        // Pass event to caller via the onChild2Event prop.
        // You can do something with the event, or just pass it.
        console.log("1. Event fired in Child2! Doing something with event before passing..");
        handleClose();
        changeTab();
    };

    const handleClose = () => {
        setshowJobModal(false);
    }
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        CareerService.getCurrentOpeningsByBranch().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                let details = [];
                setCareerDetails(details)
                resp.forEach((res, index) => {
                    console.log(res)
                    console.log(res.ArchivedJob, type)
                    if (type == "Archives") {
                        // if (res.ArchivedJob) {\
                        console.log(moment().isBetween
                            (res.FromDate, res.ToDate))
                        if (!moment().isBetween
                            (res.FromDate, res.ToDate)) {
                            res["BranchCity"] = res.BranchCity;
                            // job["BranchState"] = res.branch_locator_state.State;
                            details.push(res)
                            console.log(careerDetails)
                            setCareerDetails(details)
                        }
                        // }
                    }
                    else {
                        // if (!res.ArchivedJob) {

                        //     console.log(res)
                        //     res["BranchCity"] = res.BranchCity;
                        //     // job["BranchState"] = res.branch_locator_state.State;
                        //     details.push(res)
                        //     console.log(careerDetails)
                        //     setCareerDetails(details)
                        // }
                        if (moment().isBetween
                            (res.FromDate, res.ToDate)) {
                            res["BranchCity"] = res.BranchCity;
                            // job["BranchState"] = res.branch_locator_state.State;
                            details.push(res)
                            console.log(careerDetails)
                            setCareerDetails(details)
                        }
                    }
                });
                // setCareerDetails(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        {/* <p className="text-right">* Click on a job to view more details!</p> */}
        <Accordion defaultActiveKey="0">
            <Card>
                {careerDetails.length > 0 ? careerDetails.map((detail, index) => {
                    return (<>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={index.toString()}>
                            {detail.JobTitle}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                <a href={process.env.REACT_APP_BASE_URL + detail.NotificationDocument.url} target="_blank"><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;Notification for {detail.JobTitle}</p></a>
                                {!detail.ArchivedJob ? detail.ApplyOffline ? <a href={process.env.REACT_APP_BASE_URL + detail.BioDataForm.url} target="_blank"><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;Download Bio Data form for the post of {detail.JobTitle}</p></a> :
                                    <p className="pdfHighlight text-left m-0" onClick={handleEvent}><FontAwesomeIcon style={{ color: "#FDB515" }} icon={faList}></FontAwesomeIcon> &nbsp;Fill the Online Application form for the post of {detail.JobTitle}</p> : ""}
                            </Card.Body>
                        </Accordion.Collapse></>)
                }) : <p className="my-3 ml-3">{"No " + type + " Available"}</p>}
            </Card>
        </Accordion>
        {/* <Modal show={showJobModal} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Job Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                height: "50vh",
                overflow: "auto"
            }} className="px-md-5">
                {selectedJob ? <>
                    <p className="font-weight-bold" style={{
                        borderBottom: "0.15rem solid black", width: "fit-content",
                        paddingBottom: "0.25rem",
                        marginBottom: "1rem"
                    }}>Job Title</p>
                    <p>{selectedJob.JobTitle}</p>
                    <p className="font-weight-bold" style={{
                        borderBottom: "0.15rem solid black", width: "fit-content",
                        paddingBottom: "0.25rem",
                        marginBottom: "1rem"
                    }}>Job Type</p>
                    <p>{selectedJob.JobType}</p>
                    <p className="font-weight-bold" style={{
                        borderBottom: "0.15rem solid black", width: "fit-content",
                        paddingBottom: "0.25rem",
                        marginBottom: "1rem"
                    }}>Roles &amp; Responsibilities</p>
                    <p dangerouslySetInnerHTML={{ __html: selectedJob.RolesAndResponsibilities }}></p>
                    <p className="font-weight-bold" style={{
                        borderBottom: "0.15rem solid black", width: "fit-content",
                        paddingBottom: "0.25rem",
                        marginBottom: "1rem"
                    }}>Educational Background &amp; Professional Experience</p>
                    <p dangerouslySetInnerHTML={{ __html: selectedJob.EducationalBackgroundAndProfessionalExperience }}></p>
                    <p className="font-weight-bold" style={{
                        borderBottom: "0.15rem solid black", width: "fit-content",
                        paddingBottom: "0.25rem",
                        marginBottom: "1rem"
                    }}>Professional Competencies</p>
                    <p dangerouslySetInnerHTML={{ __html: selectedJob.PersonalCompetencies }}></p>
                    <p className="font-weight-bold" style={{
                        borderBottom: "0.15rem solid black", width: "fit-content",
                        paddingBottom: "0.25rem",
                        marginBottom: "1rem"
                    }}>Remuneration</p>
                    <p dangerouslySetInnerHTML={{ __html: selectedJob.Remuneration }}></p>
                </> : "..."}
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: "#FDB515", border: "none" }} onClick={handleEvent}>
                    Click here to apply
                </Button>
                <Button style={{ backgroundColor: "#E31A52", border: "none" }} onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal> */}
    </div >)
}
import Layout from "../Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import * as InvestorService from "../../services/services";


export function InvestorFAQs() {
    const [investorFAQs, setInvestorFAQs] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        InvestorService.getInvestorFAQs().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setInvestorFAQs(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                  </Link>{" "}
                  &#10095;
                  <Link to="/investors" className="mr-2">
                        Investors
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                        Investor FAQs
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Investor FAQs
            </div>
            <div>
                <Accordion defaultActiveKey="0">
                    {investorFAQs.length > 0 ? investorFAQs.map((faqs, index) => {
                        return (<Card>
                            <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={index.toString()}>
                                {faqs.FAQName}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body style={{ textAlign: "left", marginTop: "2rem" }} dangerouslySetInnerHTML={{ __html: faqs.FAQDescription }} >
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>)
                    }) : ""}
                </Accordion>
            </div>
        </div>} />
    </div >)
}
import Layout from "./Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import * as DownloadService from "../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { RateofInterest } from "./RateofInterest";


export function Downloads() {
    const [downloadDetails, setdownloadDetails] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        DownloadService.getDownloadInformation().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setdownloadDetails(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                        Downloads
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Downloads
            </div>
            <div>
                <Accordion defaultActiveKey="0">
                    {downloadDetails.length > 0 ? downloadDetails.map((downloads, index) => {
                        return (<Card>
                            <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={index.toString()}>
                                {downloads.DownloadType}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                    {downloads.DownloadItems.length > 0 ? downloads.DownloadItems.map((file, index) => {
                                        return (
                                            <p style={{ marginBottom: "0" }} className="mb-2">
                                                <a className="pdfHighlight-line-space font-weight-bold" target="_blank" href={process.env.REACT_APP_BASE_URL + file.url}>
                                                    <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;
                                                    {file.name}
                                                </a>
                                            </p>)
                                    }) : ""}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>)
                    }) : ""}
                </Accordion>
            </div>
            {/* <RateofInterest /> */}
        </div>} />
    </div >)
}
import React, { Component } from "react";
import "../styles/DocumentList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRupeeSign, faFile } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
// import "aos/dist/aos.css"


class DocumentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      documents: [],
    };
  }

  async componentDidMount() {
    let response = await fetch(
      process.env.REACT_APP_BASE_URL + "/document-collections"
    );
    if (!response.ok) {
      return;
    }

    let documents = await response.json();
    this.setState({ loading: false, documents: documents });
  }

  render() {
    if (!this.state.loading) {
      return (
        <div data-aos="fade-up" className="Base-wrapper DocumentList-container my-5">
          <p className="headings mb-5" style={{ marginTop: "5rem" }}>
            Documents Required For Home Loans
          </p>
          <div
            className="DocumentList-wrapper"
            style={{ marginTop: "5rem" }}
          >
            {this.state.documents.map((document, index) => {
              return (
                <div className="Card-style" key={document.id}>
                  {document.id == 1 ? (
                    <div className="DocumentIcon">
                      <FontAwesomeIcon className="FAIcon" icon={faUser} />
                    </div>
                  ) : (
                    ""
                  )}
                  {document.id == 3 ? (
                    <div className="DocumentIcon">
                      <FontAwesomeIcon className="FAIcon"
                        icon={faRupeeSign}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {document.id == 4 ? (
                    <div className="DocumentIcon">
                      <FontAwesomeIcon className="FAIcon" icon={faFile} />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-4">
                    <p className="DocumentListTitle mid-size-content">
                      {document.DocumentName}
                    </p>
                    <p className="DocumentListDescription lower-mid-size-content">
                      {document.DocumentDescription}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <Link to="/apply-now"><button style={{ marginTop: "3rem", marginBottom: "3rem" }} className="Carousel-applyButton small-size-content text-white"> Apply For Home Loan Now!</button></Link>
        </div>
      );
    }

    return <p className="DocumentList-title">Loading...</p>;
  }
}

export default DocumentList;

import Layout from "./Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import * as fairPracticePolicyService from "../services/services";


export default function FairPractice() {
    const [fairpractice, setFairPractice] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        fairPracticePolicyService.getFairPracticePolicyService().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setFairPractice(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        {/* <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                        Fair Practice Code
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Fair Practice Code
            </div> */}
        <div>
            <p style={{ textAlign: "left" }}>{fairpractice.length > 0 ? fairpractice[0].FairPracticeCodeMainAbstract : "Fair practice Code details!"}</p>
            <Accordion defaultActiveKey="0">
                {fairpractice.length > 0 ? fairpractice.map((practices, index) => {
                    return (<Card>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={index.toString()}>
                            {practices.FairPracticeAccordionHeading}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }} dangerouslySetInnerHTML={{ __html: practices.FairPracticeAccordionContent }} >
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>)
                }) : ""}
            </Accordion>
        </div>
    </div>)
    // } />
    // </div >)
}
import Layout from "./Layout";
import * as ContactService from "../services/services";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/ContactUS.css"

export function GuidelineValue() {
    const [stateList, setStateList] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        ContactService.getGuidelineDetails().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                console.log(resp)
                setStateList(resp)
            }
        })
        return () => mounted = false;

    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <Link to="/other-calculators" className="mr-2">
                        Other Calculators
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Guideline Values
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Guideline Values
            </div>
            {/* <Card className="pt-5"> */}
            <Table striped bordered hover className="pt-5">
                <thead>
                    <tr>
                        <th style={{ color: "#E31A52" }}>#</th>
                        <th style={{ color: "#E31A52" }}>State Name</th>
                        <th style={{ color: "#E31A52" }}>Guideline value</th>
                    </tr>
                </thead>
                <tbody>
                    {stateList.map((state, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{state.StateName}</td>
                                <td><a href={state.LinkToGuideline} target="_blank">{state.LinkToGuideline}</a></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>} />
    </div >)
}
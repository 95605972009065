import React, { Component } from "react";
import * as ProductService from "../services/services";
import Layout from "./Layout";
import { Tabs, Tab, TabContent, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, productList: null, downloadDetails: [] };

    ProductService.getProductList().then((products) => {
      if (!products) {
        return;
      }
      products.forEach((product) => {
        if (product.ProductId === props.match.params.id) {
          this.setState({ loading: false, productList: product });
        }
      });
    });
    ProductService.getDownloadInformation().then((downloads) => {
      this.setState({ downloadDetails: downloads })
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Layout
          mainContent={
            <div data-aos="fade-up">
              {this.state.productList ? (
                <div
                  className="ProductLabelTop mt-5 headings"
                  style={{
                    width: "100%",
                    height: "35rem",
                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL +
                      this.state.productList.ProductImage.url
                      })`,
                  }}
                >
                  <p className="ProductTitle">
                    {this.state.productList
                      ? this.state.productList.ProductName
                      : "Loading..."}
                  </p>
                </div>
              ) : (
                <div></div>
              )}

              <div className="breadcrumbs lower-mid-size-content mt-5">
                <p
                  style={{
                    width: "90%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                  }}
                >
                  <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <Link to="/products" className="mx-2">
                    Products
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    {this.state.productList
                      ? this.state.productList.ProductName
                      : "Loading..."}
                  </span>
                </p>
              </div>
              <div className="Base-wrapper">
                <Row className="cardlayout-margin">
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                    <div>
                      <Card
                        style={{
                          width: "100%",
                          margin: "auto",
                          marginTop: "2rem",
                          marginBottom: "2rem",
                          border: "none",
                        }}
                      >
                        {/* {this.state.productList ? (
                          this.state.productList.ProductImage ? (
                            <Card.Img
                              variant="top"
                              className="perProductImage"
                              src={
                                process.env.REACT_APP_BASE_URL +
                                this.state.productList.ProductImage.url
                              }
                              alt="Card image"
                            />
                          ) : (
                            <Card.Img
                              variant="top"
                              className="perProductImage"
                              src={
                                process.env.REACT_APP_BASE_URL +
                                this.state.productList.ProductImage.formats
                                  .small.url
                              }
                              alt="Card image"
                            />
                          )
                        ) : (
                          ""
                        )} */}
                        <Card.Body>
                          <Card.Title className="text-left mb-5 font-weight-bold headings">
                            {this.state.productList
                              ? this.state.productList.ProductName
                              : "Loading..."}
                          </Card.Title>
                          <Card.Text className="perProductCardText lower-mid-size-content" dangerouslySetInnerHTML={{
                            __html: this.state.productList
                              ? this.state.productList.ProductDescription
                              : "Loading..."
                          }}>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                    {this.state.productList ? <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "0.25rem",
                        margin: "auto"
                      }}
                    >
                      {this.state.productList.ProductIllustration.alternativeText == "Video" ?
                        <video className="bsProductImage" style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "0.25rem",
                          objectFit: "cover"
                        }} autoPlay muted loop>
                          <source src={process.env.REACT_APP_BASE_URL + this.state.productList.ProductIllustration.url} type="video/mp4" />
                          Your browser does not support HTML video.
                        </video> : <img className="bsProductImage" style={{
                          maxHeight: "30rem",
                          width: "100%",
                          borderRadius: "0.25rem",
                          objectFit: "cover"
                        }} src={process.env.REACT_APP_BASE_URL + this.state.productList.ProductIllustration.url} />}
                    </div> : ""}
                  </Col>
                </Row>
                <Row>
                  <div className="ProductDetailsTab">
                    <Tabs className="mid-size-content"
                      defaultActiveKey="features"
                      id="uncontrolled-tab-example"
                    >
                      <Tab eventKey="features" title="Features">
                        <TabContent className="ProductContent mt-3 lower-mid-size-content">
                          {/* <p>Features</p> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.productList
                                ? this.state.productList.ProductFeatures
                                : "Loading...",
                            }}
                          ></div>
                        </TabContent>
                      </Tab>
                      <Tab eventKey="eligibility" title="Eligibility">
                        <TabContent className="ProductContent mt-3 lower-mid-size-content">
                          {/* <p>Eligibility</p> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.productList
                                ? this.state.productList.ProductEligibility
                                : "Loading...",
                            }}
                          ></div>
                        </TabContent>
                      </Tab>
                      <Tab
                        eventKey="interestRates"
                        title="Interest Rates and Fees"
                      >
                        <TabContent className="ProductContent mt-3 lower-mid-size-content p-4">
                          {/* <p>Interest Rates and Fees</p> */}
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.productList
                                  ? this.state.productList
                                    .ProductInterestRatesAndFees
                                  : "Loading...",
                              }}
                            ></div>
                            {this.state.downloadDetails.length > 0 ? this.state.downloadDetails.map((downloads, index) => {
                              return (
                                <>
                                  {downloads.DownloadItems.length > 0 ? downloads.DownloadType == "Schedule of Charges" ? downloads.DownloadItems.map((file, index) => {
                                    return (
                                      <p className="mt-3">
                                        <a target="_blank" href={process.env.REACT_APP_BASE_URL + file.url}>
                                          <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;
                                          {file.name}
                                        </a></p>)
                                  }) : "" : ""
                                  }</>)
                            }) : ""}
                          </>
                        </TabContent>
                      </Tab>{" "}
                      <Tab
                        eventKey="documentsRequired"
                        title="Documents Required"
                      >
                        <TabContent className="ProductContent mt-3 lower-mid-size-content pl-4 pr-4 pt-4">
                          {/* <p>Documents Required</p> */}
                          <div className="Documents-Required"
                            dangerouslySetInnerHTML={{
                              __html: this.state.productList
                                ? this.state.productList.DocumentsRequired
                                : "Loading...",
                            }}
                          ></div>
                        </TabContent>
                      </Tab>
                    </Tabs>
                  </div>
                </Row>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default Products;

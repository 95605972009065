import Layout from "./Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import * as InvestorService from "../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";


export function IntimationToStockExchange() {
    const [intimations, setIntimations] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        InvestorService.getIntimations().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setIntimations(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Intimation to Stock Exchanges
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Intimation to Stock Exchanges
            </div>
            <div>
                <Accordion defaultActiveKey="0">
                    {intimations.length > 0 ? intimations.map((intimation, index) => {
                        return (<Card>
                            <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={index.toString()}>
                                {intimation.IntimationTitle}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body style={{ textAlign: "left", marginTop: "2rem" }} >
                                    {intimation.IntimationDocuments.length > 0 ? intimation.IntimationDocuments.map((idocs, index) => {
                                        return (
                                            <a href={process.env.REACT_APP_BASE_URL + idocs.url} target="_blank"><p className="pdfHighlight text-left"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{idocs.name.split(idocs.ext)[0]}</p></a>
                                        )
                                    }) : ""}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>)
                    }) : ""}
                </Accordion>
            </div>
        </div>} />
    </div >)
}
import Layout from "./Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import * as SharePriceService from "../services/services";
import { useEffect, useState } from "react";
import { Table, Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";


export function Shareprice() {
    const [shareDetails, setShareDetails] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        SharePriceService.getSharepriceDetails().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setShareDetails(resp);
            }
        })
        return () => mounted = false;
    }, [])
    return (<Layout mainContent={
        <div className="Base-wrapper">
            <div className="breadcrumbs mb-5 lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "90%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Share Price &amp; Ownership
                    </span>
                </p>
            </div>
            <div>
                <p className="headings">Current Share Pricing</p>
                <Table style={{ width: "90%", margin: "auto" }} striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ color: "#E31A52" }}>Stock Exchange</th>
                            <th style={{ color: "#E31A52" }}>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>NSE</td>
                            <td>
                                {/* {shareDetails ? shareDetails.NSEPrice : ""} */} 285.00
                            </td>
                        </tr>
                        <tr>
                            <td>BSE</td>
                            <td>
                                {/* {shareDetails ? shareDetails.BSEPrice : ""} */} 288.95
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            {/* <div style={{ backgroundColor: "#D3D3D380", padding: "2rem", borderRadius: "0.25rem", width: "90%", margin: "auto", marginTop: "3rem" }}> */}
            <Accordion defaultActiveKey="1" style={{ width: '90%', margin: "auto", marginTop: "5rem" }}>
                <Card>
                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey="0">
                        Share Holding Patterns
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ textAlign: "left", marginTop: "2rem", overflow: "auto" }} >

                            <div style={{ width: "100%", margin: "auto" }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{ color: "#E31A52" }}>Year</th>
                                            <th style={{ color: "#E31A52" }} className="text-center">Q1</th>
                                            <th style={{ color: "#E31A52" }} className="text-center">Q2</th>
                                            <th style={{ color: "#E31A52" }} className="text-center">Q3</th>
                                            <th style={{ color: "#E31A52" }} className="text-center">Q4</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shareDetails ? shareDetails.map((shareFiles, index) => {
                                            return (
                                                <tr>
                                                    <td style={{ width: "fit-content" }}>{shareFiles.Year}</td>

                                                    <td className="text-center">
                                                        {shareFiles ? shareFiles.QuarterOne ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + shareFiles.QuarterOne.url}>
                                                            <FontAwesomeIcon size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                        </a> : "-" : "-"}
                                                    </td>

                                                    <td className="text-center">
                                                        {shareFiles ? shareFiles.QuarterTwo ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + shareFiles.QuarterTwo.url}>
                                                            <FontAwesomeIcon size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                        </a> : "-" : "-"}
                                                    </td>

                                                    <td className="text-center">
                                                        {shareFiles ? shareFiles.QuarterThree ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + shareFiles.QuarterThree.url}>
                                                            <FontAwesomeIcon size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                        </a> : "-" : "-"}
                                                    </td>

                                                    <td className="text-center">
                                                        {shareFiles ? shareFiles.QuarterFour ? <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + shareFiles.QuarterFour.url}>
                                                            <FontAwesomeIcon size="2x" icon={faChartLine} ></FontAwesomeIcon>
                                                        </a> : "-" : "-"}
                                                    </td>
                                                </tr>
                                            )
                                        }) : ""}
                                    </tbody>
                                </Table>
                                {/* <p style={{ textAlign: "left" }}>
                                            <a href={process.env.REACT_APP_BASE_URL + shareFiles.url}>
                                                <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;
                                                {shareFiles.name}
                                            </a>
                                        </p> */}
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            {/* </div> */}
        </ div>
    } />)
}
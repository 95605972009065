import React, { Component } from "react";
import Layout from "./Layout";
import * as ProductService from "../services/services";
import { Card, Row, Col } from "react-bootstrap";
import "../styles/Products.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

export function CalculatorsList(props) {
  const [calculatorsList, setcalculatorsList] = useState([])

  useEffect(() => {
    let mounted = true;
    window.scrollTo(0, 0)
    setcalculatorsList([
      // { name: "Eligibility Calculator", link: "/emi-calculator" },
      { name: "Stamp Duty Calculator", link: "/stamp-duty-calculator" },
      { name: "Area Conversion Calculator", link: "/area-conversion-calculator" },
      { name: "Guideline Values", link: "/guideline-values" }
    ])
    return () => mounted = false;
  }, [])

  const gotoCalculator = (link) => {
    props.history.push(link);
  };
  return (
    <div className="ProductList-Wrapper">
      {/* <div className="Base-wrapper"> */}
      <Layout
        mainContent={
          <div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
              <p
                style={{
                  width: "100%",
                  textAlign: "left",
                  margin: "auto",
                  marginTop: "0rem",
                }}
              >
                <Link to="/" className="mr-2">
                  Home
                </Link>{" "}
                &#10095;
                <span className="ml-2">
                  Other Calculators
                </span>
              </p>
            </div>
            <div className="Productsheading">
              <div
                className="headings BranchNetworkHeading mt-4"
              >Other Calculators</div>
              <Row>
                {calculatorsList.map((calculator, index) => {
                  return (
                    <Col
                      className="col-12 col-sm-6 col-md-4 col-lg-4 mt-2 product-list-card"
                      onClick={() => { gotoCalculator(calculator.link) }}
                    >
                      <Card className="text-dark card-image-size cursor-pointer product-list-card">
                        <Card.ImgOverlay>
                          <Card.Title className="productListTitle mt-3">
                            {calculator.name}
                          </Card.Title>
                        </Card.ImgOverlay>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </div>
            {/* <ListGroup.Item className="lower-mid-size-content"><Link to="/emi-calculator">Eligibility Calculator</Link></ListGroup.Item>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/stamp-duty-calculator">Stamp Duty Calculator</Link></ListGroup.Item>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/area-conversion-calculator">Area Conversion Calculator</Link></ListGroup.Item>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/guideline-values">Guideline Values</Link></ListGroup.Item> */}
          </div>

        }
      />
    </div>)
}

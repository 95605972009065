import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import * as ProductService from "../services/services";
import "../styles/ProductListComponent.css";

class ProductListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, productList: [] };
    ProductService.getProductList().then((resp) => {
      if (!resp) {
        return;
      }
      this.setState({
        loading: false,
        productList: resp.sort((a, b) => {
          return a.ProductOrder - b.ProductOrder;
        }),
      });
      //   console.log(this.state.productList);
    });
  }
  render() {
    return (
      <div className="Base-wrapper" data-aos="fade-up">
        <p className="headings mb-1" style={{ marginTop: "5rem" }}>
          Our Products
        </p>
        <Row>
          {this.state.productList.map((products, index) => {
            return (
              <Col
                key={index}
                className="col-12 col-sm-6 col-md-4 col-lg-4"
                style={{
                  marginTop: "3rem",
                  marginBottom: "4rem",
                  borderRadius: "0.25rem",
                }}
              >
                <a
                  className="flip-card-link"
                  href={"/products/" + products.ProductId}
                >
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        {products.ProductImage ? (
                          <img
                            src={
                              process.env.REACT_APP_BASE_URL +
                              products.ProductImage.url
                            }
                            alt="product"
                            style={{
                              width: "100%",
                              height: "100%",
                              minWidth: "10rem",
                              minHeight: "8rem",
                              borderRadius: "0.25rem",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="flip-card-back small-size-content" style={(index % 2 === 0) ? { backgroundColor: "#fdb515" } : { backgroundColor: "#ec1852" }}>
                        <div className="block-with-text">
                          {products.ProductPreviewText
                            ? products.ProductPreviewText
                            : "Loading..."}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 lower-mid-size-content font-weight-bold">
                    {products.ProductName ? products.ProductName : "Loading..."}
                  </p>
                </a>
              </Col>
            );
          })}
          <Col
            className="col-12 col-sm-6 col-md-4 col-lg-4"
            style={{
              marginTop: "3rem",
              marginBottom: "4rem",
              borderRadius: "0.25rem",
            }}
          >
            <a
              className="flip-card-link"
              href={"/apply-now"}
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        "/uploads/image_31_8dcfb433a6.jpg"
                      }
                      alt="product"
                      style={{
                        width: "100%",
                        height: "100%",
                        minWidth: "10rem",
                        minHeight: "8rem",
                        borderRadius: "0.25rem",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="flip-card-back" style={{ backgroundColor: "#ec1852" }}>
                    <div className="block-with-text">
                      We offer home loans at attractive interest rates and are happy to help you with all your queries, click here to apply now and to know more about all the loans that we offer and more.
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-3 lower-mid-size-content font-weight-bold">
                Apply Now!
              </p>
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProductListComponent;

import Layout from "./Layout";
import * as FormService from "../services/services";
import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/ContactUS.css"
import { Formik } from 'formik';
import * as yup from 'yup';
import { Country, State, City } from 'country-state-city';

export function HomeLoanForm() {
    // const [contactUsInfo, setcontactUsInfo] = useState([]);
    const [validated, setValidated] = useState(false);
    const [StateData, setStateData] = useState(null);
    const [Cities, setCities] = useState(null);
    const [responseModal, setResponseModal] = useState(false)
    const [ResponseMessage, setResponseMessage] = useState("")

    const homeloanFormSchema = yup.object().shape({
        Name: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        MobileNumber: yup.string().matches(/^\d+$/, 'The field should have digits only').required().min(10).max(10),
        EmailId: yup.string().email().required(),
        AddressForCommunication: yup.string().required().trim(),
        City: yup.string().required(),
        State: yup.string().required(),
        Pincode: yup.string().matches(/^\d+$/, 'The field should have digits only').required().min(6).max(6),
        EmploymentBusinessProfessionDetails: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        CompanyName: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        CompanyAddress: yup.string().required().trim(),
        MonthlyGrossIncome: yup.string().matches(/^\d+$/, 'The field should have digits only').required().max(10),
        ExistingMonthlyLiability: yup.string().matches(/^\d+$/, 'The field should have digits only').required().max(10),
        TypeOfLoanRequired: yup.string().required(),
        LoanAmountRequired: yup.string().matches(/^\d+$/, 'The field should have digits only').required().max(10),
    });

    const submitHomeLoan = (event) => {
        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries())
        console.log(formDataObj)
        const form = event.currentTarget;
        console.log(event)
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            console.log(form)
            FormService.submitHomeLoanForm(formDataObj).then((resp) => {
                setResponseModal(true)
                setResponseMessage("Succesfully submitted, we will review and get back to you soon!")
                // alert("Succesfully submitted, we will review and get back to you soon!")
                // window.location.reload();
                // console.log(resp)
            })
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    const getCitybyState = (event) => {
        if (event.target.value) {
            console.log(event.target.value)
            setCities(City.getCitiesOfState("IN", event.target.value))
            console.log(City.getCitiesOfState("IN", event.target.value))
        }
        else setCities(null)
    }

    const checkFormValidity = (values, errors) => {
        if (values) {
            if (values.Name != "") {
                if ((Object.keys(errors).length == 0)) {
                    return false
                }
                else {
                    return true
                }
            }
            else {
                return true
            }
        }
        else {
            return true
        }
    }
    useEffect(() => {
        let mounted = true;
        console.log(State.getStatesOfCountry("IN"))
        setStateData(State.getStatesOfCountry("IN"))
        window.scrollTo(0, 0);
        FormService.getStatesByCountry().then((res) => {
            console.log(res)
        })
        return () => mounted = false;

    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Apply for home loan
                    </span>
                </p>
            </div>
            {/* <div className="headings BranchNetworkHeading mt-4">
                Apply for Home Loan
            </div> */}
            <Row className="mt-5">
                <Card className="m-auto w-100" style={{ border: ".25rem solid lightgrey" }}>
                    <div className="headings BranchNetworkHeading mt-4">
                        Apply for Home Loan
                    </div>
                    <Col>
                        <Formik
                            validationSchema={homeloanFormSchema}
                            onSubmit={submitHomeLoan}
                            initialValues={{
                                Name: "",
                                MobileNumber: "",
                                EmailId: "",
                                AddressForCommunication: "",
                                City: "",
                                State: "",
                                Pincode: "",
                                EmploymentBusinessProfessionDetails: "",
                                CompanyName: "",
                                CompanyAddress: "",
                                MonthlyGrossIncome: "",
                                ExistingMonthlyLiability: "",
                                TypeOfLoanRequired: "",
                                LoanAmountRequired: "",
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                handleReset,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const formData = new FormData(e.target),
                                        formDataObj = Object.fromEntries(formData.entries())
                                    submitHomeLoan(e)
                                    console.log(formDataObj)
                                }}>
                                    <Modal show={responseModal} centered>
                                        <Modal.Body data-aos="fade-right" data-aos-duration="250">
                                            <p className="m-0" style={{
                                                textAlign: "justify",
                                                fontWeight: "bold",
                                                padding: "1rem"
                                            }}>{ResponseMessage}</p>
                                            <button type="button" className="okButton" onClick={() => setResponseModal(false)} onMouseEnter={handleReset}>Ok</button>
                                        </Modal.Body>
                                    </Modal>
                                    <Form.Row style={{ marginLeft: "3rem", marginRight: "3rem" }}>
                                        <Form.Group as={Col} md="4" controlId="formGridName">
                                            <Form.Label className="w-100 text-left">Name *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Name"
                                                value={values.Name}
                                                onChange={handleChange}
                                                isValid={touched.Name && !errors.Name}
                                                isInvalid={!!errors.Name}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.Name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridMobileNumber">
                                            <Form.Label className="w-100 text-left">Mobile Number *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="MobileNumber"
                                                value={values.MobileNumber}
                                                onChange={handleChange}
                                                isValid={touched.MobileNumber && !errors.MobileNumber}
                                                isInvalid={!!errors.MobileNumber}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.MobileNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridEmailId">
                                            <Form.Label className="w-100 text-left">Email ID *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="EmailId"
                                                value={values.EmailId}
                                                onChange={handleChange}
                                                isValid={touched.EmailId && !errors.EmailId}
                                                isInvalid={!!errors.EmailId}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.EmailId}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridAddressForCommunication">
                                            <Form.Label className="w-100 text-left">Address For Communication *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="AddressForCommunication"
                                                value={values.AddressForCommunication}
                                                onChange={handleChange}
                                                isValid={touched.AddressForCommunication && !errors.AddressForCommunication}
                                                isInvalid={!!errors.AddressForCommunication}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.AddressForCommunication}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formState">
                                            <Form.Label style={{ textAlign: "left", width: "100%" }}>State *</Form.Label>
                                            <Form.Control required as="select" name="State" value={values.State}
                                                onChange={handleChange}
                                                onClick={getCitybyState.bind(this)}
                                                isValid={touched.State && !errors.State}
                                                isInvalid={!!errors.State}
                                            >
                                                <option value="">Select State</option>
                                                {StateData ? StateData.map((state, index) => { return (<option key={index} value={state.isoCode}>{state.name}</option>) }) : null}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.State}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formCity">
                                            <Form.Label style={{ textAlign: "left", width: "100%" }}>City *</Form.Label>
                                            <Form.Control required as="select" name="City" value={values.City}
                                                onChange={handleChange}
                                                isValid={touched.City && !errors.City}
                                                isInvalid={!!errors.City}
                                            >
                                                <option value="">Select City</option>
                                                {Cities ? Cities.map((city, index) => { return (<option key={index} value={city.isoCode}>{city.name}</option>) }) : null}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.City}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridPincode">
                                            <Form.Label className="w-100 text-left">Pin Code *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Pincode"
                                                value={values.Pincode}
                                                onChange={handleChange}
                                                isValid={touched.Pincode && !errors.Pincode}
                                                isInvalid={!!errors.Pincode}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.Pincode}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridEmploymentBusinessProfessionDetails">
                                            <Form.Label className="w-100 text-left">Employment / Business / Profession Details *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="EmploymentBusinessProfessionDetails"
                                                value={values.EmploymentBusinessProfessionDetails}
                                                onChange={handleChange}
                                                isValid={touched.EmploymentBusinessProfessionDetails && !errors.EmploymentBusinessProfessionDetails}
                                                isInvalid={!!errors.EmploymentBusinessProfessionDetails}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.EmploymentBusinessProfessionDetails}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridCompanyName">
                                            <Form.Label className="w-100 text-left">Company Name *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="CompanyName"
                                                value={values.CompanyName}
                                                onChange={handleChange}
                                                isValid={touched.CompanyName && !errors.CompanyName}
                                                isInvalid={!!errors.CompanyName}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.CompanyName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridCompanyAddress">
                                            <Form.Label className="w-100 text-left">Company Address *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="CompanyAddress"
                                                value={values.CompanyAddress}
                                                onChange={handleChange}
                                                isValid={touched.CompanyAddress && !errors.CompanyAddress}
                                                isInvalid={!!errors.CompanyAddress}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.CompanyAddress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridMonthlyGrossIncome">
                                            <Form.Label className="w-100 text-left">Monthly Gross Income *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="MonthlyGrossIncome"
                                                value={values.MonthlyGrossIncome}
                                                onChange={handleChange}
                                                isValid={touched.MonthlyGrossIncome && !errors.MonthlyGrossIncome}
                                                isInvalid={!!errors.MonthlyGrossIncome}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.MonthlyGrossIncome}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridExistingMonthlyLiability">
                                            <Form.Label className="w-100 text-left">Existing Monthly Liability *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="ExistingMonthlyLiability"
                                                value={values.ExistingMonthlyLiability}
                                                onChange={handleChange}
                                                isValid={touched.ExistingMonthlyLiability && !errors.ExistingMonthlyLiability}
                                                isInvalid={!!errors.ExistingMonthlyLiability}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.ExistingMonthlyLiability}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="4" controlId="formGridTypeofLoanRequired">
                                            <Form.Label className="w-100 text-left">Existing Liability *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="TypeofLoanRequired"
                                                value={values.TypeofLoanRequired}
                                                onChange={handleChange}
                                                isValid={touched.TypeofLoanRequired && !errors.TypeofLoanRequired}
                                                isInvalid={!!errors.TypeofLoanRequired}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.TypeofLoanRequired}
                                            </Form.Control.Feedback>
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="4" controlId="formTypeOfLoanRequired">
                                            <Form.Label style={{ textAlign: "left", width: "100%" }}>Type of Loan Required *</Form.Label>
                                            <Form.Control required as="select" name="TypeOfLoanRequired" value={values.TypeOfLoanRequired}
                                                onChange={handleChange}
                                                isValid={touched.TypeOfLoanRequired && !errors.TypeOfLoanRequired}
                                                isInvalid={!!errors.TypeOfLoanRequired}
                                            >
                                                <option value="">Select Type of Loan Required</option>
                                                <option value="Construction of House / Flat">Construction of House / Flat</option>
                                                <option value="Purchase of House / Flat">Purchase of House / Flat</option>
                                                <option value="Repairs and Renovation">Repairs and Renovation</option>
                                                <option value="Plot Loans">Plot Loans</option>
                                                <option value="Take over">Take over</option>
                                                <option value="Construction - Commercial">Construction - Commercial</option>
                                                <option value="Purchase - Commercial">Purchase - Commercial</option>
                                                <option value="Plot Loans - Commercial">Plot Loans - Commercial</option>
                                                <option value="Take over - Commercial">Take over - Commercial</option>
                                                <option value="Prosperity">Prosperity</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.TypeOfLoanRequired}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formGridLoanAmountRequired">
                                            <Form.Label className="w-100 text-left">Loan Amount Required *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="LoanAmountRequired"
                                                value={values.LoanAmountRequired}
                                                onChange={handleChange}
                                                isValid={touched.LoanAmountRequired && !errors.LoanAmountRequired}
                                                isInvalid={!!errors.LoanAmountRequired}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-left">
                                                {errors.LoanAmountRequired}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {/* <button
                                        onClick={handleReset}
                                        type="button"
                                        style={{ backgroundColor: "#FFA500", border: "none" }}
                                    >
                                        Reset
                                    </button> */}
                                    <Button type="submit" className="my-3" style={{ backgroundColor: "#FFA500", border: "none" }} disabled={checkFormValidity(values, errors)}>Apply For Home Loan</Button>
                                </Form>)}
                        </Formik>
                    </Col>
                </Card>
            </Row>
        </div>} />
    </div >)
}
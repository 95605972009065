import axios from "axios";

export const BaseURL = process.env.REACT_APP_BASE_URL;

export const getNavbarOptions = (payload) => {
  const URL = BaseURL + `/navbar-options`;
  return axios(URL, {
    method: "GET",
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    // },
    // data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getStats = (payload) => {
  const URL = BaseURL + `/stat-counters`;
  return axios(URL, {
    method: "GET",
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    // },
    // data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSATCenter = (payload) => {
  const URL = BaseURL + `/sat-centers`;
  return axios(URL, {
    method: "GET",
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    // },
    // data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getBranchDetails = (payload) => {
  const URL = BaseURL + `/branch-locator-states?_sort=State:ASC`;
  return axios(URL, {
    method: "GET",
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getRepcoDetails = (payload) => {
  const URL = BaseURL + "/repco-bank-details";
  return axios(URL, { method: "GET" })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getBranchCardDetails = (payload) => {
  const URL = BaseURL + '/branch-card-home-page-details';
  return axios(URL, { method: "GET" })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export const getGrievances = (payload) => {
  const URL = BaseURL + '/grievance-redressals';
  return axios(URL, { method: "GET" })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}


export const getProductList = (payload) => {
  const URL = BaseURL + "/product-lists";
  return axios(URL, { method: "GET" })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getFinancialInformation = (payload) => {
  const URL = BaseURL + "/financial-information-financial-highlights";
  return axios(URL, { method: "GET" })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getQuarterlyResults = () => {
  const URL = BaseURL + "/financial-information-quarterly-results?_sort=FinancialYear:DESC";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getAnnualReports = () => {
  const URL = BaseURL + "/financial-information-annual-report";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getCreditRatings = () => {
  const URL = BaseURL + "/financial-information-credit-ratings";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getAnnouncements = () => {
  const URL = BaseURL + "/announcements-pages";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getSharepriceDetails = () => {
  const URL = BaseURL + "/share-holding-pattern-documents";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getFairPracticePolicyService = () => {
  const URL = BaseURL + "/fair-practice-codes";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getDownloadInformation = () => {
  const URL = BaseURL + "/downloads";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getGalleryAlbums = () => {
  const URL = BaseURL + "/galleries?_sort=AlbumName:ASC";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getCareerInformation = () => {
  const URL = BaseURL + "/careers";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getNewsUpdates = () => {
  const URL = BaseURL + "/news-updates";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getFAQs = (type) => {
  let URL = BaseURL;

  switch (type) {
    case "queries":
      URL += "/faq-queries-complaints";
      return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
        throw error;
      })

    case "taxdocuments":
      URL += "/faq-home-loan-guide-tax-benefits";
      return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
        throw error;
      })
    case "loanProcessRHFL":
      URL += "/faq-home-loan-guide-home-loan-process-at-rhfls";
      return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
        throw error;
      })
    case "supportingDocuments":
      URL += "/faq-home-loan-guide-supporting-documents";
      return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
        throw error;
      })
    case "genInfo":
      URL += "/faq-general-informations";
      return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
        throw error;
      })
    default:
      break;
  }
}

export const getAuctionSaleNotices = () => {
  const URL = BaseURL + "/auction-sale-notices";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getAnalystCoverage = () => {
  const URL = BaseURL + "/analyst-coverages?_sort=Year:DESC";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getInvestorCalendarInfo = () => {
  const URL = BaseURL + "/investor-calendars";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getInvestorFAQs = () => {
  const URL = BaseURL + "/investor-faqs";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getInvestorContact = () => {
  const URL = BaseURL + "/investor-contacts";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getUnclaimedDividend = () => {
  const URL = BaseURL + "/unclaimed-dividends";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getTDSonDividend = () => {
  const URL = BaseURL + "/tds-on-dividends";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getIntimations = () => {
  const URL = BaseURL + "/intimation-to-stock-exchanges?_sort=IntimationTitle:Desc";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getCorporateGovernance = () => {
  const URL = BaseURL + "/corporate-governances";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getBidInformation = () => {
  const URL = BaseURL + "/bids-digitizations";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getCurrentOpeningsByBranch = () => {
  const URL = BaseURL + "/job-listings";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}


export const getStampDutyDetails = () => {
  const URL = BaseURL + "/stamp-duty-calculators";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getGuidelineDetails = () => {
  const URL = BaseURL + "/guideline-values";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const getAreaConversionUnits = () => {
  const URL = BaseURL + "/area-conversion-calculators";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

// EXTERNAL CALLS
export const getStatesByCountry = () => {
  const URL = "https://api.countrystatecity.in/v1/countries/IN/states";
  return axios(URL, { method: "GET" }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

// POST METHODS - FORM SUBMISSIONS
export const submitHomeLoanForm = (form) => {
  const URL = BaseURL + "/home-loan-forms";
  return axios({
    method: 'post',
    url: URL,
    data: form
  }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const newSubmission = (form) => {
  const URL = BaseURL + "/career-forms";
  return axios({
    method: 'post',
    url: URL,
    data: form
  }).then((response) => response.data).catch((error) => {
    throw error;
  })
}

export const enquiryFormSubmission = (form) => {
  const URL = BaseURL + "/enquiry-forms";
  return axios({
    method: 'post',
    url: URL,
    data: form
  }).then((response) => response.data).catch((error) => {
    throw error;
  })
}
import Layout from "./Layout";
import * as AboutService from "../services/services";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/AboutUS.css"

export function AboutUS() {
    const [aboutUsInformation, setAboutUsInformation] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        AboutService.getRepcoDetails().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                // let responseFormat = [];
                // responseFormat.push(resp);
                setAboutUsInformation(resp);
            }
        })
        return () => mounted = false;

    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{marginTop: "10rem"}}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                        About Us
                    </span>
                </p>
            </div>
            <div
                className="headings BranchNetworkHeading mt-4"
            >About US</div>
            {/* {aboutUsInformation.length > 0 ? <Row className="mb-5">
                <Col className="col-12 col-sm-12 col-md-6 col-lg-6 p-0">
                    <div className="aboutImageContainer">
                        <img style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "0.25rem" }} className="aboutImage" src={aboutUsInformation.length > 0 ? process.env.REACT_APP_BASE_URL + aboutUsInformation[0].AboutUSImages[0].url : ""} />
                        <div className="overlay" style={{ color: "black" }}>Inaugration</div>
                    </div>
                </Col>
                <Col className="col-12 col-sm-12 col-md-6 col-lg-6 p-0">
                    <div className="aboutImageContainer">
                        <img style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "0.25rem" }} src={aboutUsInformation.length > 0 ? process.env.REACT_APP_BASE_URL + aboutUsInformation[0].AboutUSImages[1].url : ""} />
                        <div className="overlay" style={{ color: "black" }}>Officials</div>
                    </div>
                </Col>
            </Row> : ""} */}
            <Row>
                <Col>
                    <div className="text-left lower-mid-size-content" style={{
                        whiteSpace: "pre-wrap"
                    }}>{aboutUsInformation.length > 0 ? <div>{aboutUsInformation[0].AboutUS}</div> : "Loading..."}</div></Col>
                {/* <img src={aboutUsInformation[0].AboutUSImages[1].url} /> */}
                {/* <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <img style={{ width: "100%" }} src={aboutUsInformation.length > 0 ? process.env.REACT_APP_BASE_URL + aboutUsInformation[0].AboutUSImages[0].url : ""} />
                    <img style={{ width: "100%" }} src={aboutUsInformation.length > 0 ? process.env.REACT_APP_BASE_URL + aboutUsInformation[0].AboutUSImages[1].url : ""} />
                </Col> */}
            </Row></div>} />
    </div>)
}
import React, { Component } from "react";
import { yearlyAmortizationSchedule, amortizationSchedule } from 'amortization';
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import "../styles/HomeLoanCalcComponent.css";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Doughnut, Pie, Polar, HorizontalBar } from "react-chartjs-2";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);

class HomeLoanCalcComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMonthModal: false, totalAmount: 1500000, totalAmountMin: 100000, totalAmountmax: 10000000, invalidLoanAmount: false, percentage: 8.5, percentageMin: 5, percentagemax: 15, invalidPercentage: false, tenure: 15, tenureMin: 1, tenuremax: 25, invalidTenure: false, totalAmountFormatted: "15,00,000", amortizationDetails: [], moreDetails: false, detailsText: "Get more details!", monthlyAmortizationDetails: [], currentYearsMonthlyAmortizationDetails: [], currentYear: 2021, monthlyEmi: 0, monthNumber: parseInt(moment().format("M"))
    };
    if (window.location.pathname === "/emi-calculator") {
      this.state.moreDetails = true;
    }
    this.state.amortizationDetails = yearlyAmortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(this.state.percentage));
    this.state.monthlyAmortizationDetails = amortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(this.state.percentage));
    this.state.monthlyEmi = this.state.monthlyAmortizationDetails[0].payment;
    console.log(this.state.monthlyAmortizationDetails)
  }

  convertToFormattedValue = (value) => {
    var totalFormatted = value.toString();
    var lastThree = totalFormatted.substring(totalFormatted.length - 3);
    var otherNumbers = totalFormatted.substring(0, totalFormatted.length - 3);
    if (otherNumbers !== '')
      lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return (res)
  }

  onLoanAmountChange = value => {
    this.setState({ totalAmountFormatted: this.convertToFormattedValue(value) })
    this.setState({
      totalAmount: value,
    });
    this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(value), parseInt(this.state.tenure + 1), parseInt(this.state.percentage)) })
    this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(value), parseInt(this.state.tenure + 1), parseInt(this.state.percentage)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
    this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
  };

  getLoanTotalAmount = (e) => {
    const total = (e.target.validity.valid) ?
      e.target.value : this.state.totalAmount
    if ((total) > this.state.totalAmountMin) {
      if ((total) < (this.state.totalAmountmax + 1)) {
        this.setState({ totalAmount: (total) })
        this.setState({ invalidLoanAmount: false })
        this.setState({ totalAmountFormatted: this.convertToFormattedValue(total) })
        this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(total), parseInt(this.state.tenure + 1), parseInt(this.state.percentage)) })
        this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(total), parseInt(this.state.tenure + 1), parseInt(this.state.percentage)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
        this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
      }
      else {
        // alert("Enter Total amount less than 5 Crores!");
        this.setState({ invalidLoanAmount: true })
        // this.setState({ totalAmount: (this.state.totalAmountmax) })
        this.setState({ totalAmount: (total) })
        this.setState({ totalAmountFormatted: this.convertToFormattedValue(total) })
      }
    }

    else {
      if ((total) < (this.state.totalAmountMin)) {
        // alert("Enter Total amount greater than 1 Lakh!");
        this.setState({ invalidLoanAmount: true })
        // this.setState({ totalAmount: (this.state.totalAmountMin) })
        this.setState({ totalAmount: (total) })
        this.setState({ totalAmountFormatted: this.convertToFormattedValue(total) })
      }
      else {
        this.setState({ invalidLoanAmount: false })
        this.setState({ totalAmount: (total) })
        this.setState({ totalAmountFormatted: this.convertToFormattedValue(total) })
        this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(total), parseInt(this.state.tenure + 1), parseInt(this.state.percentage)) })
        this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(total), parseInt(this.state.tenure + 1), parseInt(this.state.percentage)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
        this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
      }
    }
  }

  onPercentageChange = value => {
    this.setState({
      percentage: value,
    });
    this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(value)) })
    this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(value)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
    this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
  }

  getPercentageValue = (e) => {
    const total = (e.target.validity.valid) ?
      e.target.value : this.state.percentage

    if (total > this.state.percentagemax) {

      if (total > this.state.percentagemax - 1) {
        this.setState({ invalidPercentage: true })
        this.setState({ percentage: total })
      }
      else {
        this.setState({ invalidPercentage: false })
        this.setState({ percentage: total })
        this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(total)) })
        this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(total)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
        this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
      }
    }
    else {

      if (total < this.state.percentageMin) {
        this.setState({ invalidPercentage: true })
        this.setState({ percentage: total })
      }
      else {
        this.setState({ invalidPercentage: false })
        this.setState({ percentage: total })
        this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(total)) })
        this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(this.state.totalAmount), parseInt(this.state.tenure + 1), parseInt(total)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
        this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
      }
    }
  }

  onTenureChange = value => {
    this.setState({
      tenure: value,
    });
    this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(this.state.totalAmount), parseInt(value + 1), parseInt(this.state.percentage)) })
    this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(this.state.totalAmount), parseInt(value + 1), parseInt(this.state.percentage)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
    this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
  }

  getTenureValue = (e) => {
    const total = (e.target.validity.valid) ?
      e.target.value : this.state.tenure
    if (total > this.state.tenuremax) {
      if (total > this.state.tenuremax - 1) {
        this.setState({ tenure: total })
        this.setState({ invalidTenure: true })
      }
      else {
        this.setState({ invalidTenure: false })
        this.setState({ tenure: total })
        this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(this.state.totalAmount), parseInt(total), parseInt(this.state.percentage)) })
        this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(this.state.totalAmount), parseInt(total), parseInt(this.state.percentage)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
        this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
      }
    }

    else {
      if (total < this.state.tenureMin) {
        this.setState({ tenure: total })
        this.setState({ invalidTenure: true })
      }
      else {
        this.setState({ invalidTenure: false })
        this.setState({ tenure: total })
        this.setState({ amortizationDetails: yearlyAmortizationSchedule(parseInt(this.state.totalAmount), parseInt(total), parseInt(this.state.percentage)) })
        this.setState({ monthlyAmortizationDetails: amortizationSchedule(parseInt(this.state.totalAmount), parseInt(total), parseInt(this.state.percentage)) }, () => { console.log(this.state.monthlyAmortizationDetails) })
        this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) })
      }
    }
  }

  getTotalLoanPaymentCost = (e) => {
    Array.prototype.sum = function (prop) {
      var total = 0
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop]
      }
      return total
    }
    return this.state.amortizationDetails.sum('paymentRounded').toFixed(0)
  }

  getTotalInterestRate = (e) => {
    Array.prototype.sum = function (prop) {
      var total = 0
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop]
      }
      return total
    }
    return this.state.amortizationDetails.sum('interestPaymentRounded').toFixed(0)
  }

  getMonthlyEMI = () => {
    // this.setState({ monthlyEmi: this.state.monthlyAmortizationDetails[0].payment }, () => { console.log(this.state.monthlyEmi) }
    return this.state.monthlyAmortizationDetails[0].payment
  }



  render() {

    const handleClose = () => this.setState({ showMonthModal: false });
    const handleShow = () => this.setState({ showMonthModal: true });

    const setMonthAmortization = (index) => {
      console.log(index)
      let monthAmortization = [];
      this.setState({ currentYear: new Date().getFullYear() + index }, console.log(this.state.currentYear))
      this.setState({ currentYearsMonthlyAmortizationDetails: monthAmortization }, () => { console.log(this.state.currentYearsMonthlyAmortizationDetails) })
      for (let i = index * 12; i < (index + 1) * 12; i++) {
        monthAmortization.push(this.state.monthlyAmortizationDetails[i])
        console.log(this.state.monthlyAmortizationDetails[index], index)
      }
      this.setState({ currentYearsMonthlyAmortizationDetails: monthAmortization }, () => { console.log(this.state.currentYearsMonthlyAmortizationDetails) })
      this.setState({ showMonthModal: true }, () => { console.log(this.state.showMonthModal) })
    }
    const toggleCalculatorDetails = (e) => {
      if (window.location.pathname === "/emi-calculator") {
        this.setState({ moreDetails: !this.state.moreDetails });
      }
      else {
        // this.props.history.push("/emi-calculator");
        window.location.href = "/emi-calculator"
      }
    }
    const convertToFormattedValue = (value) => {
      var totalFormatted = value.toString();
      var lastThree = totalFormatted.substring(totalFormatted.length - 3);
      var otherNumbers = totalFormatted.substring(0, totalFormatted.length - 3);
      if (otherNumbers !== '')
        lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return (res)
    }
    const options = {
      responsive: true,
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: 'black'
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return "₹" + convertToFormattedValue(data.datasets[0].data[tooltipItem.index])
          },
        },
      },
      // scales: {
      //   yAxes: [{
      //     ticks: {
      //       fontColor: "white"
      //     }
      //   }],
      //   xAxes: [{
      //     ticks: {
      //       fontColor: "white"
      //     }
      //   }]
      // },
      elements: {
        arc: {
          borderWidth: 1,
        }
      },
    };
    const highChartOptions = {
      colors: ["lightseagreen", "#FFF515", "#FF5852"], // Brown Yellow
      // colors: ["#F7B7A3", "#2B0B3F", "#EA5F89"], // Brown Yellow
      colors: ["#EC6B5680", "#fdb515", "#ec1852"], // Brown Yellow
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 25,
          beta: 0
        },
        events: {
          load: function () {
            var renderer = this.renderer

            this.series[0].points.forEach((point, i) => {
              var marker = renderer.createElement('marker').add(renderer.defs).attr({
                markerWidth: 10,
                markerHeight: 10,
                refX: 5,
                refY: 5,
                orient: 'auto',
                id: `connector-marker-${i}`
              })

              renderer.circle(5, 5, 5).add(marker).attr({
                fill: point.color
              })

              point.connector.attr({
                'marker-start': `url(#connector-marker-${i})`
              })
            })
          }
        }
      },
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      },
      credits: {
        enabled: false
      },
      title: {
        text: null,
        // style: {
        //   fontFamily: '"Montserrat", sans-serif'
        // }
      },
      tooltip: {
        crosshairs: false,
        shared: true,
        valuePrefix: '₹',
        borderColor: '#651FFF',
        formatter: function () {
          return '<b>' + (this.point ? this.point.name : "") + '</b>: ' + convertToFormattedValue(this.point.y) + ' ₹';
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 60,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: { fontFamily: "'Montserrat', sans-serif", lineHeight: '18px', fontSize: '1rem' }
          }
        },
      },
      legend: {
        floating: true,
      },
      series: [{
        showInLegend: false,
        type: "pie",
        dataLabels: {
          enabled: true,
          connectorShape: 'crookedLine',
          crookDistance: '95%',
          // alignTo: 'plotEdges'
        },
        data: [["Principal Amount", parseInt(this.state.totalAmount)], ["Interest Rate", parseInt(this.getTotalInterestRate())], ["Total Amount", parseInt(this.getTotalLoanPaymentCost())]],
      }]
    }
    // const data = {
    //   maintainAspectRatio: false,
    //   responsive: false,
    //   labels: ["Principal Amount", "Interest Amount", "Total Amount"],
    //   datasets: [
    //     {
    //       data: [this.state.totalAmount,
    //       (((
    //         (this.state.totalAmount * (this.state.percentage / 1200)) /
    //         (1 -
    //           Math.pow(
    //             1 / (1 + this.state.percentage / 1200),
    //             this.state.tenure * 12
    //           ))
    //       ).toFixed(0) * (this.state.tenure * 12)) - this.state.totalAmount),

    //       ((
    //         (this.state.totalAmount * (this.state.percentage / 1200)) /
    //         (1 -
    //           Math.pow(
    //             1 / (1 + this.state.percentage / 1200),
    //             this.state.tenure * 12
    //           ))
    //       ).toFixed(0) * (this.state.tenure * 12))],
    //       backgroundColor: ["#097596", "#02AEE3", "#E3D630"],
    //       hoverBackgroundColor: ["#9C27B080", "#2E3B8380", "#F4433680"],
    //     }
    //   ]
    // };
    function Example() {




      return (
        <>

        </>
      );
    }

    return (
      <div data- aos="fade-up" >
        <div className="Base-wrapper CalculatorModule">
          <p className="headings mb-1 mb-sm-1 mb-md-3 mb-lg-0" style={{ color: "rgb(227, 26, 82)" }}>Calculate Home Loan EMI</p>
          {/* <Row className="p-0"> */}
          {!(this.state.invalidLoanAmount || this.state.invalidPercentage || this.state.invalidTenure) ?
            <Row className="p-0 my-5">

              <Col className="col-12 col-sm-12 col-md-12 col-lg-9 EmiValue text-left p-0">
                {/* <Table>
                  <tbody>
                    <tr>
                      <td>
                        <p>Monthly EMI Payment is {(
                          (this.state.totalAmount * (this.state.percentage / 1200)) /
                          (1 -
                            Math.pow(
                              1 / (1 + this.state.percentage / 1200),
                              this.state.tenure * 12
                            ))
                        ).toFixed(0)}</p>
                        <p>
                          Total Interest Payabale is {this.state.amortizationDetails.length > 0 ? (this.getTotalInterestRate()) : ""}
                        </p>
                        <p>
                          Total Amount Payabale is {this.state.amortizationDetails.length > 0 ? (this.getTotalLoanPaymentCost()) : ""}
                        </p>
                      </td>
                      <td colSpan="2"> <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartOptions}
                      /></td>
                    </tr>
                    <tr>
                      <td>Total Interest Payabale is</td>
                      <td colSpan="2">{this.state.amortizationDetails.length > 0 ? (this.getTotalInterestRate()) : ""}</td>
                    </tr>
                    <tr>
                      <td>Total Amount Payabale is</td>
                      <td colSpan="2">{this.state.amortizationDetails.length > 0 ? (this.getTotalLoanPaymentCost()) : ""}</td>
                    </tr>
                  </tbody>
                </Table> */}
                {/* <p className="EmiResult m-0 mid-size-content"> Monthly EMI is :
                  <span className="px-2 rupee-symbol"><FontAwesomeIcon
                    icon={faRupeeSign}
                  /></span>
                  {(
                    (this.state.totalAmount * (this.state.percentage / 1200)) /
                    (1 -
                      Math.pow(
                        1 / (1 + this.state.percentage / 1200),
                        this.state.tenure * 12
                      ))
                  ).toFixed(0)}

                </p> */}
                {/* <div className="doughnut-others mb-5"> */}
                {/* <Doughnut data={data} options={options} weight={1} /> */}

                {/* </div> */}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={highChartOptions}
                />
                {/* <span className="m-0 mid-size-content d-none">
                  The EMI for the amount of <span className="emiSelectedValues"><p className="px-2 m-0"><FontAwesomeIcon
                    icon={faRupeeSign}
                  /><span className="px-2">{this.state.totalAmountFormatted}</span><span style={{ color: "black", fontWeight: "400" }}> for a
                period of </span></p></span><span className="emiSelectedValues">{this.state.tenure} year(s)</span> at an interest rate of{" "}
                  <span className="emiSelectedValues">{this.state.percentage}%</span> is{" "}
                </span> */}
                {/* <p className="EmiResult m-0 mid-size-content">Equated Monthly Amount for the below values is -
                  <span className="px-2 rupee-symbol"><FontAwesomeIcon
                    icon={faRupeeSign}
                  /></span>
                  {(
                    (this.state.totalAmount * (this.state.percentage / 1200)) /
                    (1 -
                      Math.pow(
                        1 / (1 + this.state.percentage / 1200),
                        this.state.tenure * 12
                      ))
                  ).toFixed(0)}
                </p> */}
              </Col>
              <Col className="col-12 col-sm-12 col-md-12 col-lg-3 p-0 lower-mid-size-content m-auto text-lg-right font-weight-bold">
                <p className="py-5 mt-3" style={{
                  // textAlign: "center",
                  width: "80% !important",
                  margin: "auto"
                }}>Monthly Payment (EMI) is&nbsp;
                  ₹{convertToFormattedValue(this.getMonthlyEMI().toFixed(0))}</p>
                <p className="pb-5" style={{
                  // textAlign: "center",
                  width: "80% !important",
                  margin: "auto"
                }}>
                  Total Interest Payable is&nbsp;₹{this.state.amortizationDetails.length > 0 ? (convertToFormattedValue(this.getTotalInterestRate())) : ""}
                </p>
                <p className="pb-0 pb-sm-0 pb-md-5 pb-lg-5" style={{
                  // textAlign: "center",
                  width: "80% !important",
                  margin: "auto"
                }}>
                  Total Amount Payable is&nbsp;₹{this.state.amortizationDetails.length > 0 ? (convertToFormattedValue(this.getTotalLoanPaymentCost())) : ""}
                </p>
              </Col>
            </Row>
            :
            <Row>
              <Col className="col-12 col-sm-12 col-md-12 col-lg-12 EmiValue text-left p-0">
                <span className="m-0">
                  Enter values within the limit!
                  {this.state.invalidLoanAmount ? <p>Principal amount should be between {this.state.totalAmountMin} and {this.state.totalAmountmax}</p> : ""}
                  {this.state.invalidTenure ? <p>Tenure should be between {this.state.tenureMin} and {this.state.tenuremax}</p> : ""}
                  {this.state.invalidPercentage ? <p>Percentage should be between {this.state.percentageMin} and {this.state.percentagemax}</p> : ""}
                </span>
              </Col></Row>}
          {/* </Row> */}
          <Row>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 EmiCalculatorSliders">
              <div className="mb-3">
                <p className="CalculatorHeadings lower-mid-size-content">Principal Amount</p>
                <Row>
                  <Col className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <Slider
                      min={this.state.totalAmountMin}
                      max={this.state.totalAmountmax}
                      value={this.state.totalAmount}
                      step={100000}
                      marks={{
                        100000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>1 L</strong>
                        },

                        1000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>10 L</strong>
                        },

                        3000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>30 L</strong>
                        },

                        5000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>50 L</strong>
                        },

                        7000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>70 L</strong>
                        },

                        9000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>90 L</strong>
                        },

                        10000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem",
                            width: "fit-content"
                          },
                          label: <strong>1 Cr</strong>,
                        },
                      }}
                      onChange={this.onLoanAmountChange}
                      railStyle={{ backgroundColor: 'rgb(227, 26, 82)', height: 10 }}
                      trackStyle={{ backgroundColor: '#fdb515', height: 10 }}
                      handleStyle={{
                        borderColor: 'black',
                        borderRadius: '0.25rem',
                        height: 28,
                        width: 28,
                        marginTop: -9,
                        backgroundColor: 'rgb(253, 181, 21)',
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-2 col-lg-2 pr-0 mt-3 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mb-0 CalculatorValueCol">
                    <input className="LoanAmount small-size-content" type="tel" pattern="[0-9]*"
                      onInput={this.getLoanTotalAmount.bind(this)} value={this.state.totalAmount} /> <p className="CalculatorNormalLabelLeft" style={{ float: "right" }}><FontAwesomeIcon
                        icon={faRupeeSign}
                      /></p>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {this.state.invalidLoanAmount ? <span className="mt-1" style={{ float: "right", color: 'red' }}>Enter Principal amount between 1 Lakh and 1 Crore</span> : ""}
                </Row>
              </div>
              <div className="mb-3">
                <p className="CalculatorHeadings lower-mid-size-content">Tenure</p>
                <Row>
                  <Col className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <Slider
                      min={this.state.tenureMin}
                      max={this.state.tenuremax}
                      step={1}
                      value={this.state.tenure}
                      marks={{

                        1: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            width: "fit-content",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>1 Yr</strong>,
                        },
                        5: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>5 Yrs</strong>
                        },
                        10: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>10 Yrs</strong>,
                        },
                        15: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>15 Yrs</strong>,
                        },
                        20: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>20 Yrs</strong>,
                        },
                        25: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem",
                            width: "fit-content"
                          },
                          label: <strong>25 Yrs</strong>,
                        },
                      }}
                      onChange={this.onTenureChange}
                      railStyle={{ backgroundColor: 'rgb(227, 26, 82)', height: 10 }}
                      trackStyle={{ backgroundColor: '#fdb515', height: 10 }}
                      handleStyle={{
                        borderColor: 'black',
                        borderRadius: '0.25rem',
                        height: 28,
                        width: 28,
                        marginTop: -9,
                        backgroundColor: 'rgb(253, 181, 21)',
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-2 col-lg-2 pr-0 mt-3 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mb-0 CalculatorValueCol">
                    <p className="CalculatorNormalLabels mr-3">Years</p><input className="LoanTenure" type="tel" pattern="[0-9]*"
                      onInput={this.getTenureValue.bind(this)} value={this.state.tenure} />
                  </Col></Row>
                <Row className="mb-4">
                  {this.state.invalidTenure ? <span style={{ float: "right", color: "red" }}>Enter Tenure between 1 and 25 Years</span> : ""}
                </Row>
              </div>
              <div className="mb-3">
                <p className="CalculatorHeadings lower-mid-size-content">Interest Rate</p>
                <Row>
                  <Col className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <Slider
                      min={this.state.percentageMin}
                      max={this.state.percentagemax}
                      step={0.5}
                      marks={{
                        5: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>5%</strong>
                        },
                        8.5: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>8.5%</strong>,
                        },
                        15: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>15%</strong>,
                        },
                      }}
                      value={this.state.percentage}
                      onChange={this.onPercentageChange}
                      railStyle={{ backgroundColor: 'rgb(227, 26, 82)', height: 10 }}
                      trackStyle={{ backgroundColor: '#fdb515', height: 10 }}
                      handleStyle={{
                        borderColor: 'black',
                        borderRadius: '0.25rem',
                        height: 28,
                        width: 28,
                        marginTop: -9,
                        backgroundColor: 'rgb(253, 181, 21)',
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-2 col-lg-2 pr-0 mt-3 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mb-0 CalculatorValueCol">
                    <p className="mr-3 CalculatorNormalLabelsRight">%</p><input className="LoanPercentage" type="number" step="0.5" pattern="[0-9]*"
                      onInput={this.getPercentageValue.bind(this)} value={this.state.percentage} />
                  </Col>
                </Row>
                <Row className="mb-4">
                  {this.state.invalidPercentage ? <span style={{ float: "right", color: "red" }}>Enter Percentage between 5 and 15%</span> : ""}
                </Row>
              </div>
              {!(this.state.invalidLoanAmount || this.state.invalidPercentage || this.state.invalidTenure) ? <p className="EmiResult m-0 mid-size-content mt-5">Your EMI is
                <span className="px-2 rupee-symbol"><FontAwesomeIcon
                  icon={faRupeeSign}
                /></span>
                {convertToFormattedValue(this.getMonthlyEMI().toFixed(0))}
              </p> : ""}
            </Col>

          </Row>
          {this.state.moreDetails ? null : <p className="lower-mid-size-content mt-5" style={{ cursor: "pointer" }} onClick={toggleCalculatorDetails}>{this.state.detailsText}  &#10095;</p>}
          {this.state.moreDetails ? <div data-aos="fade-up" className="mt-5">
            <p className="mt-5 text-right" data-aos="fade-up">* Click on any year below to get monthly amortization details</p>
            <Table responsive bordered>
              <thead className="text-left">
                <tr>
                  <th style={{ color: "#E31A52" }}>No</th>
                  <th style={{ color: "#E31A52" }}>Principal Payment</th>
                  <th style={{ color: "#E31A52" }}>Interest Payment</th>
                  <th style={{ color: "#E31A52" }}>Amount to be paid every Year</th>
                  <th style={{ color: "#E31A52" }}>Year</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {this.state.amortizationDetails.length > 0 ? this.state.amortizationDetails.map((amortization, index) => {
                  return (
                    <>
                      {
                        index == 0 ?
                          <tr key={amortization.paymentNumber} style={{ cursor: "pointer" }} onClick={() => { setMonthAmortization(index) }}>
                            <td>{amortization.paymentNumber}</td>
                            <td>{convertToFormattedValue((amortization.principalPaymentRounded.toFixed(0) - (amortization.principalPaymentRounded.toFixed(0) / 12) * (parseInt(moment().format("M")) - 1)).toFixed(0))}</td>
                            <td>{convertToFormattedValue((amortization.interestPaymentRounded.toFixed(0) - (amortization.interestPaymentRounded.toFixed(0) / 12) * (parseInt(moment().format("M")) - 1)).toFixed(0))}</td>
                            <td>{convertToFormattedValue((amortization.paymentRounded.toFixed(0) - (amortization.paymentRounded.toFixed(0) / 12) * (parseInt(moment().format("M")) - 1)).toFixed(0))}</td>
                            <td>{new Date().getFullYear() + index}</td>
                          </tr>
                          :
                          index == this.state.amortizationDetails.length - 1 ?
                            <tr key={amortization.paymentNumber} style={{ cursor: "pointer" }} onClick={() => { setMonthAmortization(index) }}>
                              <td>{amortization.paymentNumber}</td>
                              <td>{convertToFormattedValue((amortization.principalPaymentRounded.toFixed(0) - (amortization.principalPaymentRounded.toFixed(0) / 12) * (12 - parseInt(moment().format("M")) + 1)).toFixed(0))}</td>
                              <td>{convertToFormattedValue((amortization.interestPaymentRounded.toFixed(0) - (amortization.interestPaymentRounded.toFixed(0) / 12) * (12 - parseInt(moment().format("M")) + 1)).toFixed(0))}</td>
                              <td>{convertToFormattedValue((amortization.paymentRounded.toFixed(0) - (amortization.paymentRounded.toFixed(0) / 12) * (12 - parseInt(moment().format("M")) + 1)).toFixed(0))}</td>
                              <td>{new Date().getFullYear() + index}</td>
                            </tr>
                            :
                            <tr key={amortization.paymentNumber} style={{ cursor: "pointer" }} onClick={() => { setMonthAmortization(index) }}>
                              <td>{amortization.paymentNumber}</td>
                              <td>{convertToFormattedValue(amortization.principalPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(amortization.interestPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(amortization.paymentRounded.toFixed(0))}</td>
                              <td>{new Date().getFullYear() + index}</td>
                            </tr>
                      }
                    </>
                  )
                }) : ""}
              </tbody>
            </Table>
          </div> : ""}
          <Modal show={this.state.showMonthModal} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Monthly Amortization for the year {this.state.currentYear}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "50vh", overflow: "auto" }}>
              <Table responsive bordered>
                <thead className="text-left">
                  <tr>
                    <th style={{ color: "#E31A52", fontWeight: "600" }}>Month</th>
                    <th style={{ color: "#E31A52", fontWeight: "600" }}>Principal Payment</th>
                    <th style={{ color: "#E31A52", fontWeight: "600" }}>Interest Payment</th>
                    <th style={{ color: "#E31A52", fontWeight: "600" }}>Amount to be paid every Month</th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {this.state.currentYearsMonthlyAmortizationDetails.length > 0 ? this.state.currentYearsMonthlyAmortizationDetails.map((amortization, index) => {
                    return (<>
                      {
                        this.state.currentYear == moment().format("YYYY") ?
                          parseInt(moment().format("M")) + index <= 12 ?
                            <tr key={amortization.paymentNumber + parseInt(moment().format("M"))}>
                              <td>{moment(parseInt(moment().format("M")) + index, 'MM').format('MMMM')}</td>
                              <td>{convertToFormattedValue(this.state.currentYearsMonthlyAmortizationDetails[(12 - this.state.monthNumber) + index].principalPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(this.state.currentYearsMonthlyAmortizationDetails[(12 - this.state.monthNumber) + index].interestPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(this.state.currentYearsMonthlyAmortizationDetails[(12 - this.state.monthNumber) + index].payment.toFixed(0))}</td>
                            </tr> : null
                          :
                          this.state.currentYear == parseInt(moment().format("YYYY")) + (this.state.tenure) ?
                            index < (parseInt(moment().format("M")) > 2 ? parseInt(moment().format("M")) - 1 : 1) ?
                              < tr key={amortization.paymentNumber}>
                                <td>{moment(index + 1, 'MM').format('MMMM')}</td>
                                <td>{convertToFormattedValue(amortization.principalPaymentRounded.toFixed(0))}</td>
                                <td>{convertToFormattedValue(amortization.interestPaymentRounded.toFixed(0))}</td>
                                <td>{convertToFormattedValue(amortization.payment.toFixed(0))}</td>
                              </tr> : null
                            :
                            < tr key={amortization.paymentNumber}>
                              <td>{moment(index + 1, 'MM').format('MMMM')}</td>
                              <td>{convertToFormattedValue(amortization.principalPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(amortization.interestPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(amortization.payment.toFixed(0))}</td>
                            </tr>
                      }
                    </>
                    )
                  }) : ""}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {this.state.moreDetails ? <p className="lower-mid-size-content mt-5 " onClick={toggleCalculatorDetails} style={{ cursor: "pointer" }}>Show Less details!  &#10095;</p> : null}
        </div>
        {/* <div className="doughnut-mobile py-5">
          <Doughnut className="m-auto" data={data} options={options} weight={1} />
        </div> */}
      </div >
    );
  }
}

export default HomeLoanCalcComponent;

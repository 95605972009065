import Layout from "../Layout";
import { useEffect, useState } from "react";
import { Col, Row, OverlayTrigger, Popover, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/FinancialInformation.css"

export function FAQHomePage(props) {
    const [allFAQs, setAllFAQs] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const goToGeneralInformation = () => {
        props.history.push("/faqs/general");
    }
    const goToFAQQueries = () => {
        props.history.push("/faqs/queries");
    }
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        return () => mounted = false;

    }, [])

    return (<div><Layout mainContent={<div data-aos="fade-up" className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    FAQ
                    </span>
            </p>
        </div>
        <div className="headings BranchNetworkHeading">Frequently Asked Questions</div>
        <Row>
            <Col onClick={() => goToFAQQueries()} className="col-12 col-sm-4 col-md-4 col-lg-3 m-auto" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem", marginBottom: "3rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                Queries / Complaints
            </Col>
            <Col onClick={() => setShow(true)} className="col-12 col-sm-4 col-md-4 col-lg-3 m-auto mt-3" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                Home Loan Guide
            </Col>
            <Col onClick={() => goToGeneralInformation()} className="col-12 col-sm-4 col-md-4 col-lg-3 m-auto" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                General Information
            </Col>
        </Row>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Home Loan Guide</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><Link to="/faqs/home-loan-tax-benefits">Tax Benefits</Link></p>
                <p><Link to="/faqs/home-loan-process">Home Loan Process at RHFL</Link></p>
                <p><Link to="/faqs/home-loan-supporting-doc">Supporting Documents (RH-3)</Link></p>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ background: "#E31A52", border: "none" }} onClick={handleClose}>
                    Back
                    </Button>
            </Modal.Footer>
        </Modal>
    </div>} />
    </div>)
}
import Layout from "./Layout";
import * as ContactService from "../services/services";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/ContactUS.css"

export function ContactUS() {
    const [contactUsInfo, setcontactUsInfo] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        ContactService.getRepcoDetails().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                // let responseFormat = [];
                // responseFormat.push(resp);
                setcontactUsInfo(resp);
            }
        })
        return () => mounted = false;

    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Contact Us
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Contact US
            </div>
            {contactUsInfo.length > 0 ?
                <Row>
                    <Col>
                        <div style={{
                            width: "100%", backgroundImage: "url('http://134.209.154.158/backend/uploads/contact_us_2_36e9bbd40c.jpeg')",
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            margin: 'auto',
                            backgroundPosition: 'initial',
                            borderRadius: '0.25rem',
                        }}>

                        </div>
                    </Col>
                    <Col className="col-sm-12 col-md-8 col-lg-8 col-12 m-auto">
                        <div className="text-left lower-mid-size-content mb-3" style={{
                            whiteSpace: "pre-wrap", margin: "auto"
                        }}>{contactUsInfo.length > 0 ? <div>
                            <div style={{ textAlign: "left", width: "fit-content", margin: "auto" }} dangerouslySetInnerHTML={{ __html: contactUsInfo[0].RepcoRegisteredOfficeAddress }}></div>
                        </div> : "Loading..."}
                        </div>
                        <Link to="/apply-now">
                            <Button type="button" style={{ backgroundColor: "#fdb515", border: "none" }}>
                                Apply for Home Loan
                            </Button>
                        </Link>
                    </Col>

                    {/* <Col className="m-auto">
                        <Form style={{ width: "80%", margin: "auto" }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control autoComplete="email" type="email" placeholder="Enter email" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" autoComplete="new-password" placeholder="Password" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group controlId="formGridAddress1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control placeholder="1234 Main St" />
                            </Form.Group>

                            <Form.Group controlId="formGridAddress2">
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control placeholder="Apartment, studio, or floor" />
                            </Form.Group>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control as="select" defaultValue="Choose...">
                                        <option>Choose...</option>
                                        <option value="1">TamilNadu</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control />
                                </Form.Group>
                            </Form.Row>

                            <Button type="button" className="mt-5" style={{ backgroundColor: "#fdb515", border: "none" }}>
                               Apply for Home Loan
                            </Button>
                        </Form>
                    </Col> */}
                </Row> : ""}
            <Row className="mt-5">

            </Row>
        </div>} />
    </div >)
}
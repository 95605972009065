import Layout from "./Layout";
import * as ContactService from "../services/services";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/ContactUS.css"

export function AreaConversionCalculator() {
    const [conversionList, setConversionList] = useState([]);
    const [selectedConversion, setselectedConversion] = useState([]);
    const [unit, setUnit] = useState([]);
    const [calculationUnit, setcalculationUnit] = useState([]);
    const [selectedUnitName, setselectedUnitName] = useState([]);
    const changeUnit = (event) => {
        setUnit(event.target.value)
        let calculationFormula = event.target.value / selectedConversion
        setcalculationUnit(calculationFormula)
        console.log(event.target.value)
    }
    const changedConversionUnit = (event) => {
        console.log(event.target.value)
        setUnit(0)
        setcalculationUnit(0)
        let conversionData = event.target.value.split(",");
        setselectedConversion(conversionData[0])
        setselectedUnitName(conversionData[1])
    }
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        ContactService.getAreaConversionUnits().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                console.log(resp)
                setConversionList(resp)
            }
        })
        return () => mounted = false;

    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <Link to="/other-calculators" className="mr-2">
                        Other Calculators
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Area Conversion Calculator
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Area Conversion Calculator
            </div>
            <Row className="m-auto w-100">
                <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-4">
                    <Form.Group controlId="exampleForm.SelectCustom" className="m-auto">
                        <Form.Label>Select Unit</Form.Label>
                        <Form.Control as="select" onChange={changedConversionUnit.bind(this)}>
                            <option>Select Unit</option>
                            {conversionList.map((conversion, index) => {
                                return (
                                    <option key={conversion.id} value={[conversion.ConversionValue, conversion.ConversionName]}>{conversion.ConversionName}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-8">
                    <Form.Group controlId="exampleForm.SelectCustom" className="m-auto">
                        <Form.Label>Enter Unit Value</Form.Label>
                        <Form.Control
                            type="number"
                            name="Unit"
                            value={unit}
                            onChange={changeUnit.bind(this)}
                        // isValid={touched.LanguagesKnown && !errors.LanguagesKnown}
                        // isInvalid={!!errors.LanguagesKnown}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {unit > 0 ? <Table striped bordered hover className="pt-5 mt-5" style={{ width: "98%", margin: "auto" }}>
                <thead>
                    <tr>
                        <th>Unit</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {conversionList.map((list, index) => {
                        return (
                            list.ConversionName != selectedUnitName ?
                                <tr>
                                    <td>{list.ConversionName}</td>
                                    <td style={{ textAlign: "left" }}>{calculationUnit * list.ConversionValue}</td>
                                </tr> : null
                        )
                    })}
                </tbody>
            </Table> : <p className="mt-5" style={{ width: "98%", margin: "auto", fontSize: 20 }}>Choose a unit and enter a value to start</p>}
            <p className="my-5 font-weight-bold" style={{textAlign: "right"}}>* The Roman numeral suffixes at the end of some of the units, e.g. Bigha I and Bigha II, indicate that more than one measure of area exists by the same name in different regions, and are not a part of the unit's nomenclature.</p>
            <p className="font-weight-bold" style={{textAlign: "right"}}>Disclaimer: We verify the accuracy of calculations pertaining to Metric and English measure of area upto two decimals. However, as several versions of the regional units exist in the sub-continent, we suggest users undertake a sample calculation to establish consonance between their unit measure and that used in our calculator.</p>
        </div>} />
    </div >)
}
import Layout from "./Layout";
import { Col, Jumbotron, Row } from "react-bootstrap";
import "../styles/NewsUpdates.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import * as NewsService from "../services/services";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


export function NewsUpdates() {
    const [newsUpdates, setNewsUpdates] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        NewsService.getNewsUpdates().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setNewsUpdates(resp)
            }
        })
        return () => mounted = false;
    }, [])



    return (<div><Layout mainContent={<div className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content mb-5" style={{ marginTop: "10rem" }}>
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    News Updates
                    </span>
            </p>
        </div>
        <Jumbotron className="newsUpdatesBackground mb-0">
            <p className="headings newsUpdates">News Updates!</p>
        </Jumbotron>
        <div style={{ backgroundColor: "#D3D3D380", padding: "2rem", borderRadius: "0.25rem", textAlign: "left", height: "20rem" }}>
            {/* <p className="headings">Other newsUpdates</p> */}
            <div className="microsoft container">
                <p className="marquee">
                    {newsUpdates.length > 0 ? newsUpdates.map((newsUpdate, index) => {
                        return (
                            <div style={{marginBottom: "1rem"}} key={index}>
                                 <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;<a style={{padding: "1rem"}} href={process.env.REACT_APP_BASE_URL + newsUpdate.NewsPDF.url} target="_blank">{newsUpdate.NewsName}</a>
                            </div>
                        )
                    }) : ""}
                </p>
            </div>
        </div>
    </div >} /></div >)
}

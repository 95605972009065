import Layout from "./Layout";
import CurrentOpenings from "./Careers/CurrentOpenings"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Button, Card, Col, InputGroup, Row, Tab, TabContent, Tabs, Form, Modal } from "react-bootstrap";
import * as CareerService from "../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import "../styles/FinancialInformation.css"
import { Formik } from 'formik';
import * as yup from 'yup';
import { Country, State, City } from 'country-state-city';


export function Careers() {
    const [careerDetails, setCareerDetails] = useState([]);
    const [ActiveAccordion, setActiveAccordion] = useState("0");
    const [ProfileFormData, setProfileFormData] = useState({});
    const [AllFormsStatus, setAllFormsStatus] = useState([]);
    const [showSuccess, setshowSuccess] = useState(false);
    const [activeTab, setactiveTab] = useState("currentOpenings");
    const [StateData, setStateData] = useState(null);
    const [Cities, setCities] = useState(null);
    // const { Formik } = formik;

    const getCitybyState = (event) => {
        if (event.target.value) {
            console.log(event.target.value)
            setCities(City.getCitiesOfState("IN", event.target.value))
            console.log(City.getCitiesOfState("IN", event.target.value))
        }
        else setCities(null)
    }

    const handleClose = () => {
        setshowSuccess(false);
        window.location.reload();
    }

    const changeTab = () => {
        setactiveTab("submitResume")
    }

    const personalInformationSchema = yup.object().shape({
        State: yup.string().required(),
        City: yup.string().required(),
        FirstName: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        LastName: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        FatherHusbandName: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        Gender: yup.string().required(),
        DateOfBirth: yup.string().required(),
        MaritalStatus: yup.string().required(),
        LanguagesKnown: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PermanentAddress: yup.string().required().trim(),
        PermanentAddressPinCode: yup.string().required().min(6).max(6),
        CorrespondenceAddress: yup.string().required().trim(),
        CorrespondencePinCode: yup.string().required().min(6).max(6),
        EmailId: yup.string().email().required(),
        Mobile: yup.string().required().min(10).max(10).matches(/^\d+$/, 'The field should have digits only'),
        Photo: yup.string().required(),
    });

    const workExperienceSchema = yup.object().shape({
        DoYouHavePriorWorkExperience: yup.string().required(),
        TotalExperienceInMonths: yup.string().required().matches(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/, "The field cannot be less than or equal to 0"),
        CurrentEmployerName: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        CurrentDesignation: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        CurrentLocation: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        CurrentJobDescription: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        CurrentAnnualGrossSalary: yup.string().required().matches(/^\d+$/, 'The field should have digits only').matches(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/, "The field cannot be less than or equal to 0"),
        PreviousEmployerName: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PreviousDesignation: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PreviousLocation: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PreviousJobDescription: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PreviousAnnualGrossSalary: yup.string().matches(/^\d+$/, 'The field should have digits only').matches(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/, "The field cannot be less than or equal to 0"),
    });

    const educationalQualificationSchema = yup.object().shape({
        GraduationStream: yup.string().required(),
        GraduationCourseType: yup.string().required(),
        GraduationInstitute: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        GraduationUniversity: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        GraduationPercentage: yup.string().required().matches(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/, "Percentage should be less than 100 and greater than 0"),
        GraduationYear: yup.string().required().matches(/^\d+$/, 'The field should have digits only').min(4).max(4),
        GraduationSpecialization: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        GraduationOthers: yup.string(),
        AreYouPostGraduate: yup.string().required(),
        PostGraduationStream: yup.string().required(),
        PostGraduationCourseType: yup.string().required(),
        PostGraduationInstitute: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PostGraduationUniversity: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PostGraduationPercentage: yup.string().required().matches(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/, "Percentage should be less than 100 and greater than 0"),
        PostGraduationYear: yup.string().required().matches(/^\d+$/, 'The field should have digits only').min(4).max(4),
        PostGraduationSpecialization: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        PostGraduationOthers: yup.string(),
    });

    const otherInformationSchema = yup.object().shape({
        HaveRelativesAtRHFL: yup.string().required(),
        RelativeName: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        RelativeLocation: yup.string().required().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        RelativeRelationship: yup.string().required().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        RelativeDesignation: yup.string().required().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        HaveYouAppearedForInterview: yup.string().required(),
        ApplyingPosition: yup.string().required(),
        RESUME: yup.string().required(),
        Declaration: yup.string().required(),
        Place: yup.string().required().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").trim(),
        Date: yup.string().required(),
    });
    const getDisabledstatus = (values, errors) => {
        if (values) {
            if (values.State != "") {
                if ((Object.keys(errors).length == 0)) {
                    return false
                }
                else {
                    return true
                }
            }
            else {
                return true
            }
        }
        else {
            return true
        }
    }
    const getDisabledstatus2 = (values, errors) => {
        if (values) {
            if (values.DoYouHavePriorWorkExperience == "Yes") {
                console.log(errors)
                if ((Object.keys(errors).length == 0)) {
                    return false
                }
                else {
                    return true
                }
            }
            else {
                return false
            }
        }
        else {
            return true
        }
    }
    const getDisabledstatus3 = (values, errors) => {
        if (values) {
            if (values.GraduationStream != "") {
                if (values.AreYouPostGraduate == "Yes") {
                    if ((Object.keys(errors).length == 0)) {
                        return false
                    }
                    else {
                        return true
                    }
                }
                else {
                    if ((Object.keys(errors).length <= 6)) {
                        return false
                    }
                    else {
                        return true
                    }
                }
            }
            else {
                return true
            }
        }
        else {
            return true
        }
    }
    const getDisabledstatus4 = (values, errors) => {
        if (values) {
            if (values.HaveRelativesAtRHFL == "Yes") {
                if (values.RelativeName != "") {
                    if ((Object.keys(errors).length == 0)) {
                        return false
                    }
                    else {
                        return true
                    }
                }
                else return true
            }
            else {
                if ((Object.keys(errors).length <= 4)) {
                    return false
                }
                else {
                    return true
                }
            }
        }
        else {
            return true
        }
    }

    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        setStateData(State.getStatesOfCountry("IN"))
        CareerService.getCareerInformation().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setCareerDetails(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Careers
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Careers
            </div>
            <Row>
                <Tabs className="w-100"
                    defaultActiveKey="currentOpenings"
                    activeKey={activeTab}
                    id="uncontrolled-tab-example"
                    onSelect={(k) => setactiveTab(k)}
                >
                    <Tab eventKey="currentOpenings" title="Current Openings">
                        <TabContent className="ProductContent mt-3">
                            <div >
                                <CurrentOpenings type="Current Openings" changeTab={() => { changeTab() }} />
                            </div>
                        </TabContent>
                    </Tab>

                    <Tab eventKey="submitResume" title="Submit Resume">
                        <TabContent className="ProductContent mt-3" style={{ width: "100%" }}>
                            <Accordion defaultActiveKey="0" activeKey={ActiveAccordion}>
                                <Card>
                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"0"} onClick={() => { setActiveAccordion("0") }}>
                                        Personal Information
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={"0"}>
                                        <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                            <Formik
                                                validationSchema={personalInformationSchema}
                                                onSubmit={console.log}
                                                initialValues={{
                                                    State: "",
                                                    City: "",
                                                    FirstName: "",
                                                    LastName: "",
                                                    FatherHusbandName: "",
                                                    Gender: "Male",
                                                    DateOfBirth: "",
                                                    MaritalStatus: "Single",
                                                    LanguagesKnown: "",
                                                    PermanentAddress: "",
                                                    PermanentAddressPinCode: "",
                                                    CorrespondenceAddress: "",
                                                    CorrespondencePinCode: "",
                                                    EmailId: "",
                                                    Mobile: "",
                                                    Photo: "",
                                                }}
                                            >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    touched,
                                                    isValid,
                                                    errors,
                                                }) => (
                                                    <Form noValidate onSubmit={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        const formData = new FormData(e.target),
                                                            formDataObj = Object.fromEntries(formData.entries())
                                                        window.scrollTo(0, 0)
                                                        // setActiveAccordion("1")
                                                        // if (getDisabledstatus()) {
                                                        let data = {};
                                                        data = { ...ProfileFormData, ...formDataObj }
                                                        setProfileFormData(data)
                                                        let status = AllFormsStatus;
                                                        status.push("Form1")
                                                        setAllFormsStatus(status)
                                                        setActiveAccordion("1")
                                                        // }
                                                    }}>

                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" controlId="formGridFirstName">
                                                                <Form.Label>First name *</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="FirstName"
                                                                    value={values.FirstName}
                                                                    onChange={handleChange}
                                                                    isValid={touched.FirstName && !errors.FirstName}
                                                                    isInvalid={!!errors.FirstName}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.FirstName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridLastName">
                                                                <Form.Label>Last name *</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="LastName"
                                                                    value={values.LastName}
                                                                    onChange={handleChange}
                                                                    isValid={touched.LastName && !errors.LastName}
                                                                    isInvalid={!!errors.LastName}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.LastName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridFatherHusbandName">
                                                                <Form.Label>Father / Husband Name *</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="FatherHusbandName"
                                                                    value={values.FatherHusbandName}
                                                                    onChange={handleChange}
                                                                    isValid={touched.FatherHusbandName && !errors.FatherHusbandName}
                                                                    isInvalid={!!errors.FatherHusbandName}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.FatherHusbandName}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" controlId="formGridDateOfBirth">
                                                                <Form.Label>Date of Birth *</Form.Label>
                                                                <Form.Control
                                                                    type="date"
                                                                    name="DateOfBirth"
                                                                    value={values.DateOfBirth}
                                                                    onChange={handleChange}
                                                                    isValid={touched.DateOfBirth && !errors.DateOfBirth}
                                                                    isInvalid={!!errors.DateOfBirth}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.DateOfBirth}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="validationFormikGender">
                                                                <Form.Label>Gender *</Form.Label>
                                                                <Form.Check
                                                                    name="Gender"
                                                                    type="radio"
                                                                    id="genderradio1"
                                                                    label="Male"
                                                                    value="Male"
                                                                    onChange={handleChange}
                                                                    defaultChecked
                                                                    isValid={touched.Gender && !errors.Gender}
                                                                />
                                                                <Form.Check
                                                                    name="Gender"
                                                                    type="radio"
                                                                    id="genderradio2"
                                                                    label="Female"
                                                                    value="Female"
                                                                    onChange={handleChange}
                                                                    isValid={touched.Gender && !errors.Gender}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.Gender}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="validationFormikMaritalStatus">
                                                                <Form.Label>Marital Status *</Form.Label>
                                                                <Form.Check
                                                                    name="MaritalStatus"
                                                                    type="radio"
                                                                    id="MaritalStatusradio1"
                                                                    label="Single"
                                                                    value="Single"
                                                                    defaultChecked
                                                                    onChange={handleChange}
                                                                    isValid={touched.MaritalStatus && !errors.MaritalStatus}
                                                                />
                                                                <Form.Check
                                                                    name="MaritalStatus"
                                                                    type="radio"
                                                                    id="MaritalStatusradio2"
                                                                    label="Married"
                                                                    value="Married"
                                                                    onChange={handleChange}
                                                                    isValid={touched.MaritalStatus && !errors.MaritalStatus}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.MaritalStatus}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>


                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" controlId="formGridState">
                                                                <Form.Label style={{ textAlign: "left", width: "100%" }}>State *</Form.Label>
                                                                <Form.Control required as="select" name="State" value={values.State}
                                                                    onChange={handleChange}
                                                                    onClick={getCitybyState.bind(this)}
                                                                    isValid={touched.State && !errors.State}
                                                                    isInvalid={!!errors.State}
                                                                >
                                                                    <option value="">Select State</option>
                                                                    {StateData ? StateData.map((state, index) => { return (<option key={index} value={state.isoCode}>{state.name}</option>) }) : null}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.State}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridCity">
                                                                <Form.Label style={{ textAlign: "left", width: "100%" }}>City *</Form.Label>
                                                                <Form.Control required as="select" name="City" defaultValue="" value={values.City}
                                                                    onChange={handleChange}
                                                                    isValid={touched.City && !errors.City}
                                                                    isInvalid={!!errors.City}
                                                                >
                                                                    <option value="">Select City</option>
                                                                    {Cities ? Cities.map((city, index) => { return (<option key={index} value={city.isoCode}>{city.name}</option>) }) : null}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.City}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>

                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" controlId="formGridLanguagesKnown">
                                                                <Form.Label>Languages Known *</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="LanguagesKnown"
                                                                    value={values.LanguagesKnown}
                                                                    onChange={handleChange}
                                                                    isValid={touched.LanguagesKnown && !errors.LanguagesKnown}
                                                                    isInvalid={!!errors.LanguagesKnown}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.LanguagesKnown}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" controlId="formGridPermanentAddress">
                                                                <Form.Label>Permanent Address *</Form.Label>
                                                                <Form.Control
                                                                    as="textarea" rows={3}
                                                                    type="text"
                                                                    name="PermanentAddress"
                                                                    value={values.PermanentAddress}
                                                                    onChange={handleChange}
                                                                    isValid={touched.PermanentAddress && !errors.PermanentAddress}
                                                                    isInvalid={!!errors.PermanentAddress}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.PermanentAddress}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridPermanentAddressPinCode">
                                                                <Form.Label>Permanent Address Pincode *</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="PermanentAddressPinCode"
                                                                    value={values.PermanentAddressPinCode}
                                                                    onChange={handleChange}
                                                                    isValid={touched.PermanentAddressPinCode && !errors.PermanentAddressPinCode}
                                                                    isInvalid={!!errors.PermanentAddressPinCode}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.PermanentAddressPinCode}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridCorrespondenceAddress">
                                                                <Form.Label>Correspondence Address *</Form.Label>
                                                                <Form.Control
                                                                    as="textarea" rows={3}
                                                                    type="text"
                                                                    name="CorrespondenceAddress"
                                                                    value={values.CorrespondenceAddress}
                                                                    onChange={handleChange}
                                                                    isValid={touched.CorrespondenceAddress && !errors.CorrespondenceAddress}
                                                                    isInvalid={!!errors.CorrespondenceAddress}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.CorrespondenceAddress}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridCorrespondencePinCode">
                                                                <Form.Label>Correspondence Address Pincode *</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="CorrespondencePinCode"
                                                                    value={values.CorrespondencePinCode}
                                                                    onChange={handleChange}
                                                                    isValid={touched.CorrespondencePinCode && !errors.CorrespondencePinCode}
                                                                    isInvalid={!!errors.CorrespondencePinCode}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.CorrespondencePinCode}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="3" controlId="formGridEmailId">
                                                                <Form.Label>Email ID *</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="EmailId"
                                                                    value={values.EmailId}
                                                                    onChange={handleChange}
                                                                    isValid={touched.EmailId && !errors.EmailId}
                                                                    isInvalid={!!errors.EmailId}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.EmailId}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridMobile">
                                                                <Form.Label>Mobile Number *</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="Mobile"
                                                                    value={values.Mobile}
                                                                    onChange={handleChange}
                                                                    isValid={touched.Mobile && !errors.Mobile}
                                                                    isInvalid={!!errors.Mobile}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.Mobile}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridPhoto">
                                                                <Form.Label>Photo *</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name="Photo"
                                                                    accept="image/*"
                                                                    value={values.Photo}
                                                                    onChange={handleChange}
                                                                    isValid={touched.Photo && !errors.Photo}
                                                                    isInvalid={!!errors.Photo}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.Photo}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Button type="submit" disabled={getDisabledstatus(values, errors)}>Continue</Button>
                                                        {/* <Button type="button" onClick={() => { setActiveAccordion("2"); console.log(ActiveAccordion) }}>Back</Button> */}
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"1"}>
                                        Work Experience
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={"1"}>
                                        <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                            <Formik
                                                validationSchema={workExperienceSchema}
                                                onSubmit={console.log}
                                                initialValues={{
                                                    DoYouHavePriorWorkExperience: "No",
                                                    TotalExperienceInMonths: "",
                                                    CurrentEmployerName: "",
                                                    CurrentDesignation: "",
                                                    CurrentLocation: "",
                                                    CurrentJobDescription: "",
                                                    CurrentAnnualGrossSalary: "",
                                                    PreviousEmployerName: "",
                                                    PreviousDesignation: "",
                                                    PreviousLocation: "",
                                                    PreviousJobDescription: "",
                                                    PreviousAnnualGrossSalary: "",
                                                }}
                                            >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    touched,
                                                    isValid,
                                                    errors,
                                                }) => (
                                                    <Form noValidate onSubmit={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        const formData = new FormData(e.target),
                                                            formDataObj = Object.fromEntries(formData.entries())
                                                        window.scrollTo(300, 300)
                                                        window.scrollTo(0, 0)
                                                        // if (!getDisabledstatus2(values, errors)) {
                                                        let data = ProfileFormData;
                                                        data = { ...data, ...formDataObj }
                                                        setProfileFormData(data)
                                                        let status = AllFormsStatus;
                                                        status.push("Form2")
                                                        setAllFormsStatus(status)
                                                        setActiveAccordion("2")
                                                        // }
                                                    }}>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="12" controlId="validationFormikDoYouHavePriorExperience">
                                                                <Form.Label>Do you have prior work experience *</Form.Label>
                                                                <Form.Check
                                                                    name="DoYouHavePriorWorkExperience"
                                                                    type="radio"
                                                                    id="DoYouHavePriorWorkExperienceradio1"
                                                                    label="Yes"
                                                                    value="Yes"
                                                                    onChange={handleChange}
                                                                    isValid={touched.DoYouHavePriorWorkExperience && !errors.DoYouHavePriorWorkExperience}
                                                                />
                                                                <Form.Check
                                                                    name="DoYouHavePriorWorkExperience"
                                                                    type="radio"
                                                                    id="DoYouHavePriorWorkExperienceradio2"
                                                                    label="No"
                                                                    value="No"
                                                                    defaultChecked
                                                                    onChange={handleChange}
                                                                    isValid={touched.DoYouHavePriorWorkExperience && !errors.DoYouHavePriorWorkExperience}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.DoYouHavePriorWorkExperience}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            {/* </Form.Row> */}

                                                            {values.DoYouHavePriorWorkExperience == "Yes" ?
                                                                // <Form.Row className="mt-3">
                                                                <>
                                                                    <Form.Group as={Col} md="3" controlId="formGridTotalExperienceInMonths">
                                                                        <Form.Label>Total Experience (In Months) *</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            name="TotalExperienceInMonths"
                                                                            value={values.TotalExperienceInMonths}
                                                                            onChange={handleChange}
                                                                            isValid={touched.TotalExperienceInMonths && !errors.TotalExperienceInMonths}
                                                                            isInvalid={!!errors.TotalExperienceInMonths}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.TotalExperienceInMonths}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <h5 className="w-100 ml-1 font-weight-bold my-4">Details of current employment *</h5>
                                                                    <Form.Group as={Col} md="3" controlId="formGridCurrentEmployerName">
                                                                        <Form.Label>Employer Name</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="CurrentEmployerName"
                                                                            value={values.CurrentEmployerName}
                                                                            onChange={handleChange}
                                                                            isValid={touched.CurrentEmployerName && !errors.CurrentEmployerName}
                                                                            isInvalid={!!errors.CurrentEmployerName}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.CurrentEmployerName}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridCurrentDesignation">
                                                                        <Form.Label>Designation *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="CurrentDesignation"
                                                                            value={values.CurrentDesignation}
                                                                            onChange={handleChange}
                                                                            isValid={touched.CurrentDesignation && !errors.CurrentDesignation}
                                                                            isInvalid={!!errors.CurrentDesignation}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.CurrentDesignation}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridCurrentLocation">
                                                                        <Form.Label>Location *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="CurrentLocation"
                                                                            value={values.CurrentLocation}
                                                                            onChange={handleChange}
                                                                            isValid={touched.CurrentLocation && !errors.CurrentLocation}
                                                                            isInvalid={!!errors.CurrentLocation}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.CurrentLocation}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridCurrentJobDescription">
                                                                        <Form.Label>Job Description *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="CurrentJobDescription"
                                                                            value={values.CurrentJobDescription}
                                                                            onChange={handleChange}
                                                                            isValid={touched.CurrentJobDescription && !errors.CurrentJobDescription}
                                                                            isInvalid={!!errors.CurrentJobDescription}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.CurrentJobDescription}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridCurrentAnnualGrossSalary">
                                                                        <Form.Label>Current Annual Gross Salary *</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            name="CurrentAnnualGrossSalary"
                                                                            value={values.CurrentAnnualGrossSalary}
                                                                            onChange={handleChange}
                                                                            isValid={touched.CurrentAnnualGrossSalary && !errors.CurrentAnnualGrossSalary}
                                                                            isInvalid={!!errors.CurrentAnnualGrossSalary}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.CurrentAnnualGrossSalary}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>

                                                                    {/* PREVIOUS EMPLOYER */}
                                                                    <h5 className="w-100 ml-1 font-weight-bold my-4">Details of previous employment (optional)</h5>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPreviousEmployerName">
                                                                        <Form.Label>Employer Name</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PreviousEmployerName"
                                                                            value={values.PreviousEmployerName}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PreviousEmployerName && !errors.PreviousEmployerName}
                                                                            isInvalid={!!errors.PreviousEmployerName}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PreviousEmployerName}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPreviousDesignation">
                                                                        <Form.Label>Designation</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PreviousDesignation"
                                                                            value={values.PreviousDesignation}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PreviousDesignation && !errors.PreviousDesignation}
                                                                            isInvalid={!!errors.PreviousDesignation}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PreviousDesignation}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPreviousLocation">
                                                                        <Form.Label>Location</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PreviousLocation"
                                                                            value={values.PreviousLocation}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PreviousLocation && !errors.PreviousLocation}
                                                                            isInvalid={!!errors.PreviousLocation}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PreviousLocation}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPreviousJobDescription">
                                                                        <Form.Label>Job Description</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PreviousJobDescription"
                                                                            value={values.PreviousJobDescription}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PreviousJobDescription && !errors.PreviousJobDescription}
                                                                            isInvalid={!!errors.PreviousJobDescription}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PreviousJobDescription}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPreviousAnnualGrossSalary">
                                                                        <Form.Label>Previous Annual Gross Salary</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            name="PreviousAnnualGrossSalary"
                                                                            value={values.PreviousAnnualGrossSalary}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PreviousAnnualGrossSalary && !errors.PreviousAnnualGrossSalary}
                                                                            isInvalid={!!errors.PreviousAnnualGrossSalary}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PreviousAnnualGrossSalary}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </>
                                                                // </Form.Row>
                                                                : null}
                                                        </Form.Row>
                                                        <Button type="submit" disabled={getDisabledstatus2(values, errors)}>Continue</Button>
                                                        <Button type="button" onClick={() => { setActiveAccordion("0"); console.log(ActiveAccordion) }}>Back</Button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"2"}>
                                        Educational Information
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={"2"}>
                                        <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                                <Formik
                                                    validationSchema={educationalQualificationSchema}
                                                    onSubmit={console.log}
                                                    initialValues={{
                                                        GraduationStream: "",
                                                        GraduationCourseType: "Full-Time",
                                                        GraduationInstitute: "",
                                                        GraduationUniversity: "",
                                                        GraduationPercentage: "",
                                                        GraduationYear: "",
                                                        GraduationSpecialization: "",
                                                        GraduationOthers: "",
                                                        AreYouPostGraduate: "No",
                                                        PostGraduationStream: "",
                                                        PostGraduationCourseType: "Full-Time",
                                                        PostGraduationInstitute: "",
                                                        PostGraduationUniversity: "",
                                                        PostGraduationPercentage: "",
                                                        PostGraduationYear: "",
                                                        PostGraduationSpecialization: "",
                                                        PostGraduationOthers: "",
                                                    }}
                                                >
                                                    {({
                                                        handleSubmit,
                                                        handleChange,
                                                        handleBlur,
                                                        values,
                                                        touched,
                                                        isValid,
                                                        errors,
                                                    }) => (
                                                        <Form noValidate onSubmit={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            window.scrollTo(0, 0)
                                                            const formData = new FormData(e.target),
                                                                formDataObj = Object.fromEntries(formData.entries())
                                                            window.scrollTo(300, 300)
                                                            let data = ProfileFormData;
                                                            data = { ...data, ...formDataObj }
                                                            setProfileFormData(data)
                                                            let status = AllFormsStatus;
                                                            status.push("Form3")
                                                            setAllFormsStatus(status)
                                                            setActiveAccordion("3")
                                                        }}>
                                                            <Form.Row>
                                                                <h5 className="w-100 ml-1 font-weight-bold my-4">Degree Qualifications</h5>
                                                                <h5 className="w-100 ml-1 font-weight-bold my-4">Graduation</h5>
                                                                <Form.Group as={Col} md="3" controlId="GraduateformGridStream">
                                                                    <Form.Label style={{ textAlign: "left", width: "100%" }}>Graduation Stream *</Form.Label>
                                                                    <Form.Control required as="select" name="GraduationStream" value={values.GraduationStream}
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationStream && !errors.GraduationStream}
                                                                        isInvalid={!!errors.GraduationStream}
                                                                    >
                                                                        <option value="">Select Graduation Stream</option>
                                                                        <option value="B.Sc">B.Sc</option>
                                                                        <option value="Bachelor in Arts">Bachelor in Arts</option>
                                                                        <option value="Bachelor in Commerce">Bachelor in Commerce</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="B.Tech / B.E">B.Tech / B.E</option>
                                                                        <option value="Other">Other</option>
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.GraduationStream}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                {values.GraduationStream == "Other" ? <Form.Group as={Col} md="3" controlId="formGridGraduationOthers">
                                                                    <Form.Label>Other Stream *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="GraduationOthers"
                                                                        value={values.GraduationOthers}
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationOthers && !errors.GraduationOthers}
                                                                        isInvalid={!!errors.GraduationOthers}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.GraduationOthers}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group> : null}
                                                                <Form.Group as={Col} md="12" controlId="validationFormikGraduationCourseType">
                                                                    <Form.Label>Course Type *</Form.Label>
                                                                    <Form.Check
                                                                        name="GraduationCourseType"
                                                                        type="radio"
                                                                        id="GraduationCourseTyperadio1"
                                                                        label="Full-Time"
                                                                        value="Full-Time"
                                                                        onChange={handleChange}
                                                                        defaultChecked
                                                                        isValid={touched.GraduationCourseType && !errors.GraduationCourseType}
                                                                    />
                                                                    <Form.Check
                                                                        name="GraduationCourseType"
                                                                        type="radio"
                                                                        id="GraduationCourseTyperadio2"
                                                                        label="Part-Time"
                                                                        value="Part-Time"
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationCourseType && !errors.GraduationCourseType}
                                                                    />
                                                                    <Form.Check
                                                                        name="GraduationCourseType"
                                                                        type="radio"
                                                                        id="GraduationCourseTyperadio3"
                                                                        label="Correspondence / Distance"
                                                                        value="Correspondence / Distance"
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationCourseType && !errors.GraduationCourseType}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.GraduationCourseType}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridGraduationInstitute">
                                                                    <Form.Label>Institute Name *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="GraduationInstitute"
                                                                        value={values.GraduationInstitute}
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationInstitute && !errors.GraduationInstitute}
                                                                        isInvalid={!!errors.GraduationInstitute}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.GraduationInstitute}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridGraduationUniversity">
                                                                    <Form.Label>University Name *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="GraduationUniversity"
                                                                        value={values.GraduationUniversity}
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationUniversity && !errors.GraduationUniversity}
                                                                        isInvalid={!!errors.GraduationUniversity}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.GraduationUniversity}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridGraduationYear">
                                                                    <Form.Label>Year Of Passing *</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name="GraduationYear"
                                                                        value={values.GraduationYear}
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationYear && !errors.GraduationYear}
                                                                        isInvalid={!!errors.GraduationYear}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.GraduationYear}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridGraduationPercentage">
                                                                    <Form.Label>Percentage *</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name="GraduationPercentage"
                                                                        value={values.GraduationPercentage}
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationPercentage && !errors.GraduationPercentage}
                                                                        isInvalid={!!errors.GraduationPercentage}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.GraduationPercentage}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridGraduationSpecialization">
                                                                    <Form.Label>Specialization *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="GraduationSpecialization"
                                                                        value={values.GraduationSpecialization}
                                                                        onChange={handleChange}
                                                                        isValid={touched.GraduationSpecialization && !errors.GraduationSpecialization}
                                                                        isInvalid={!!errors.GraduationSpecialization}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.GraduationSpecialization}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="12" className="mt-5" controlId="validationFormikPGDegreeAvailability">
                                                                    <Form.Label>Do you have a Post Graduate Degree? *</Form.Label>
                                                                    <Form.Check
                                                                        name="AreYouPostGraduate"
                                                                        type="radio"
                                                                        id="AreYouPostGraduateradio1"
                                                                        label="Yes"
                                                                        value="Yes"
                                                                        onChange={handleChange}
                                                                        isValid={touched.AreYouPostGraduate && !errors.AreYouPostGraduate}
                                                                    />
                                                                    <Form.Check
                                                                        name="AreYouPostGraduate"
                                                                        type="radio"
                                                                        id="AreYouPostGraduateradio2"
                                                                        label="No"
                                                                        value="No"
                                                                        defaultChecked
                                                                        onChange={handleChange}
                                                                        isValid={touched.AreYouPostGraduate && !errors.AreYouPostGraduate}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.AreYouPostGraduate}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                {/* PG Degree */}
                                                                {values.AreYouPostGraduate == "Yes" ? <>
                                                                    <h5 className="w-100 ml-1 font-weight-bold my-4">Post-Graduation</h5>
                                                                    <Form.Group as={Col} md="3" controlId="GraduateformGridStream">
                                                                        <Form.Label style={{ textAlign: "left", width: "100%" }}>PG Stream *</Form.Label>
                                                                        <Form.Control required as="select" name="PostGraduationStream" value={values.PostGraduationStream}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationStream && !errors.PostGraduationStream}
                                                                            isInvalid={!!errors.PostGraduationStream}
                                                                        >
                                                                            <option value="">Select Post-Graduation Stream</option>
                                                                            <option value="MBA">MBA</option>
                                                                            <option value="MCM">MCM</option>
                                                                            <option value="M.Sc">M.Sc</option>
                                                                            <option value="M.Com">M.Com</option>
                                                                            <option value="M.A">M.A</option>
                                                                            <option value="Other">Other</option>
                                                                        </Form.Control>
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PostGraduationStream}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    {values.PostGraduationStream == "Other" ? <Form.Group as={Col} md="3" controlId="formGridPostGraduationOthers">
                                                                        <Form.Label>Other PG Stream *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PostGraduationOthers"
                                                                            value={values.PostGraduationOthers}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationOthers && !errors.PostGraduationOthers}
                                                                            isInvalid={!!errors.PostGraduationOthers}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PostGraduationOthers}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group> : null}
                                                                    <Form.Group as={Col} md="12" controlId="validationFormikPostGraduationCourseType">
                                                                        <Form.Label>PG Course Type *</Form.Label>
                                                                        <Form.Check
                                                                            name="PostGraduationCourseType"
                                                                            type="radio"
                                                                            id="PostGraduationCourseTyperadio1"
                                                                            label="Full-Time"
                                                                            value="Full-Time"
                                                                            onChange={handleChange}
                                                                            defaultChecked
                                                                            isValid={touched.PostGraduationCourseType && !errors.PostGraduationCourseType}
                                                                        />
                                                                        <Form.Check
                                                                            name="PostGraduationCourseType"
                                                                            type="radio"
                                                                            id="PostGraduationCourseTyperadio2"
                                                                            label="Part-Time"
                                                                            value="Part-Time"
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationCourseType && !errors.PostGraduationCourseType}
                                                                        />
                                                                        <Form.Check
                                                                            name="PostGraduationCourseType"
                                                                            type="radio"
                                                                            id="PostGraduationCourseTyperadio3"
                                                                            label="Correspondence / Distance"
                                                                            value="Correspondence / Distance"
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationCourseType && !errors.PostGraduationCourseType}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.PostGraduationCourseType}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPostGraduationInstitute">
                                                                        <Form.Label>PG Institute Name *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PostGraduationInstitute"
                                                                            value={values.PostGraduationInstitute}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationInstitute && !errors.PostGraduationInstitute}
                                                                            isInvalid={!!errors.PostGraduationInstitute}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PostGraduationInstitute}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPostGraduationUniversity">
                                                                        <Form.Label>PG University Name *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PostGraduationUniversity"
                                                                            value={values.PostGraduationUniversity}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationUniversity && !errors.PostGraduationUniversity}
                                                                            isInvalid={!!errors.PostGraduationUniversity}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PostGraduationUniversity}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPostGraduationYear">
                                                                        <Form.Label>Year Of Passing PG Degree *</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            name="PostGraduationYear"
                                                                            value={values.PostGraduationYear}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationYear && !errors.PostGraduationYear}
                                                                            isInvalid={!!errors.PostGraduationYear}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PostGraduationYear}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPostGraduationPercentage">
                                                                        <Form.Label>PG Percentage *</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            name="PostGraduationPercentage"
                                                                            value={values.PostGraduationPercentage}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationPercentage && !errors.PostGraduationPercentage}
                                                                            isInvalid={!!errors.PostGraduationPercentage}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PostGraduationPercentage}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3" controlId="formGridPostGraduationSpecialization">
                                                                        <Form.Label>PG Specialization *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="PostGraduationSpecialization"
                                                                            value={values.PostGraduationSpecialization}
                                                                            onChange={handleChange}
                                                                            isValid={touched.PostGraduationSpecialization && !errors.PostGraduationSpecialization}
                                                                            isInvalid={!!errors.PostGraduationSpecialization}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid" className="text-left">
                                                                            {errors.PostGraduationSpecialization}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </> : null}
                                                            </Form.Row>
                                                            <Button type="submit" disabled={getDisabledstatus3(values, errors)}>Continue</Button>
                                                            <Button type="button" onClick={() => { setActiveAccordion("1"); console.log(ActiveAccordion) }}>Back</Button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Card.Body>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"3"}>
                                        Other Information
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={"3"}>
                                        <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                            <Formik
                                                validationSchema={otherInformationSchema}
                                                onSubmit={console.log}
                                                initialValues={{
                                                    HaveRelativesAtRHFL: "Yes",
                                                    RelativeName: "",
                                                    RelativeLocation: "",
                                                    RelativeRelationship: "",
                                                    RelativeDesignation: "",
                                                    HaveYouAppearedForInterview: "No",
                                                    ApplyingPosition: "",
                                                    RESUME: "",
                                                    Declaration: "No",
                                                    Place: "",
                                                    Date: "" + new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
                                                }}
                                            >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    touched,
                                                    isValid,
                                                    errors,
                                                }) => (
                                                    <Form noValidate onSubmit={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        const formData = new FormData(e.target),
                                                            formDataObj = Object.fromEntries(formData.entries())
                                                        window.scrollTo(0, 0)
                                                        let data = ProfileFormData;
                                                        data = { ...data, ...formDataObj }
                                                        setProfileFormData(data)
                                                        let status = AllFormsStatus;
                                                        status.push("Form4")
                                                        setAllFormsStatus(status)
                                                        if (["Form1", "Form2", "Form3", "Form4"].every(i => status.includes(i))) {
                                                            CareerService.newSubmission(ProfileFormData).then((resp) => {
                                                                setshowSuccess(true);
                                                            })
                                                        }
                                                        else {
                                                            alert("Fill in all the 4 sections to submit!")
                                                        }
                                                    }}>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="12" controlId="validationFormikHaveRelativesAtRHFL">
                                                                <Form.Label>Have Relatives At RHFL? *</Form.Label>
                                                                <Form.Check
                                                                    name="HaveRelativesAtRHFL"
                                                                    type="radio"
                                                                    id="HaveRelativesAtRHFLradio1"
                                                                    label="Yes"
                                                                    value="Yes"
                                                                    onChange={handleChange}
                                                                    defaultChecked
                                                                    isValid={touched.HaveRelativesAtRHFL && !errors.HaveRelativesAtRHFL}
                                                                />
                                                                <Form.Check
                                                                    name="HaveRelativesAtRHFL"
                                                                    type="radio"
                                                                    id="HaveRelativesAtRHFLradio2"
                                                                    label="No"
                                                                    value="No"
                                                                    onChange={handleChange}
                                                                    isValid={touched.HaveRelativesAtRHFL && !errors.HaveRelativesAtRHFL}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.HaveRelativesAtRHFL}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            {values.HaveRelativesAtRHFL == "Yes" ? <>
                                                                <Form.Group as={Col} md="3" controlId="formGridRelativeName">
                                                                    <Form.Label>Relative Name *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="RelativeName"
                                                                        value={values.RelativeName}
                                                                        onChange={handleChange}
                                                                        isValid={touched.RelativeName && !errors.RelativeName}
                                                                        isInvalid={!!errors.RelativeName}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.RelativeName}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridRelativeLocation">
                                                                    <Form.Label>Relative Location *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="RelativeLocation"
                                                                        value={values.RelativeLocation}
                                                                        onChange={handleChange}
                                                                        isValid={touched.RelativeLocation && !errors.RelativeLocation}
                                                                        isInvalid={!!errors.RelativeLocation}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.RelativeLocation}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridRelativeRelationship">
                                                                    <Form.Label>Relationship *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="RelativeRelationship"
                                                                        value={values.RelativeRelationship}
                                                                        onChange={handleChange}
                                                                        isValid={touched.RelativeRelationship && !errors.RelativeRelationship}
                                                                        isInvalid={!!errors.RelativeRelationship}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.RelativeRelationship}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="formGridRelativeDesignation">
                                                                    <Form.Label>Relative Designation *</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="RelativeDesignation"
                                                                        value={values.RelativeDesignation}
                                                                        onChange={handleChange}
                                                                        isValid={touched.RelativeDesignation && !errors.RelativeDesignation}
                                                                        isInvalid={!!errors.RelativeDesignation}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" className="text-left">
                                                                        {errors.RelativeDesignation}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </> : null}

                                                            <Form.Group as={Col} md="12" controlId="validationFormikHaveYouAppearedForInterview">
                                                                <Form.Label>Have you applied for Interview at RHFL before? *</Form.Label>
                                                                <Form.Check
                                                                    name="HaveYouAppearedForInterview"
                                                                    type="radio"
                                                                    id="HaveYouAppearedForInterviewradio1"
                                                                    label="Yes"
                                                                    value="Yes"
                                                                    onChange={handleChange}
                                                                    isValid={touched.HaveYouAppearedForInterview && !errors.HaveYouAppearedForInterview}
                                                                />
                                                                <Form.Check
                                                                    name="HaveYouAppearedForInterview"
                                                                    type="radio"
                                                                    id="HaveYouAppearedForInterviewradio2"
                                                                    label="No"
                                                                    value="No"
                                                                    defaultChecked
                                                                    onChange={handleChange}
                                                                    isValid={touched.HaveYouAppearedForInterview && !errors.HaveYouAppearedForInterview}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.HaveYouAppearedForInterview}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="3" controlId="formGridApplyingPosition">
                                                                <Form.Label style={{ textAlign: "left", width: "100%" }}>Applying Position *</Form.Label>
                                                                <Form.Control required as="select" name="ApplyingPosition" value={values.ApplyingPosition}
                                                                    onChange={handleChange}
                                                                    isValid={touched.ApplyingPosition && !errors.ApplyingPosition}
                                                                    isInvalid={!!errors.ApplyingPosition}
                                                                >
                                                                    <option value="">Select ApplyingPosition</option>
                                                                    <option value="Direct Marketing Executive">Direct Marketing Executive</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.ApplyingPosition}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="3" controlId="formGridPhoto">
                                                                <Form.Label>Upload RESUME (PDF / Doc) *</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name="RESUME"
                                                                    accept=".pdf,.docx,.doc"
                                                                    value={values.Photo}
                                                                    onChange={handleChange}
                                                                    isValid={touched.Photo && !errors.Photo}
                                                                    isInvalid={!!errors.Photo}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.Photo}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="12" controlId="validationFormikDeclaration">
                                                                <Form.Check
                                                                    name="Declaration"
                                                                    type="radio"
                                                                    id="Declarationradio1"
                                                                    label="I hereby solemnly declare and affirm that all the statements made in this Application are true to the best of my knowledge and belief. I understand that in the event of any information being found false, incorrect or incomplete or if I am found ineligible due to non-fulfilment of any of the eligibility conditions, my candidature for the applied post is liable to be cancelled/ rejected without assigning any reasons at any stage of recruitment and after appointment in that event, the company shall have complete rights to dismiss me from the services of the Company."
                                                                    value="Yes"
                                                                    onChange={handleChange}
                                                                    defaultChecked
                                                                    isValid={touched.Declaration && !errors.Declaration}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.Declaration}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridPlace">
                                                                <Form.Label>Place *</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Place"
                                                                    value={values.Place}
                                                                    onChange={handleChange}
                                                                    isValid={touched.Place && !errors.Place}
                                                                    isInvalid={!!errors.Place}
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.Place}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="3" controlId="formGridDate">
                                                                <Form.Label>Today's Date</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Date"
                                                                    value={values.Date}
                                                                    onChange={handleChange}
                                                                    isValid={touched.Date && !errors.Date}
                                                                    isInvalid={!!errors.Date}
                                                                    readOnly
                                                                />
                                                                <Form.Control.Feedback type="invalid" className="text-left">
                                                                    {errors.Date}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Button type="submit" disabled={getDisabledstatus4(values, errors)}>Submit Profile</Button>
                                                        <Button type="button" onClick={() => { setActiveAccordion("2"); console.log(ActiveAccordion) }}>Back</Button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                        </TabContent>
                    </Tab>
                    <Tab eventKey="archivedOpenings" title="Archives">
                        <TabContent className="ProductContent mt-3">
                            <div >
                                <CurrentOpenings type="Archives" changeTab={() => { changeTab() }} />
                            </div>
                        </TabContent>
                    </Tab>
                </Tabs>
            </Row>
            {/* <Row>
                {careerDetails.map((careers, index) => {
                    return (
                        <Col style={{ background: "white" }} className="col-12 col-sm-6 col-md-4 col-lg-4 my-5">
                            <div style={{ backgroundColor: "grey", borderRadius: "1rem" }}>
                                <a target="_blank" href={process.env.REACT_APP_BASE_URL + careers.Document.url}>
                                    <div style={{ backgroundColor: "lightgrey", borderRadius: "1rem" }}><img style={{ width: "15rem", borderRadius: "0.25rem" }} src={process.env.REACT_APP_BASE_URL + careers.DocumentPreview.url} /></div>
                                    <p className="mt-3" style={{ width: "50%", margin: "auto", color: "white" }}><b>{careers.DocumentName}</b></p>
                                </a>
                            </div>
                        </Col>
                    )
                })}
            </Row> */}
            <div className="OtherModal">
                <Modal show={showSuccess} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Your form has been Submitted successfully!, we will review and get back to you.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>} />
    </div >)
}
import Layout from "../Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import * as InvestorService from "../../services/services";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function TDSonDividend() {
    const [TDSonDividend, setTDSonDividend] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        InvestorService.getTDSonDividend().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setTDSonDividend(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <Link to="/investors" className="mr-2">
                        Investors
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        TDS on Dividend
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                TDS on Dividend
            </div>
            <div>
                <Row className="pill-row p-3 pt-4">
                    <Col>
                        {TDSonDividend.length > 0 ? TDSonDividend[0].TDSonDividend.length > 0 ? TDSonDividend[0].TDSonDividend.map((tds, index) => {
                            return (<div><a className="pdfHighlight-line-space text-left" target="_blank" href={process.env.REACT_APP_BASE_URL + tds.url}><p className="text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(tds.name).split(tds.ext)[0]}</p></a></div>)
                        }) : "" : ""}
                    </Col>
                </Row>
            </div>
        </div>} />
    </div >)
}
import Layout from "./Layout";
import * as FinancialService from "../services/services";
import { useEffect, useState } from "react";
import { Col, Row, Table, Tabs, Tab, TabContent, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/FinancialInformation.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

export function AuctionSaleNotices() {
    const [auctionNotices, setAuctionNotices] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        FinancialService.getAuctionSaleNotices().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setAuctionNotices(resp);
            }
        })
        return () => mounted = false;

    }, [])
    const convertToFormattedValue = (value) => {
        if (value != null && (value % 1 == 0)) {
            var totalFormatted = value.toString();
            var lastThree = totalFormatted.substring(totalFormatted.length - 3);
            var otherNumbers = totalFormatted.substring(0, totalFormatted.length - 3);
            if (otherNumbers !== '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            return (res)
        }
        else return value
    }

    return (<div><Layout mainContent={<div data-aos="fade-up" className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    Auction Sale Notices
                    </span>
            </p>
        </div>
        <div className="headings BranchNetworkHeading">Auction Sale Notices</div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
            <Row className="pill-row p-3"  style={{ textAlign:"left" }}>
                <Col sm={3} className="pill-column">
                    <Nav variant="pills" className="flex-column">
                        {auctionNotices ? auctionNotices.map((auctionNotice, index) => {
                            return (
                                <Nav.Item>
                                    <Nav.Link eventKey={index}>{auctionNotice.StateName}</Nav.Link>
                                </Nav.Item>
                            )
                        }) : ""}
                    </Nav>
                </Col>
                <Col sm={9}>
                    {auctionNotices ? auctionNotices.map((notice, index) => {

                        return (<Tab.Content>
                            {
                                notice.AuctionNotices.length > 0 ? notice.AuctionNotices.map((file) => {
                                    return (
                                        <Tab.Pane eventKey={index}>
                                            <a className="pdfHighlight" target="_blank" href={process.env.REACT_APP_BASE_URL + file.url}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(file.name).split('.').slice(0, -1).join('.')}</a>
                                        </Tab.Pane>
                                    )
                                }) : "No Information Available"
                            }
                        </Tab.Content>)
                    }) : ""}
                </Col>
            </Row>
        </Tab.Container>
    </div>} /></div>)
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/Footer.css";
import ListGroup from "react-bootstrap/ListGroup";
import * as RepcoDetailsService from "../services/services";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, repcoDetails: [], productList: [], KYCPolicy: "" };
    RepcoDetailsService.getRepcoDetails().then((resp) => {
      if (!resp) {
        return;
      }
      this.setState({ loading: false, repcoDetails: resp, KYCPolicy: resp[0].KYCPolicy ? resp[0].KYCPolicy.url : "" });
    });
  }
  render() {
    return (
      <div className="Footer-wrapper font-weight-bold">
        <div className="Base-wrapper">
          <Row className="footer">
            <Col className="text-left">
              <p className="headings FooterHeadings">
                Our Company
              </p>
              <ListGroup>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/about">About Us</Link></ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/products">Products</Link></ListGroup.Item>
                {/* <ListGroup.Item className="lower-mid-size-content">Investors</ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content">Media</ListGroup.Item> */}
                <ListGroup.Item className="lower-mid-size-content"><Link to="/careers">Careers</Link></ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/contact">Contact</Link></ListGroup.Item>
              </ListGroup>
            </Col>
            <Col className="text-left">
              <p className="headings FooterHeadings">
                Useful Links
              </p>
              <ListGroup>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/branches">Find A Branch</Link></ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/faqs">FAQs</Link></ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/policies-and-codes">Policies &amp; Codes</Link></ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/downloads">Downloads</Link></ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/gallery">Gallery</Link></ListGroup.Item>
                <ListGroup.Item className="lower-mid-size-content"><Link to="/grievances">Grievance Redressal</Link></ListGroup.Item>
              </ListGroup>
            </Col>
            <Col className="text-left">
              <p className="headings FooterHeadings">
                Our Calculators
              </p>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/emi-calculator">EMI Calculator</Link></ListGroup.Item>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/eligibility-calculator">Eligibility Calculator</Link></ListGroup.Item>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/other-calculators">Other Calculators</Link></ListGroup.Item>
              {/* <ListGroup.Item className="lower-mid-size-content"><Link to="/stamp-duty-calculator">Stamp Duty Calculator</Link></ListGroup.Item>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/area-conversion-calculator">Area Conversion Calculator</Link></ListGroup.Item>
              <ListGroup.Item className="lower-mid-size-content"><Link to="/guideline-values">Guideline Values</Link></ListGroup.Item> */}
            </Col>
            <Col className="text-left">
              <p className="headings FooterHeadings">Follow</p>
              <Row>
                <Col className="p-0">
                  <img onClick={() => window.open("https://www.facebook.com/RepcoHomeFinanceOfficial/")}
                    style={{ width: "1.25rem", marginRight: "1rem", cursor: "pointer" }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/uploads/facebook_circular_logo_ccc3504dd2.png"
                    }
                    alt="facebook_link"
                  />
                  <img onClick={() => window.open("https://twitter.com/RepcoHome/")}
                    style={{ width: "1.25rem", marginRight: "1rem", cursor: "pointer" }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/uploads/twitter_4bcd0065e2.png"
                    }
                    alt="twitter_link"
                  />
                  <img onClick={() => window.open("https://www.youtube.com/channel/UCk2rsuc5HpjlLNVZso4AaCg")}
                    style={{ width: "1.25rem", cursor: "pointer" }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/uploads/youtube_a870c0d71a.png"
                    }
                    alt="youtube_link"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="std-height mt-4">
            <Row className="FooterHeadings headings w-100">
              {this.state.repcoDetails.length > 0 ? (
                <p className="w-100">{this.state.repcoDetails[0].RepcoTitle}</p>
              ) : (
                <p className="w-100">Repco Home Finance Ltd.</p>
              )}
              <hr />
            </Row>
            <Row className="w-100 FooterHeading">
              {this.state.repcoDetails.length > 0 ? (
                <p className="w-100 lower-mid-size-content">
                  {this.state.repcoDetails[0].RepcoAddress}
                </p>
              ) : (
                <p className="w-100 lower-mid-size-content">
                  Alexander Square Third Floor, Old No: 34/35 New No: 2 Sardar
                  Patel Road, Guindy, Chennai - 600032.
                </p>
              )}
            </Row>
            <Row className="w-100">
              {this.state.repcoDetails.length > 0 ? (
                <div className="w-100">
                  <p className="w-100">
                    {"Phone: " +
                      this.state.repcoDetails[0].RepcoPhone +
                      " | Mobile: " +
                      this.state.repcoDetails[0].RepcoMobile +
                      " | Fax: " +
                      this.state.repcoDetails[0].RepcoFax
                    }
                  </p>
                  <p className="w-100">
                    {"E-mail: " +
                      this.state.repcoDetails[0].RepcoEmail +
                      " | Toll-free number: " +
                      this.state.repcoDetails[0].RepcoTollFreeNumber}
                  </p>
                </div>
              ) : (
                <div className="w-100">
                  <p className="w-100">
                    Phone: (044) - 42106650 / 42106652 | Mobile: 9444394918 | Fax:
                    (044) - 42106651
                  </p>
                  <p className="w-100">
                    E-mail: co@repcohome.com | Toll-free
                    number: 1800-425-6070</p>
                </div>
              )}
            </Row>
          </Row>
        </div>
        <div className="pt-2 pb-2" style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          background: "#E31A52",
          color: "white"
        }}>
          <p className="m-0">Copyright © 2021 Repco Home Finance. All Rights Reserved.</p>
          <p className="m-0">Designed By <a className="text-white" href="https://www.imaginetventures.com/" target="_blank">ImagiNET Ventures</a></p>
        </div>
      </div >
    );
  }
}

export default Footer;

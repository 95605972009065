import React, { Component } from "react";
import { yearlyAmortizationSchedule, amortizationSchedule } from 'amortization';
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import "../styles/HomeLoanCalcComponent.css";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Doughnut, Pie, Polar, HorizontalBar } from "react-chartjs-2";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);

class EligibilityCalcComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyIncome: 10000,
      monthlyIncomeMin: 10000,
      monthlyIncomeMax: 10000000,
      monthlyIncomeValidation: false,
      tenure: 1,
      tenureMin: 1,
      tenureMax: 25,
      tenureValidation: false,
      interestRate: 5,
      interestRateMin: 5,
      interestRateMax: 15,
      interestRateValidation: false,
      otherEMIs: 0,
      otherEMIsMin: 0,
      otherEMIsMax: 10000,
      otherEMIsValidation: false,
      eligibleEMI: 0,
      loanEligibilityPrincipalAmount: 0,
      interestPayable: 0,
      amortizationDetails: [],
      monthlyAmortizationDetails: [],
      currentYearsMonthlyAmortizationDetails: [],
      currentYear: 2021,
      monthNumber: parseInt(moment().format("M"))
    };

  }

  convertToFormattedValue = (value) => {
    var totalFormatted = value.toString();
    var lastThree = totalFormatted.substring(totalFormatted.length - 3);
    var otherNumbers = totalFormatted.substring(0, totalFormatted.length - 3);
    if (otherNumbers !== '')
      lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return (res)
  }

  onMonthlyAmountChange = value => {
    console.log(value)
    if ((value) >= this.state.monthlyIncomeMin) {
      if ((value) <= (this.state.monthlyIncomeMax)) {
        this.setState({ monthlyIncomeValidation: false })
      }
      else {
        this.setState({ monthlyIncomeValidation: true })
      }
    }
    else {
      this.setState({ monthlyIncomeValidation: true })
    }
    this.setState({ monthlyIncome: value })
    this.setState({ otherEMIsMax: value })
    this.setState({ otherEMIs: 0 })
  };

  getMonthlyIncome = (e) => {
    console.log(e.target.validity.valid)
    const total = (e.target.validity.valid) ?
      e.target.value : this.state.monthlyIncome
    console.log(total)
    if ((total) >= this.state.monthlyIncomeMin) {
      if ((total) <= (this.state.monthlyIncomeMax)) {
        this.setState({ monthlyIncomeValidation: false })
        this.setState({ otherEMIsMax: total })
        this.setState({ monthlyIncome: total })
        this.setState({ otherEMIs: 0 })
      }
      else {
        this.setState({ monthlyIncomeValidation: true })
        this.setState({ monthlyIncome: total })
        this.setState({ otherEMIsMax: total })
        this.setState({ otherEMIs: 0 })
      }
    }
    else {
      this.setState({ monthlyIncomeValidation: true })
      this.setState({ monthlyIncome: total })
      this.setState({ otherEMIsMax: total })
      this.setState({ otherEMIs: 0 })
    }
  }

  onTenureChange = value => {
    console.log(value)
    if ((value) >= this.state.tenureMin) {
      if ((value) <= (this.state.tenureMax)) {
        this.setState({ tenureValidation: false })
      }
      else {
        this.setState({ tenureValidation: true })
      }
    }
    else {
      this.setState({ tenureValidation: true })
    }
    this.setState({ tenure: value })
  };

  getTenure = (e) => {
    console.log(e.target.validity.valid)
    const total = (e.target.validity.valid) ?
      e.target.value : this.state.tenure
    console.log(total)
    if ((total) >= this.state.tenureMin) {
      if ((total) <= (this.state.tenureMax)) {
        this.setState({ tenureValidation: false })
        this.setState({ tenure: total })
      }
      else {
        this.setState({ tenureValidation: true })
        this.setState({ tenure: total })
      }
    }
    else {
      this.setState({ tenureValidation: true })
      this.setState({ tenure: total })
    }
  }

  onInterestRateChange = value => {
    console.log(value)
    if ((value) >= this.state.interestRateMin) {
      if ((value) <= (this.state.interestRateMax)) {
        this.setState({ interestRateValidation: false })
      }
      else {
        this.setState({ interestRateValidation: true })
      }
    }
    else {
      this.setState({ interestRateValidation: true })
    }
    this.setState({ interestRate: value })
  };

  getInterestRate = (e) => {
    console.log(e.target.validity.valid)
    const total = (e.target.validity.valid) ?
      e.target.value : this.state.interestRate
    console.log(total)
    if ((total) >= this.state.interestRateMin) {
      if ((total) <= (this.state.interestRateMax)) {
        this.setState({ interestRateValidation: false })
        this.setState({ interestRate: total })
      }
      else {
        this.setState({ interestRateValidation: true })
        this.setState({ interestRate: total })
      }
    }
    else {
      this.setState({ interestRateValidation: true })
      this.setState({ interestRate: total })
    }
  }

  onotherEMIsChange = value => {
    console.log(value)
    if ((value) >= this.state.otherEMIsMin) {
      if ((value) <= (this.state.otherEMIsMax)) {
        this.setState({ otherEMIsValidation: false })
      }
      else {
        this.setState({ otherEMIsValidation: true })
      }
    }
    else {
      this.setState({ otherEMIsValidation: true })
    }
    this.setState({ otherEMIs: value })
  };

  getotherEMIs = (e) => {
    console.log(e.target.validity.valid)
    const total = (e.target.validity.valid) ?
      e.target.value : this.state.otherEMIs
    console.log(total)
    if ((total) >= this.state.otherEMIsMin) {
      if ((total) <= (this.state.otherEMIsMax)) {
        this.setState({ otherEMIsValidation: false })
        this.setState({ otherEMIs: total })
      }
      else {
        this.setState({ otherEMIsValidation: true })
        this.setState({ otherEMIs: total })
      }
    }
    else {
      this.setState({ otherEMIsValidation: true })
      this.setState({ otherEMIs: total })
    }
  }
  getOtherEMIMaxValue() {
    if (this.state.monthlyIncome <= 20000) {
      return ((this.state.monthlyIncome / 100) * 40)
    }
    else if (this.state.monthlyIncome > 20000 && this.state.monthlyIncome <= 50000) {
      return ((this.state.monthlyIncome / 100) * 50)
    }
    else {
      return ((this.state.monthlyIncome / 100) * 60)
    }
  }
  getCalculatedEMI() {
    let totalThreshold = this.getOtherEMIMaxValue() - this.state.otherEMIs;
    if (totalThreshold > 0) {
      this.setState({ eligibleEMI: totalThreshold })
      // TOTAL AMOUNT
      var emi = totalThreshold;
      var term = this.state.tenure * 12;
      var intr = this.state.interestRate / 1200;
      var princ = Math.round(emi * (1 - (Math.pow(1 / (1 + intr), term))) / intr)
      this.setState({ interestPayable: (emi * term) })
      this.setState({ loanEligibilityPrincipalAmount: Math.abs(Math.round((emi * term) * 1 - princ * 1) - (emi * term)) })
      this.setState({ amortizationDetails: yearlyAmortizationSchedule((Math.abs(Math.round((emi * term) * 1 - princ * 1) - (emi * term))), this.state.tenure + 1, this.state.interestRate) })
      this.setState({ monthlyAmortizationDetails: amortizationSchedule((Math.abs(Math.round((emi * term) * 1 - princ * 1) - (emi * term))), this.state.tenure + 1, this.state.interestRate) })
      console.log(Math.abs(Math.round((emi * term) * 1 - princ * 1) - (emi * term)), this.state.tenure + 1, this.state.interestRate)
      return (totalThreshold)
    }
    else {
      this.setState({ eligibleEMI: "Not Eligible" })
      this.setState({ loanEligibilityPrincipalAmount: "Not Eligible" })
      this.setState({ interestPayable: "Not Eligible" })
      this.setState({ amortizationDetails: [] })
      return "Not Eligible for Loan"
    }
  }
  render() {
    const handleClose = () => this.setState({ showMonthModal: false });
    const setMonthAmortization = (index) => {
      console.log(index)
      let monthAmortization = [];
      this.setState({ currentYear: new Date().getFullYear() + index }, console.log(this.state.currentYear))
      this.setState({ currentYearsMonthlyAmortizationDetails: monthAmortization }, () => { console.log(this.state.currentYearsMonthlyAmortizationDetails) })
      for (let i = index * 12; i < (index + 1) * 12; i++) {
        monthAmortization.push(this.state.monthlyAmortizationDetails[i])
        console.log(this.state.monthlyAmortizationDetails[index], index)
      }
      this.setState({ currentYearsMonthlyAmortizationDetails: monthAmortization }, () => { console.log(this.state.currentYearsMonthlyAmortizationDetails) })
      this.setState({ showMonthModal: true }, () => { console.log(this.state.showMonthModal) })
    }
    const convertToFormattedValue = (value) => {
      var totalFormatted = value.toString();
      var lastThree = totalFormatted.substring(totalFormatted.length - 3);
      var otherNumbers = totalFormatted.substring(0, totalFormatted.length - 3);
      if (otherNumbers !== '')
        lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return (res)
    }
    const highChartOptions = {
      colors: ["lightseagreen", "#FFF515", "#FF5852"], // Brown Yellow
      // colors: ["#F7B7A3", "#2B0B3F", "#EA5F89"], // Brown Yellow
      colors: ["#EC6B5680", "#fdb515", "#ec1852"], // Brown Yellow
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 25,
          beta: 0
        },
        events: {
          load: function () {
            var renderer = this.renderer

            this.series[0].points.forEach((point, i) => {
              var marker = renderer.createElement('marker').add(renderer.defs).attr({
                markerWidth: 10,
                markerHeight: 10,
                refX: 5,
                refY: 5,
                orient: 'auto',
                id: `connector-marker-${i}`
              })

              renderer.circle(5, 5, 5).add(marker).attr({
                fill: point.color
              })

              point.connector.attr({
                'marker-start': `url(#connector-marker-${i})`
              })
            })
          }
        }
      },
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      },
      credits: {
        enabled: false
      },
      title: {
        text: null,
        // style: {
        //   fontFamily: '"Montserrat", sans-serif'
        // }
      },
      tooltip: {
        crosshairs: false,
        shared: true,
        valuePrefix: '₹',
        borderColor: '#651FFF',
        formatter: function () {
          return '<b>' + (this.point ? this.point.name : "") + '</b>: ' + convertToFormattedValue(this.point.y) + ' ₹';
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 60,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: { fontFamily: "'Montserrat', sans-serif", lineHeight: '18px', fontSize: '1rem' }
          }
        },
      },
      legend: {
        floating: true,
      },
      series: [{
        showInLegend: false,
        type: "pie",
        dataLabels: {
          enabled: true,
          connectorShape: 'crookedLine',
          crookDistance: '95%',
          // alignTo: 'plotEdges'
        },
        data: [["Loan Amount", parseInt(this.state.loanEligibilityPrincipalAmount)], ["Interest Rate", parseInt(this.state.interestPayable - this.state.loanEligibilityPrincipalAmount)], ["Total Amount", parseInt(this.state.interestPayable)]],
      }]
    }
    return (
      <div data- aos="fade-up" >
        <Modal show={this.state.showMonthModal} onHide={handleClose} centered size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Monthly Amortization for the year {this.state.currentYear}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "50vh", overflow: "auto" }}>
            <Table responsive bordered>
              <thead className="text-left">
                <tr>
                  <th style={{ color: "#E31A52", fontWeight: "600" }}>Month</th>
                  <th style={{ color: "#E31A52", fontWeight: "600" }}>Principal Payment</th>
                  <th style={{ color: "#E31A52", fontWeight: "600" }}>Interest Payment</th>
                  <th style={{ color: "#E31A52", fontWeight: "600" }}>Amount to be paid every Month</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {this.state.currentYearsMonthlyAmortizationDetails.length > 0 ? this.state.currentYearsMonthlyAmortizationDetails.map((amortization, index) => {
                  return (<>
                    {
                      this.state.currentYear == moment().format("YYYY") ?
                        parseInt(moment().format("M")) + index <= 12 ?
                          <tr key={amortization.paymentNumber + parseInt(moment().format("M"))}>
                            <td>{moment(parseInt(moment().format("M")) + index, 'MM').format('MMMM')}</td>
                            <td>{convertToFormattedValue(this.state.currentYearsMonthlyAmortizationDetails[(12 - this.state.monthNumber) + index].principalPaymentRounded.toFixed(0))}</td>
                            <td>{convertToFormattedValue(this.state.currentYearsMonthlyAmortizationDetails[(12 - this.state.monthNumber) + index].interestPaymentRounded.toFixed(0))}</td>
                            <td>{convertToFormattedValue(this.state.currentYearsMonthlyAmortizationDetails[(12 - this.state.monthNumber) + index].payment.toFixed(0))}</td>
                          </tr> : null
                        :
                        this.state.currentYear == parseInt(moment().format("YYYY")) + (this.state.tenure) ?
                          index < (parseInt(moment().format("M")) > 2 ? parseInt(moment().format("M")) - 1 : 1) ?
                            < tr key={amortization.paymentNumber}>
                              <td>{moment(index + 1, 'MM').format('MMMM')}</td>
                              <td>{convertToFormattedValue(amortization.principalPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(amortization.interestPaymentRounded.toFixed(0))}</td>
                              <td>{convertToFormattedValue(amortization.payment.toFixed(0))}</td>
                            </tr> : null
                          :
                          < tr key={amortization.paymentNumber}>
                            <td>{moment(index + 1, 'MM').format('MMMM')}</td>
                            <td>{convertToFormattedValue(amortization.principalPaymentRounded.toFixed(0))}</td>
                            <td>{convertToFormattedValue(amortization.interestPaymentRounded.toFixed(0))}</td>
                            <td>{convertToFormattedValue(amortization.payment.toFixed(0))}</td>
                          </tr>
                    }
                  </>
                  )
                }) : ""}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="Base-wrapper CalculatorModule">
          < p className="headings mb-1 mb-sm-1 mb-md-3 mb-lg-0" style={{ color: "rgb(227, 26, 82)" }}>Calculate Loan Eligibility</p>
          <Row>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 EmiCalculatorSliders">
              <div className="mb-3">
                <p className="CalculatorHeadings lower-mid-size-content">Monthly Salary</p>
                <Row>
                  <Col className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <Slider
                      min={this.state.monthlyIncomeMin}
                      max={this.state.monthlyIncomeMax}
                      value={this.state.monthlyIncome}
                      step={10000}
                      marks={{
                        10000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>10 K</strong>
                        },
                        2000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>20 L</strong>
                        },
                        4000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>40 L</strong>
                        },
                        6000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>60 L</strong>
                        },
                        8000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>80 L</strong>
                        },
                        10000000: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>1 C</strong>
                        },
                      }}
                      onChange={this.onMonthlyAmountChange}
                      railStyle={{ backgroundColor: 'rgb(227, 26, 82)', height: 10 }}
                      trackStyle={{ backgroundColor: '#fdb515', height: 10 }}
                      handleStyle={{
                        borderColor: 'black',
                        borderRadius: '0.25rem',
                        height: 28,
                        width: 28,
                        marginTop: -9,
                        backgroundColor: 'rgb(253, 181, 21)',
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-2 col-lg-2 pr-0 mt-3 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mb-0 CalculatorValueCol">
                    <input className="LoanAmount small-size-content" type="tel" pattern="[0-9]*"
                      onInput={this.getMonthlyIncome.bind(this)} value={this.state.monthlyIncome} />
                    <p className="CalculatorNormalLabelLeft" style={{ float: "right" }}><FontAwesomeIcon
                      icon={faRupeeSign}
                    />
                    </p>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {this.state.monthlyIncomeValidation ? <span className="mt-1" style={{ float: "right", color: 'red' }}>Enter monthly salary between 10,000 and 1 Crore</span> : ""}
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 EmiCalculatorSliders">
              <div className="mb-3">
                <p className="CalculatorHeadings lower-mid-size-content">Tenure</p>
                <Row>
                  <Col className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <Slider
                      min={this.state.tenureMin}
                      max={this.state.tenureMax}
                      value={this.state.tenure}
                      step={1}
                      marks={{

                        1: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            width: "fit-content",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>1 Yr</strong>,
                        },
                        5: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>5 Yrs</strong>
                        },
                        10: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>10 Yrs</strong>,
                        },
                        15: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>15 Yrs</strong>,
                        },
                        20: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>20 Yrs</strong>,
                        },
                        25: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem",
                            width: "fit-content"
                          },
                          label: <strong>25 Yrs</strong>,
                        },
                      }}
                      onChange={this.onTenureChange}
                      railStyle={{ backgroundColor: 'rgb(227, 26, 82)', height: 10 }}
                      trackStyle={{ backgroundColor: '#fdb515', height: 10 }}
                      handleStyle={{
                        borderColor: 'black',
                        borderRadius: '0.25rem',
                        height: 28,
                        width: 28,
                        marginTop: -9,
                        backgroundColor: 'rgb(253, 181, 21)',
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-2 col-lg-2 pr-0 mt-3 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mb-0 CalculatorValueCol">
                    <p className="CalculatorNormalLabels mr-3">Years</p><input className="LoanTenure" type="tel" pattern="[0-9]*"
                      onInput={this.getTenure.bind(this)} value={this.state.tenure} />
                  </Col>
                </Row>
                <Row className="mb-4">
                  {this.state.tenureValidation ? <span className="mt-1" style={{ float: "right", color: 'red' }}>Enter tenure between 1 and 25 Years</span> : ""}
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 EmiCalculatorSliders">
              <div className="mb-3">
                <p className="CalculatorHeadings lower-mid-size-content">Interest Rate (% P.A)</p>
                <Row>
                  <Col className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <Slider
                      min={this.state.interestRateMin}
                      max={this.state.interestRateMax}
                      value={this.state.interestRate}
                      onChange={this.onInterestRateChange}
                      step={0.5}
                      marks={{
                        5: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          }, label: <strong>5%</strong>
                        },
                        8.5: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>8.5%</strong>,
                        },
                        15: {
                          style: {
                            color: "black",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            borderRadius: "0.25rem"
                          },
                          label: <strong>15%</strong>,
                        },
                      }}
                      railStyle={{ backgroundColor: 'rgb(227, 26, 82)', height: 10 }}
                      trackStyle={{ backgroundColor: '#fdb515', height: 10 }}
                      handleStyle={{
                        borderColor: 'black',
                        borderRadius: '0.25rem',
                        height: 28,
                        width: 28,
                        marginTop: -9,
                        backgroundColor: 'rgb(253, 181, 21)',
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-2 col-lg-2 pr-0 mt-3 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mb-0 CalculatorValueCol">
                    <input className="LoanAmount small-size-content" type="number" step="0.5" pattern="[0-9]*"
                      onInput={this.getInterestRate.bind(this)} value={this.state.interestRate} />
                    <p className="CalculatorNormalLabelLeft" style={{ float: "right" }}>
                      %
                    </p>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {this.state.interestRateValidation ? <span className="mt-1" style={{ float: "right", color: 'red' }}>Enter Interest Rate between 1% and 15%</span> : ""}
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 EmiCalculatorSliders">
              <div className="mb-3">
                <p className="CalculatorHeadings lower-mid-size-content">Other EMIs (Monthly)</p>
                <Row>
                  <Col className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <Slider
                      min={this.state.otherEMIsMin}
                      max={this.getOtherEMIMaxValue()}
                      value={this.state.otherEMIs}
                      onChange={this.onotherEMIsChange}
                      railStyle={{ backgroundColor: 'rgb(227, 26, 82)', height: 10 }}
                      trackStyle={{ backgroundColor: '#fdb515', height: 10 }}
                      handleStyle={{
                        borderColor: 'black',
                        borderRadius: '0.25rem',
                        height: 28,
                        width: 28,
                        marginTop: -9,
                        backgroundColor: 'rgb(253, 181, 21)',
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-2 col-lg-2 pr-0 mt-3 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mb-0 CalculatorValueCol">
                    <input className="LoanAmount small-size-content" type="tel" pattern="[0-9]*"
                      onInput={this.getotherEMIs.bind(this)} value={this.state.otherEMIs} />
                    <p className="CalculatorNormalLabelLeft" style={{ float: "right" }}>
                      <FontAwesomeIcon
                        icon={faRupeeSign}
                      />
                    </p>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {this.state.otherEMIsValidation ? <span className="mt-1" style={{ float: "right", color: 'red' }}>Enter Other EMI between 0 and {this.state.monthlyIncome}</span> : ""}
                </Row>
              </div>
            </Col>
            <button className="mb-5" onClick={() => this.getCalculatedEMI()} style={{ background: "#E31A52", color: "white", paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem", paddingBottom: "0.5rem", borderRadius: "0.25rem", border: "none" }}>Calculate</button>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 EmiCalculatorSliders">
              <Row>
                <Col className="col-12 col-sm-12 col-md-12 col-lg-8 p-0">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={highChartOptions}
                  />
                </Col>
                <Col className="col-12 col-sm-12 col-md-12 col-lg-4 p-0 mt-lg-5">
                  <div className="mb-3">
                    <p className="font-weight-bold lower-mid-size-content text-md-right text-lg-right">Eligible EMI (Monthly)</p>
                    <Row>
                      <p className="w-100 font-weight-bold text-md-right text-lg-right" style={{ textAlign: "right!important" }}>
                        {this.state.eligibleEMI == 'Not Eligible' ? null : <FontAwesomeIcon
                          icon={faRupeeSign}
                        />}&nbsp;
                        {this.state.eligibleEMI == 'Not Eligible' ? "Not Eligible" : this.convertToFormattedValue(this.state.eligibleEMI)}
                      </p>
                    </Row>
                  </div>
                  <div className="mb-3">
                    <p className="font-weight-bold lower-mid-size-content text-md-right text-lg-right">Eligible Loan Amount</p>
                    <Row>
                      <p className="w-100 font-weight-bold text-md-right text-lg-right">
                        {this.state.loanEligibilityPrincipalAmount == 'Not Eligible' ? null : <FontAwesomeIcon
                          icon={faRupeeSign}
                        />}&nbsp;
                        {this.state.loanEligibilityPrincipalAmount == 'Not Eligible' ? "Not Eligible" : this.convertToFormattedValue(this.state.loanEligibilityPrincipalAmount)}
                      </p>
                    </Row>
                  </div>
                  <div className="mb-3">
                    <p className="font-weight-bold lower-mid-size-content text-md-right text-lg-right">Interest Payable</p>
                    <Row>
                      <p className="w-100 font-weight-bold text-md-right text-lg-right" style={{ textAlign: "right!important" }}>
                        {this.state.interestPayable == 'Not Eligible' ? null : <FontAwesomeIcon
                          icon={faRupeeSign}
                        />}&nbsp;
                        {this.state.interestPayable == 'Not Eligible' ? "Not Eligible" : this.convertToFormattedValue(this.state.interestPayable - this.state.loanEligibilityPrincipalAmount)}
                      </p>
                    </Row>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
          {this.state.amortizationDetails.length > 0 ? <Table responsive bordered className="mt-5">
            <thead className="text-left">
              <tr>
                <th style={{ color: "#E31A52" }}>No</th>
                <th style={{ color: "#E31A52" }}>Principal Payment</th>
                <th style={{ color: "#E31A52" }}>Interest Payment</th>
                <th style={{ color: "#E31A52" }}>Amount to be paid every Year</th>
                <th style={{ color: "#E31A52" }}>Year</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {this.state.amortizationDetails.length > 0 ? this.state.amortizationDetails.map((amortization, index) => {
                return (
                  <>
                    {
                      index == 0 ?
                        <tr key={amortization.paymentNumber} style={{ cursor: "pointer" }} onClick={() => { setMonthAmortization(index) }}>
                          <td>{amortization.paymentNumber}</td>
                          <td>{convertToFormattedValue((amortization.principalPaymentRounded.toFixed(0) - (amortization.principalPaymentRounded.toFixed(0) / 12) * (parseInt(moment().format("M")) - 1)).toFixed(0))}</td>
                          <td>{convertToFormattedValue((amortization.interestPaymentRounded.toFixed(0) - (amortization.interestPaymentRounded.toFixed(0) / 12) * (parseInt(moment().format("M")) - 1)).toFixed(0))}</td>
                          <td>{convertToFormattedValue((amortization.paymentRounded.toFixed(0) - (amortization.paymentRounded.toFixed(0) / 12) * (parseInt(moment().format("M")) - 1)).toFixed(0))}</td>
                          <td>{new Date().getFullYear() + index}</td>
                        </tr>
                        :
                        index == this.state.amortizationDetails.length - 1 ?
                          <tr key={amortization.paymentNumber} style={{ cursor: "pointer" }} onClick={() => { setMonthAmortization(index) }}>
                            <td>{amortization.paymentNumber}</td>
                            <td>{convertToFormattedValue((amortization.principalPaymentRounded.toFixed(0) - (amortization.principalPaymentRounded.toFixed(0) / 12) * (12 - parseInt(moment().format("M")) + 1)).toFixed(0))}</td>
                            <td>{convertToFormattedValue((amortization.interestPaymentRounded.toFixed(0) - (amortization.interestPaymentRounded.toFixed(0) / 12) * (12 - parseInt(moment().format("M")) + 1)).toFixed(0))}</td>
                            <td>{convertToFormattedValue((amortization.paymentRounded.toFixed(0) - (amortization.paymentRounded.toFixed(0) / 12) * (12 - parseInt(moment().format("M")) + 1)).toFixed(0))}</td>
                            <td>{new Date().getFullYear() + index}</td>
                          </tr>
                          :
                          <tr key={amortization.paymentNumber} style={{ cursor: "pointer" }} onClick={() => { setMonthAmortization(index) }}>
                            <td>{amortization.paymentNumber}</td>
                            <td>{convertToFormattedValue(amortization.principalPaymentRounded.toFixed(0))}</td>
                            <td>{convertToFormattedValue(amortization.interestPaymentRounded.toFixed(0))}</td>
                            <td>{convertToFormattedValue(amortization.paymentRounded.toFixed(0))}</td>
                            <td>{new Date().getFullYear() + index}</td>
                          </tr>
                    }
                  </>
                )
              }) : ""}
            </tbody>
          </Table> : null}
        </div>
      </div >
    );
  }
}

export default EligibilityCalcComponent;

import Layout from "../Layout";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/FinancialInformation.css"

export function InvestorHomePage(props) {
    const goToInvestorCalendar = () => {
        props.history.push("/investors/investor-calendar");
    }
    const goToInvestorFAQ = () => {
        props.history.push("/investors/investor-faq");
    }
    const goToInvestorContacts = () => {
        props.history.push("/investors/investor-contact");
    }
    const goToUnclaimedDividend = () => {
        props.history.push("/investors/unclaimed-dividend");
    }
    const goToTDSonDividend = () => {
        props.history.push("/investors/tds-dividend");
    }
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        return () => mounted = false;

    }, [])


    return (<div><Layout mainContent={<div data-aos="fade-up" className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    Investor Information
                    </span>
            </p>
        </div>
        <div className="headings BranchNetworkHeading">Investor Information</div>
        <Row>
            <Col onClick={() => goToInvestorCalendar()} className="col-12 col-sm-3 col-md-3 col-lg-3 m-auto" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem",  borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                Investor Calendar
            </Col>
            <Col onClick={() => goToInvestorFAQ()} className="col-12 col-sm-3 col-md-3 col-lg-3 m-auto" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                Investor FAQ
            </Col>
            <Col onClick={() => goToInvestorContacts()} className="col-12 col-sm-3 col-md-3 col-lg-3 m-auto" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                Investor Contact
            </Col>
        </Row>
        <Row className="mt-5">
            <Col onClick={() => goToUnclaimedDividend()} className="col-12 col-sm-3 col-md-3 col-lg-3 m-auto" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                Unclaimed Dividend
            </Col>
            <Col onClick={() => goToTDSonDividend()} className="col-12 col-sm-3 col-md-3 col-lg-3 m-auto" style={{ background: "#e31a52", paddingTop: "6rem", paddingBottom: "6rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold", cursor: "pointer" }}>
                TDS on Dividend
            </Col>
            <Col className="d-none d-md-block d-lg-block col-12 col-sm-3 col-md-3 col-lg-3 m-auto" style={{ paddingTop: "6rem", paddingBottom: "6rem", borderRadius: "0.25rem", color: "white", fontWeight: "bold" }}>
            </Col>
        </Row>
    </div>} />
    </div>)
}
import Layout from "./Layout";
import { useEffect, useState } from "react";
import EligibilityCalcComponent from "../components/EligibilityCalcComponent";
import { Link } from "react-router-dom";

export function EligibilityCalculator() {
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Eligibility Calculator
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Eligibility Calculator
            </div>
            <EligibilityCalcComponent />
        </div>} />
    </div >)
}
import React, { Component } from "react";
import Layout from "./Layout";
import * as ProductService from "../services/services";
import { Card, Row, Col } from "react-bootstrap";
import "../styles/Products.css";
import { Link } from "react-router-dom";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, productList: [] };
    ProductService.getProductList().then((resp) => {
      if (!resp) {
        return;
      }
      this.setState({
        loading: false,
        productList: resp.sort((a, b) => {
          return a.ProductOrder - b.ProductOrder;
        }),
      });
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  gotoProduct = (productId) => {
    this.props.history.push("/products/" + productId);
  };
  render() {
    return (
      <div className="ProductList-Wrapper">
        {/* <div className="Base-wrapper"> */}
        <Layout
          mainContent={
            <div className="Base-wrapper" data-aos="fade-up">
              <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                  }}
                >
                  <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    Products
                    </span>
                </p>
              </div>
              <div className="Productsheading">
                <div
                  className="headings BranchNetworkHeading mt-4"
                >Products</div>
                <Row>
                  {this.state.productList.map((products, index) => {
                    return (
                      <Col
                        className="col-12 col-sm-6 col-md-4 col-lg-4 mt-2 product-list-card"
                        key={products.id}
                        onClick={() => this.gotoProduct(products.ProductId)}
                      >
                        <Card className="text-dark card-image-size cursor-pointer product-list-card">
                          <Card.ImgOverlay>
                            <Card.Title className="productListTitle mt-3">
                              {products.ProductName}
                            </Card.Title>
                          </Card.ImgOverlay>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default ProductList;

import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import Layout from "./Layout";
import * as branchService from "../services/services";
import { Link } from "react-router-dom";
import { Col, Dropdown, Form, FormControl, Row, Card } from "react-bootstrap";

class SatCenterList extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, SATList: [], stateList: [], branchList: [], stateName: "", branchName: "", selectedBranch: null, selectedSATCenter: {} };
    this.SATCenterList();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  stateValue = (e) => {
    this.setState({ stateName: e.target.value });
  };
  branchValue = (e) => {
    this.setState({ branchName: e.target.value });
  };
  SATCenterList = (e) => {
    branchService.getSATCenter().then((res) => {
      if (!res) {
        return;
      }
      this.setState({ SATList: res }, () => { console.log(this.state.SATList) });
    });
  };
  setSatCenterList(event) {
    console.log(event.target.value);
    this.state.SATList.forEach((satcentre, index) => {
      if (satcentre.id == event.target.value) {
        console.log(satcentre)
        this.setState({ selectedSATCenter: satcentre }, () => { console.log(this.state.selectedSATCenter) })
      }
    })
  }
  filterSlashes = (content) => {
    let contentreturn = ""
    contentreturn = content.replace(/\\r/g, " ")
    contentreturn = contentreturn.replace(/\\n/g, " ")
    return contentreturn
  }
  render() {
    return (
      <div className="globalTopMargin">
        <Layout
          mainContent={
            <div className="Base-wrapper">
              <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                  }}
                >
                  <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    Sat Centers
                  </span>
                </p>
              </div>
              <div
                className="headings BranchNetworkHeading"
              >
                Sat Centers
              </div>
              <div>

                <Form>
                  <Row className="m-auto branch-custom-row">
                    <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-8">
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" onChange={this.setSatCenterList.bind(this)}>
                          <option>Select SAT Centers</option>
                          {this.state.SATList.map((list, index) => {
                            return (
                              <option key={list.id} value={list.id}>{list.SatCentreName}</option>
                            )
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <Row className="mt-5">
                  {this.state.selectedSATCenter.SatCentreName ? <Col className="m-auto col-12 col-sm-12 col-md-12 col-lg-6">
                    <Card className="py-5 h-100" style={{ boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px" }}><p className="font-tint mid-size-content mb-4 mt-0">Sat-Center Details</p>
                      <p className="text-left mx-5"><div className="font-tint font-weight-bold">Sat-Center Name - </div>{this.state.selectedSATCenter.SatCentreName}</p>
                      <p className="text-left mx-5" dangerouslySetInnerHTML={{ __html: "<div class='font-tint font-weight-bold'>Address - </div>" + this.state.selectedSATCenter.SatCentreAddress }}></p>
                      <p className="text-left mx-5"><div className="font-tint font-weight-bold">Sat-Center Incharge - </div>{this.state.selectedSATCenter.SatCentreIncharge}</p>
                      <p className="text-left mx-5"><div className="font-tint font-weight-bold">Sat-Center Contact - </div>{this.state.selectedSATCenter.SatCentreContactNumber}</p>
                    </Card>
                  </Col> : <p className="w-100 text-center">Choose a Sat Center List to View the details</p>}
                </Row>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
export default SatCenterList;

import Layout from "./Layout";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import * as GalleryService from "../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight, faHome } from "@fortawesome/free-solid-svg-icons";
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import "../styles/Gallery.css"
import { EnquiryForm } from "../components/EnquiryForm";
import { Gallery as RPGallery, CustomGallery, Item, DefaultLayout } from 'react-photoswipe-gallery'
import React from "react";


export function Gallery() {

    const [show, setShow] = useState(false);
    const [albumName, setAlbumName] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);
    const [enquiryForm, setEnquiryForm] = useState(false);
    const [homepage, setHomePage] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (name, url) => {
        setAlbumName(name);
        setImageUrl(url);
        setShow(true)
    };

    const [galleryAlbums, setGalleryAlbums] = useState([]);

    const toggleEnquiryForm = () => {
        setEnquiryForm(!enquiryForm)
    }

    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        GalleryService.getGalleryAlbums().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setGalleryAlbums(resp);
            }
        })
        return () => mounted = false;
    }, [])

    const layoutRef = useRef()


    return (<div>
        <Layout mainContent={
            <>
                <div className="Base-wrapper" data-aos="fade-up">
                    <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                        <p
                            style={{
                                width: "100%",
                                textAlign: "left",
                                margin: "auto",
                                marginTop: "0rem",
                            }}
                        >
                            <Link to="/" className="mr-2">
                                Home
                            </Link>{" "}
                            &#10095;
                            <span className="ml-2">
                                Gallery
                            </span>
                        </p>
                    </div>
                    <div className="headings BranchNetworkHeading mt-4">
                        Gallery
                    </div>
                </div>
                <div className="sticky-left" data-aos="fade-right">
                    <ul className="social-left">
                        {!homepage ? <Link to="/home"><li className="home-left">
                            <FontAwesomeIcon style={{ width: "1.15rem" }} icon={faHome} />
                        </li></Link> : ""}
                        <li className={!homepage ? "email-left" : "email-left-others"} onClick={toggleEnquiryForm}>
                            <p className="enquire">Enquire now!</p>
                            {/* <a href="#"> */}
                            <img
                                style={{ width: "1.15rem" }}
                                src={
                                    process.env.REACT_APP_BASE_URL +
                                    "/uploads/email_a420f3db5c.png"
                                }
                                alt="email_link"
                            />
                            {/* </a> */}
                        </li>
                    </ul>
                    {enquiryForm ? <EnquiryForm closeEnquiryForm={enquiryForm} checkToggleStatus={toggleEnquiryForm} /> : ""}
                </div>

                <CustomGallery data-aos="fade-down" options={{ closeOnScroll: false, closeOnVerticalDrag: false, modal: true }} layoutRef={layoutRef} ui={PhotoswipeUIDefault}>
                    <Row style={{ width: "90%", marginBottom: "10rem" }} className="m-auto mb-5">
                        {galleryAlbums.length > 0 ? galleryAlbums.map((albums, index) => {
                            return (<React.Fragment key={index}>
                                {/* <div> */}
                                <Col className="col-12 col-sm-12 col-md-4 col-lg-3">
                                    <section id="photos" style={{ display: "flex" }}>
                                        {albums.AlbumImages.length > 0 ? albums.AlbumImages.map((image, iindex) => {
                                            // if (iindex < 10) {
                                            return (
                                                <Item key={iindex}
                                                    original={process.env.REACT_APP_BASE_URL + image.url}
                                                    thumbnail={process.env.REACT_APP_BASE_URL + image.url}
                                                    width="600"
                                                    height="400"
                                                    title={albums.AlbumName}
                                                >
                                                    {({ ref, open }) => (
                                                        <img data-aos="zoom-out-up" ref={ref} className="gallery-image" style={iindex < 1 ? {
                                                            borderRadius: "0.25rem", boxShadow: "2px 4px 13px -5px rgba(0,0,0,0.45)", marginBottom: "1rem", maxHeight: "15rem", objectFit: "cover"
                                                        } : { display: "none" }} onClick={open} src={process.env.REACT_APP_BASE_URL + image.url} />
                                                    )}
                                                </Item>
                                            )
                                            // }
                                        }) : ""}
                                    </section>
                                    <p className="mb-5 lower-mid-size-content text-left" style={{
                                        fontWeight: "bold", whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}>{albums.AlbumName}</p>
                                </Col>
                                <DefaultLayout
                                    shareButton={false}
                                    fullscreenButton={true}
                                    zoomButton={false}
                                    ref={layoutRef}

                                />
                            </React.Fragment>
                            )
                        }) : null}
                    </Row>
                </CustomGallery>
            </>
        } />
    </div >)
}
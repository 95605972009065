import Layout from "./Layout";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/AboutUS.css"

export function NotFound() {
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        return () => mounted = false;

    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up" style={{ marginTop: "20rem", marginBottom: "20rem" }}>
            <p style={{ color: "#E31A52", fontSize: "3rem" }}>
                404
            </p>
            <p>Page not found!</p>
        </div>} />
    </div >)
}
import Layout from "./Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import * as CorporateService from "../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faUser } from "@fortawesome/free-solid-svg-icons";


export function CorporateGovernance() {
    const [corporateGovernance, setCorporateGovernance] = useState([]);
    var [count, setCount] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        CorporateService.getCorporateGovernance().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setCorporateGovernance(resp);
                setCount(0);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Corporate Governance
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Corporate Governance
            </div>
            <div>
                {corporateGovernance.length > 0 ? corporateGovernance.map((cgovern, index) => {
                    if (!cgovern.IsSectionAccordion) {
                        return (
                            <div>
                                <h4 className="text-left mb-4 font-weight-bold" style={{ color: "#E31A52" }}>{cgovern.SectionHeading}</h4>
                                <div className="text-left" dangerouslySetInnerHTML={{ __html: cgovern.SectionText }} ></div>
                            </div>)
                    }
                    else {
                        count += 1;
                        return (
                            <div>
                                {count <= 1 ? <h4 className="text-left mt-5 mb-4 font-weight-bold" style={{ color: "#E31A52" }}>
                                    COMMITTEES OF THE BOARD
                                </h4> : ""}
                                <Accordion defaultActiveKey="1">
                                    <Card>
                                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={(index).toString()}>
                                            {cgovern.SectionHeading}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }} dangerouslySetInnerHTML={{ __html: cgovern.SectionText }}>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        )
                    }
                }) : ""}
                <h4 className="text-left mt-5 mb-4 font-weight-bold" style={{ color: "#E31A52" }}>DOWNLOADS</h4>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"1"}>
                            Download Links
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"1"}>
                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                {corporateGovernance.length > 0 ? corporateGovernance.map((cgovern, index) => {
                                    if (!cgovern.IsSectionAccordion) {
                                        return (
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"1"}>
                                                        Business Responsibility Report
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={"1"} style={{ paddingTop: "2rem" }}>
                                                        <>{
                                                            cgovern.BusinessReponsibilityReport.length > 0 ? cgovern.BusinessReponsibilityReport.map((files, index) => {
                                                                return (
                                                                    <Card.Body style={{ textAlign: "left", paddingTop: '2rem !important' }}>
                                                                        <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                                                    </Card.Body>
                                                                )
                                                            }) : ""
                                                        }</>
                                                    </Accordion.Collapse>
                                                </Card>
                                                {/* <Card>
                                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"2"}>
                                                        Codes
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={"2"} style={{ paddingTop: "2rem" }}>
                                                        <p>{
                                                            cgovern.Codes.length > 0 ? cgovern.Codes.map((files, index) => {
                                                                return (
                                                                    <Card.Body style={{ textAlign: "left", paddingTop: "2rem !important" }}>
                                                                        <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                                                    </Card.Body>
                                                                )
                                                            }) : ""
                                                        }</p>
                                                    </Accordion.Collapse>
                                                </Card> */}
                                                <Card>
                                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"3"}>
                                                        Familiarization Programmes
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={"3"} style={{ paddingTop: "2rem" }}>
                                                        <p>{
                                                            cgovern.FamiliarizationProgrammes.length > 0 ? cgovern.FamiliarizationProgrammes.map((files, index) => {
                                                                return (
                                                                    <Card.Body style={{ textAlign: "left", paddingTop: "2rem !important" }}>
                                                                        <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                                                    </Card.Body>
                                                                )
                                                            }) : ""
                                                        }</p>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"4"}>
                                                        Letters
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={"4"} style={{ paddingTop: "2rem" }}>
                                                        <p>{
                                                            cgovern.Letters.length > 0 ? cgovern.Letters.map((files, index) => {
                                                                return (
                                                                    <Card.Body style={{ textAlign: "left", paddingTop: "2rem !important" }}>
                                                                        <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                                                    </Card.Body>
                                                                )
                                                            }) : ""
                                                        }</p>
                                                    </Accordion.Collapse>
                                                </Card>
                                                {/* <Card>
                                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"5"}>
                                                        Policies
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={"5"} style={{ paddingTop: "2rem" }}>
                                                        <p>{
                                                            cgovern.Policies.length > 0 ? cgovern.Policies.map((files, index) => {
                                                                return (
                                                                    <Card.Body style={{ textAlign: "left", paddingTop: "2rem !important" }}>
                                                                        <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                                                    </Card.Body>
                                                                )
                                                            }) : ""
                                                        }</p>
                                                    </Accordion.Collapse>
                                                </Card> */}
                                                <Card>
                                                    <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"6"}>
                                                        Others
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={"6"} style={{ paddingTop: "2rem" }}>
                                                        <p>{
                                                            cgovern.Others.length > 0 ? cgovern.Others.map((files, index) => {
                                                                return (
                                                                    <Card.Body style={{ textAlign: "left" }}>
                                                                        <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                                                    </Card.Body>
                                                                )
                                                            }) : ""
                                                        }</p>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        )
                                    }
                                }) : ""}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>


            </div>
        </div>} />
    </div >)
}
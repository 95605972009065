import React, { Component } from "react";
import "../styles/BranchSearch.css";
import { Col, Row } from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import { Link } from "react-router-dom";
import * as BranchService from "../services/services";

class ReachUsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, branchCardDetails: [] };
    BranchService.getBranchCardDetails().then((resp) => {
      console.log(resp)
      if (!resp) {
        return;
      }
      //   debugger;
      this.setState({ loading: false, branchCardDetails: resp });
    });
  }
  render() {
    return (
      <div className="Base-wrapper">
        <div
          className="headings BranchNetworkHeading mb-5"
          style={{ marginTop: "5rem" }} data-aos="fade-up"
        >
          Reach Us
        </div>
        <div className="BranchInputSet" data-aos="fade-up">
          <div>
            <Row className="alternateColor">
              {this.state.branchCardDetails.map((details, index) => {
                return (
                  <Col className="col-12 col-sm-6 col-md-4 col-lg-4" key={details.id}>
                    {details.CardTitle !== "Contact Us" ? <Link to={details.CardTitle == "Repco Branches" ? "/branches" : "/satcenters"}>

                      <Card>
                        <Card.Img className="BranchCardImage" variant="top" src={process.env.REACT_APP_BASE_URL + details.CardImage.url} />
                        <Card.Title className="pt-4 mid-size-content">{details.CardTitle}<span className="pl-4 mid-size-content" style={{
                          lineHeight: "1.5rem"
                        }}>&#10095;</span></Card.Title>

                        <Card.Body>
                          <Card.Text className="lower-mid-size-content">
                            {details.CardDescription}
                          </Card.Text>
                        </Card.Body>
                      </Card></Link> : <Link to="/contact">

                      <Card>
                        <Card.Img className="BranchCardImage" variant="top" src={process.env.REACT_APP_BASE_URL + details.CardImage.url} />
                        <Card.Title className="pt-4 mid-size-content">{details.CardTitle}<span className="pl-4 mid-size-content" style={{
                          lineHeight: "1.5rem"
                        }}>&#10095;</span></Card.Title>

                        <Card.Body>
                          <Card.Text className="lower-mid-size-content">
                            {details.CardDescription}
                          </Card.Text>
                        </Card.Body>
                      </Card></Link>}
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default ReachUsComponent;

import Layout from "./Layout";
import { Col, Jumbotron, Row } from "react-bootstrap";
import "../styles/Announcements.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import * as AnnouncementsService from "../services/services";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


export function Announcements() {
    const [announcements, setAnnouncements] = useState([]);
    const [importantAnnouncements, setImportantAnnouncements] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        AnnouncementsService.getAnnouncements().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                let simpleAnnouncement = [];
                let impAnnouncement = [];
                resp.forEach((res, index) => {
                    if (res.ImportantAnnouncement) {
                        impAnnouncement.push(res)
                    }
                    else {
                        simpleAnnouncement.push(res)
                    }
                });
                setAnnouncements(simpleAnnouncement);
                setImportantAnnouncements(impAnnouncement)
            }
        })
        return () => mounted = false;
    }, [])



    return (<div><Layout mainContent={<div className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content mb-5" style={{ marginTop: "10rem" }}>
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    Announcements
                    </span>
            </p>
        </div>
        <Jumbotron className="announcementsBackground mb-0">
            <p className="headings announcements">Announcements!</p>
        </Jumbotron>
        <Row>
            <Col className="p-0 d-none d-sm-none d-md-none d-lg-block col-lg-6">
                <div style={{
                    width: "100%", backgroundImage: "url('http://134.209.154.158/backend/uploads/announcement_1_faed01ced1.jpeg')",
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    margin: 'auto',
                    backgroundPosition: 'initial',
                    borderRadius: '0.25rem',
                }}>

                </div>
            </Col>
            <Col className="p-0">
                {importantAnnouncements.length > 0 ?
                    <div style={{ backgroundColor: "#D3D3D380", padding: "2rem", borderRadius: "0.25rem", textAlign: "left" }}>
                        <p className="headings" style={{ color: "black" }}>Important Announcements</p>
                        {importantAnnouncements.map((announcement, index) => {
                            return (
                                <div key={index}>
                                    {announcement.NewAnnouncement ?
                                        <p>
                                            <a target="_blank" href={process.env.REACT_APP_BASE_URL + announcement.File.url}>
                                                <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;
                                {announcement.File.name}
                                            </a>&nbsp;
                            <span className="new-announcement">New</span>
                                        </p> :
                                        <a target="_blank" href={process.env.REACT_APP_BASE_URL + announcement.File.url}>
                                            <p className="important-announcement" style={{ marginBottom: "0" }}>
                                                <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;
                                {announcement.File.name}
                                            </p>
                                        </a>}
                                </div>
                            )
                        })}
                    </div> : ""}
                <div style={{ backgroundColor: "#D3D3D380", padding: "2rem", borderRadius: "0.25rem", textAlign: "left" }}>
                    <p className="headings">Other Announcements</p>
                    {announcements.length > 0 ? announcements.map((announcement, index) => {
                        return (
                            <div key={index}>
                                {announcement.NewAnnouncement ?
                                    <p>
                                        <a className="pdfHighlight-line-space font-weight-bold" target="_blank" href={process.env.REACT_APP_BASE_URL + announcement.File.url}>
                                            <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;
                                {announcement.File.name}
                                        </a>&nbsp;
                            <span className="new-announcement">New</span>
                                    </p> :
                                    <p style={{ marginBottom: "0" }}>
                                        <a className="pdfHighlight-line-space font-weight-bold" target="_blank" href={process.env.REACT_APP_BASE_URL + announcement.File.url}>
                                            <FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;
                                {announcement.File.name}
                                        </a>
                                    </p>}
                            </div>
                        )
                    }) : ""}
                </div>
            </Col>
        </Row>

    </div >} /></div >)
}

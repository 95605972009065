import "../styles/EnquiryForm.css"
import { useEffect, useState } from "react"
import * as FormService from "../services/services";
import { Formik } from 'formik';
import * as yup from 'yup';
import { Col, Row, Form, Button, Modal } from "react-bootstrap";

export function EnquiryForm(props) {
    const [modal, setmodal] = useState(props.modal)
    const [responseModal, setResponseModal] = useState(false)
    const [ResponseMessage, setResponseMessage] = useState("")

    const enquiryFormSchema = yup.object().shape({
        Name: yup.string().required().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").trim(),
        MobileNumber: yup.string().matches(/^\d+$/, 'The field should have digits only').required().min(10).max(10),
        EmailId: yup.string().email().required(),
        Message: yup.string().required().trim(),
    });
    // const inputsHandler = (e) => {
    //     setCustomerDetails({ [e.target.name]: e.target.value })
    // }
    const checkFormValidity = (values, errors) => {
        if (values) {
            if (values.Name != "") {
                if ((Object.keys(errors).length == 0)) {
                    return false
                }
                else {
                    return true
                }
            }
            else {
                return true
            }
        }
        else {
            return true
        }
    }

    useEffect(() => {
        console.log(props)
        setmodal(props.closeEnquiryForm)
    }, [])

    const submitForm = (event) => {
        console.log(event)
        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries())
        console.log(formDataObj)
        FormService.enquiryFormSubmission(formDataObj).then((resp) => {
            setResponseModal(true)
            setResponseMessage("Succesfully submitted, we will review and get back to you soon!")
            setmodal(false)
            // window.location.reload();
            console.log(resp)
        })
    }

    const closePopup = () => {
        props.checkToggleStatus();
    }

    return (
        // <div data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="bottom-bottom" className="EnquiryFormBase">
        <>
            <Modal show={responseModal} centered>
                <Modal.Body data-aos="fade-right" data-aos-duration="250">
                    <p className="m-0" style={{
                        textAlign: "justify",
                        fontWeight: "bold",
                        padding: "1rem"
                    }}>{ResponseMessage}</p>
                    <button type="button" className="okButton" onClick={()=>setResponseModal(false)}>Ok</button>
                </Modal.Body>
            </Modal >
            <Modal show={modal} centered>
                <Modal.Body data-aos="fade-right" data-aos-duration="250">
                    <p className="m-0" style={{
                        textAlign: "justify",
                        fontWeight: "bold",
                        padding: "1rem"
                    }}>Can't find what you are looking for? Let us know!, Our team is here to help you!</p>
                    <Formik
                        validationSchema={enquiryFormSchema}
                        onSubmit={submitForm}
                        initialValues={{
                            Name: "",
                            MobileNumber: "",
                            EmailId: "",
                            Message: "",
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form noValidate onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const formData = new FormData(e.target),
                                    formDataObj = Object.fromEntries(formData.entries())
                                submitForm(e)
                                console.log(formDataObj)
                            }}>

                                <div style={{ paddingRight: "1rem", paddingLeft: "1rem" }}>
                                    <Form.Group md="12" controlId="formGridName">
                                        <Form.Label className="w-100 text-left">Name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Name"
                                            value={values.Name}
                                            onChange={handleChange}
                                            isValid={touched.Name && !errors.Name}
                                            isInvalid={!!errors.Name}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-left">
                                            {errors.Name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group md="12" controlId="formGridMobileNumber">
                                        <Form.Label className="w-100 text-left">Mobile Number *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="MobileNumber"
                                            value={values.MobileNumber}
                                            onChange={handleChange}
                                            isValid={touched.MobileNumber && !errors.MobileNumber}
                                            isInvalid={!!errors.MobileNumber}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-left">
                                            {errors.MobileNumber}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group md="12" controlId="formGridEmailId">
                                        <Form.Label className="w-100 text-left">Email ID *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="EmailId"
                                            value={values.EmailId}
                                            onChange={handleChange}
                                            isValid={touched.EmailId && !errors.EmailId}
                                            isInvalid={!!errors.EmailId}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-left">
                                            {errors.EmailId}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group md="12" controlId="formGridMessage">
                                        <Form.Label className="w-100 text-left">Message *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Message"
                                            value={values.Message}
                                            onChange={handleChange}
                                            isValid={touched.Message && !errors.Message}
                                            isInvalid={!!errors.Message}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-left">
                                            {errors.Message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="enquiryButtons">
                                    <button type="submit" className="enquiryApplyButton" disabled={checkFormValidity(values, errors)}>Enquire Now</button>
                                    <button type="button" className="enquiryCloseButton" onClick={() => { setmodal(false); closePopup() }}>Close</button>
                                </div>
                            </Form>)}
                    </Formik>
                </Modal.Body>
            </Modal >
        </>)

    {/* <form>
            <input
                className="BranchStateInput w-100 mt-3"
                value={inputCustomerDetails.customer_name}
                placeholder="Name"
                type="text"
                required
                onChange={inputsHandler}
            />
            <input
                className="BranchStateInput w-100 mt-3"
                value={inputCustomerDetails.customer_phone}
                placeholder="Phone"
                type="tel"
                pattern="[0-9]*"
                required
            />
            <input
                className="BranchStateInput w-100 mt-3"
                value={inputCustomerDetails.customer_email}
                placeholder="Email"
                type="email"
                required
            />
            <textarea style={{ resize: "none" }}
                className="BranchStateInput w-100 mt-3"
                value={inputCustomerDetails.customer_message}
                placeholder="Message"
                type="textarea"
                required
            />
            <button type="submit" className="enquiryApplyButton mt-4 text-white">Submit!</button>
            <button type="button" className="enquiryCloseButton ml-4 mt-4 text-white" onClick={closePopup}>Close!</button>

        </form> */}

}
import Layout from "./Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import * as PolicyService from "../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import FairPractice from "./FairPractice"


export function PoliciesAndCodes() {
    const [PolicyDetails, setPolicyDetails] = useState([]);
    const [AllPolicies, setAllPolicies] = useState([]);
    const [AllCodes, setAllCodes] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        PolicyService.getRepcoDetails().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setPolicyDetails(resp);
            }
        })
        PolicyService.getCorporateGovernance().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setAllPolicies(resp[0]);
                setAllCodes(resp[0]);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                    </Link>{" "}
                    &#10095;
                    <span className="ml-2">
                        Policies &amp; Codes
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Policies &amp; Codes
            </div>
            <div>
                <Accordion defaultActiveKey="3">
                    <Card>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"3"}>
                            Policies
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"3"} style={{ paddingTop: "2rem" }}>
                            <>
                                <p className="m-0">{
                                    AllPolicies ? AllPolicies.Policies ? AllPolicies.Policies.length > 0 ? AllPolicies.Policies.map((files, index) => {
                                        return (
                                            <Card.Body style={{ textAlign: "left", paddingTop: "2rem !important", marginBottom: 0 }}>
                                                <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                            </Card.Body>
                                        )
                                    }) : "" : "" : ""
                                }</p>
                                <Card.Body style={{ textAlign: "left" }}>
                                    {PolicyDetails.length > 0 ? <p className="m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;<a className="pdfHighlight text-left m-0" href={process.env.REACT_APP_BASE_URL + PolicyDetails[0].KYCPolicy.url} target="_blank">KYC Policy</a></p> : null}
                                </Card.Body>
                                <Card.Body style={{ textAlign: "left" }}>
                                    {PolicyDetails.length > 0 ? <p className="m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;<a className="pdfHighlight text-left m-0" href={process.env.REACT_APP_BASE_URL + PolicyDetails[0].PrivacyPolicy.url} target="_blank">Privacy Policy</a></p> : null}
                                </Card.Body>
                            </>
                        </Accordion.Collapse>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"4"}>
                            Codes
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"4"} style={{ paddingTop: "2rem" }}>
                            <p>{
                                AllCodes ? AllCodes.Codes ? AllCodes.Codes.length > 0 ? AllCodes.Codes.map((files, index) => {
                                    return (
                                        <Card.Body style={{ textAlign: "left", paddingTop: "2rem !important" }}>
                                            <a href={process.env.REACT_APP_BASE_URL + files.url}><p className="pdfHighlight text-left m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{files.name.split(files.ext)[0]}</p></a>
                                        </Card.Body>
                                    )
                                }) : "" : "" : ""
                            }</p>
                        </Accordion.Collapse>
                        <Accordion.Toggle style={{ textAlign: "left", padding: "1.5rem", fontWeight: "bold" }} as={Card.Header} eventKey={"2"}>
                            Fair Practice Code
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"2"}>
                            <Card.Body style={{ textAlign: "left", marginTop: "2rem" }}>
                                {/* <Link to="/fair-practice-policy">Fair Practice Code</Link> */}
                                <FairPractice />
                            </Card.Body>
                        </Accordion.Collapse>

                    </Card>
                </Accordion>
            </div>
        </div>} />
    </div >)
}
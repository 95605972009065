import Layout from "../Layout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import * as InvestorService from "../../services/services";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function UnclaimedDividend() {
    const [unclaimedDividend, setUnclaimedDividend] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0)
        InvestorService.getUnclaimedDividend().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setUnclaimedDividend(resp);
            }
        })
        return () => mounted = false;
    }, [])

    return (<div>
        <Layout mainContent={<div className="Base-wrapper" data-aos="fade-up">
            <div className="breadcrumbs lower-mid-size-content" style={{ marginTop: "10rem" }}>
                <p
                    style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "auto",
                        marginTop: "0rem",
                    }}
                >
                    <Link to="/" className="mr-2">
                        Home
                  </Link>{" "}
                  &#10095;
                  <Link to="/investors" className="mr-2">
                        Investors
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                        Unclaimed Dividend
                    </span>
                </p>
            </div>
            <div className="headings BranchNetworkHeading mt-4">
                Unclaimed Dividend
            </div>
            <div>
                <Row className="pill-row p-3 pt-4">
                    <Col>
                        {unclaimedDividend.length > 0 ? unclaimedDividend[0].UnclaimedDividend ? unclaimedDividend[0].UnclaimedDividend.map((dividend, index) => {
                            return (<a className="pdfHighlight-line-space text-left" target="_blank" href={process.env.REACT_APP_BASE_URL + dividend.url}><p className="text-left w-fit-content m-0"><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{(dividend.name).split(dividend.ext)[0]}</p></a>)
                        }) : "" : ""}
                    </Col>
                </Row>
            </div>
        </div>} />
    </div >)
}
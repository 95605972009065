import Layout from "../Layout";
import { Row, Table } from "react-bootstrap";
import "../../styles/Announcements.css"
import * as InvestorService from "../../services/services";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";


export function InvestorCalendar() {
    const [calendar, setCalendar] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        InvestorService.getInvestorCalendarInfo().then((resp) => {
            if (!resp) { return }
            if (mounted) {
                setCalendar(resp);
            }
        })
        return () => mounted = false;
    }, [])



    return (<div><Layout mainContent={<div className="Base-wrapper">
        <div className="breadcrumbs lower-mid-size-content mb-5" style={{ marginTop: "10rem" }} data-aos="fade-up">
            <p
                style={{
                    width: "100%",
                    textAlign: "left",
                    margin: "auto",
                    marginTop: "0rem",
                }}
            >
                <Link to="/" className="mr-2">
                    Home
                  </Link>{" "}
                  &#10095;
                  <Link to="/investors" className="mr-2">
                    Investors
                  </Link>{" "}
                  &#10095;
                  <span className="ml-2">
                    Investor Calendar
                    </span>
            </p>
        </div>
        <div className="headings BranchNetworkHeading" data-aos="fade-up">Investor Calendar</div>
        <Row data-aos="fade-up">
            <Table className="m-auto" responsive bordered>
                <thead className="text-left">
                    <tr>
                        <th>Date</th>
                        <th>Event</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody className="text-left">
                    {calendar.length > 0 ? calendar.map((calendarInfo, index) => {
                        return (<tr key={index}>
                            <td>{calendarInfo.EventDate}</td>
                            <td>{calendarInfo.EventName}</td>
                            <td><a href={process.env.REACT_APP_BASE_URL + calendarInfo.EventDetails.url} target="_blank"><p style={{ color: "rgb(253 181 21)", fontWeight: "bold" }}><FontAwesomeIcon style={{ color: "red" }} icon={faFilePdf}></FontAwesomeIcon> &nbsp;{calendarInfo.EventDetails.name.split(calendarInfo.EventDetails.ext)[0]}</p></a></td>
                        </tr>)
                    }) : ""}
                </tbody>
            </Table>
            <p className="mt-5 font-weight-bold">Note: All future dates are indicative only and subject to change!</p>
        </Row>

    </div >} /></div >)
}
